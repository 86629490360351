import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { Formik, Form as _Form } from 'formik';
import { initializeTargetValues } from './formValues';
import PotentialReach from './PotentialReach';
import Audiences from './Audiences';
import { AutoSubmit } from 'app/midgarComponents';
import { AudiencesFormikProps } from 'app/utilities/types/formik';
import { Segments } from '../../../../../../types/typescript/Segments';
import { campaignTargetSchema } from './validationSchema';
import { setFormErrors } from 'app/ducks/campaigns/campaign/actions';
import { AppConfig } from '../../../../../../../configs/apps/typescript/types';

import type { JSX } from 'react';

interface Props {
  includedSegments: Segments;
  excludedSegments: Segments;
  includedSegmentsFilters: Segments;
  excludedSegmentsFilters: Segments;
  includedSegmentsGeoFilters: Segments;
  customerCountPerExecution: number;
  audienceFiltersEnabled: boolean;
  exclusionCampaign: boolean;
  appConfig: AppConfig;
  getEligibleSegments: (
    segments: Segments,
    ineligibleItems: Segments,
    allowBoostSegments: boolean,
    audienceFiltersEnabled: boolean,
  ) => Segments;
  segments: Segments;
  getSegments: (value: string, type: string) => Promise<void>;
  segmentsFetching: boolean;
  maxAudienceLimit: string | null;
  submitAllForms: boolean;
}

const AudienceVersions = (props: Props): JSX.Element => {
  const {
    includedSegments,
    excludedSegments,
    includedSegmentsFilters,
    excludedSegmentsFilters,
    includedSegmentsGeoFilters,
    customerCountPerExecution,
    audienceFiltersEnabled,
    exclusionCampaign,
    getEligibleSegments,
    segments,
    getSegments,
    segmentsFetching,
    maxAudienceLimit,
    submitAllForms,
  } = props;

  return (
    <Formik
      initialValues={initializeTargetValues({
        includedSegments,
        excludedSegments,
        includedSegmentsFilters,
        excludedSegmentsFilters,
        includedSegmentsGeoFilters,
        customerCountPerExecution,
        audienceFiltersEnabled,
        exclusionCampaign,
      })}
      // eslint-disable-next-line
      onSubmit={() => {}}
      validationSchema={campaignTargetSchema({ exclusionCampaign })}
      enableReinitialize
    >
      {({ handleSubmit, values }: typeof AudiencesFormikProps): JSX.Element => {
        return (
          <Form onSubmit={handleSubmit}>
            {/* ToggleWithLabel and its related implementation will be added once the campaign related audience part implementation starts */}
            <Audiences
              includedSegments={values.includedSegments}
              excludedSegments={values.excludedSegments}
              includedsegmentOptions={getEligibleSegments(segments, values.includedSegmentsFilters, true, values.audienceFiltersEnabled)}
              excludedsegmentOptions={getEligibleSegments(segments, values.excludedSegmentsFilters, false, values.audienceFiltersEnabled)}
              fetchSegments={getSegments}
              segmentsFetching={segmentsFetching}
              exclusionCampaign={exclusionCampaign}
            />

            <PotentialReach
              includedSegments={values.includedSegments}
              excludedSegments={values.excludedSegments}
              isExclusionCampaign={exclusionCampaign}
              includedSegmentsFilters={values.includedSegmentsFilters}
              excludedSegmentsFilters={values.excludedSegmentsFilters}
              includedSegmentsGeoFilters={values.includedSegmentsGeoFilters}
              audienceFiltersEnabled={values.audienceFiltersEnabled}
              maxAudienceLimit={Number(maxAudienceLimit)}
            />
            <AutoSubmit formName="composedAudience" setStoreValidityState={setFormErrors} submitTrigger={submitAllForms} />
          </Form>
        );
      }}
    </Formik>
  );
};

const Form = styled(_Form)`
  > section {
    margin-bottom: ${sc.gutter};
  }
`;

export default AudienceVersions;
