import { showWidgetType } from 'app/features/BannerManagement/utils';
import { IBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { LEVEL_CREATIVE, LEVEL_VARIANT } from 'configs/apps/creatives/banner';

export const CategoryFieldNames = {
  name: 'name',
  weight: 'weight',
};

export const CreativeFieldNames = {
  gaCategory: 'gaCategory',
  name: 'name',
  platform: 'platform',
  site: 'site',
  widgetType: 'widgetType',
};

export const VariantFieldNames = {
  imageUrl: 'imageUrl',
  itemId: 'itemId',
  landingPageType: 'landingPageType',
  name: 'name',
  url: 'url',
  variantType: 'variantType',
  widgetType: 'widgetType',
};

export const requiredCreativeFields = (bannerConfig?: IBannerCreativeConfig): Array<string> => {
  const disabledFields: string[] = [];
  if (!bannerConfig?.useGACategory) disabledFields.push(CreativeFieldNames.gaCategory);
  if (!showWidgetType(bannerConfig, LEVEL_CREATIVE)) disabledFields.push(CreativeFieldNames.widgetType);

  return Object.values(CreativeFieldNames).filter(field => !disabledFields.includes(field));
};

export const requiredVariantFields = (bannerConfig?: IBannerCreativeConfig): Array<string> => {
  // Exclude the landing page related fields since they are validated in validateLandingPage()
  const disabledFields: string[] = [VariantFieldNames.itemId, VariantFieldNames.url];
  if (!showWidgetType(bannerConfig, LEVEL_VARIANT)) disabledFields.push(VariantFieldNames.widgetType);
  if (!bannerConfig?.useVariantType || !showWidgetType(bannerConfig, LEVEL_VARIANT)) disabledFields.push(VariantFieldNames.variantType);

  return Object.values(VariantFieldNames).filter(field => !disabledFields.includes(field));
};
