import * as React from 'react';
import Calendar from 'rc-calendar';
import Picker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import { TextField } from '@material-ui/core';

import { momentFromDateStr, momentToDateStr } from 'app/utilities/date';
import { defaultDateFormat } from 'configs/dateTime';

interface DateFieldProps {
  label?: string;
  placeholder?: string;
  value?: string;
  setValue(...args: unknown[]): unknown;
  name: string;
  classes?: {
    textField?: string;
  };
}

export const DateField = ({ label = '', placeholder = '', value = '', setValue, name, classes = {}, ...otherProps }: DateFieldProps) => (
  <Picker
    animation="slide-up"
    calendar={<Calendar onSelect={m => setValue(momentToDateStr(m), name)} format={defaultDateFormat} dateInputPlaceholder={placeholder} />}
    value={momentFromDateStr(value)}
  >
    {() => <TextField label={label} value={value} className={classes.textField} {...otherProps} />}
  </Picker>
);
