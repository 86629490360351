import React from 'react';

import { Grid } from '@material-ui/core';
import { ListSelectableGridColumn } from './ListSelectableGridColumn';

const getSelectedItemId = (selected, list) => {
  if (selected && selected.length > 0) {
    const found = selected.find(s => s.listId === list.id);
    if (found) {
      return found.itemId;
    }
  }
  return null;
};

interface ListSelectableGroupProps {
  selected?: {
    listId?: number | string;
    itemId?: number | string;
  }[];
  lists?: {
    id?: number | string;
    name?: string;
    items?: {
      id?: number | string;
      name?: string;
    }[];
  }[];
  colWidth?: 1 | 2 | 3 | 4 | 6;
  handleListClick?(...args: unknown[]): unknown;
  scrollIntoViewIfNeeded?: boolean;
}

export const ListSelectableGroup = ({
  selected = [],
  lists = [],
  colWidth,
  handleListClick,
  scrollIntoViewIfNeeded = false,
}: ListSelectableGroupProps) => (
  <Grid container spacing={0}>
    {lists.map(list => (
      <ListSelectableGridColumn
        key={list.id}
        selectedItemId={getSelectedItemId(selected, list)}
        listId={list.id}
        listName={list.name}
        items={list.items}
        colWidth={colWidth}
        handleClick={handleListClick(list.id)}
        scrollIntoViewIfNeeded={scrollIntoViewIfNeeded}
      />
    ))}
  </Grid>
);
