import * as React from 'react';
import styled from 'styled-components';
import { Error as ErrorIcon } from '@material-ui/icons';

import Tags from 'app/features/Tags';
import { Paper, Input } from 'app/midgarComponents';

// ------------ Styles ----------------
const GeneralWrapper = styled(Paper)`
  padding: 3rem 1rem;
`;
const InputContainer = styled.div`
  width: 50%;
`;

interface GeneralProps {
  name?: string;
  description?: string;
  tags?: {
    id?: string | number;
    name?: string;
  }[];
  handleChangeName(...args: unknown[]): unknown;
  handleChangeDescription(...args: unknown[]): unknown;
  handleAddTag(...args: unknown[]): unknown;
  handleRemoveTag(...args: unknown[]): unknown;
  classes: {};
  valid: boolean;
  validationError?: string;
  loading: boolean;
}

// ----------- End of Styles ----------

export const General = ({
  name = '',
  description = '',
  tags = [],
  handleChangeName,
  handleChangeDescription,
  handleAddTag,
  handleRemoveTag,
  classes,
  valid,
  validationError = '',
  loading,
}: GeneralProps) => (
  <GeneralWrapper>
    {!loading && !valid && validationError && (
      <span>
        <ErrorIcon color="error" style={{ marginRight: '0.2rem' }} />
        <i data-qa="audience-validation-error">{validationError}</i>
      </span>
    )}

    <InputContainer>
      <Input
        required
        autoFocus
        id="name"
        label="Name"
        placeholder="Name"
        value={name}
        data-qa="segment-name"
        type="search"
        onChange={handleChangeName}
      />

      <Input
        required
        id="description"
        label="Description"
        placeholder="Description"
        value={description}
        data-qa="segment-description"
        type="search"
        onChange={handleChangeDescription}
      />

      <Tags selected={tags} removeTag={handleRemoveTag} addTag={handleAddTag} size={100} />
    </InputContainer>
  </GeneralWrapper>
);
