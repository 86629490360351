import React from 'react';

import { joinWithSpace, mapIndexed, R } from 'app/helpers/RamdaHelpers/helpers';

const padding = joinWithSpace(R.repeat('.', 5));

interface StandaloneBreadcrumbLabelProps {
  content: string;
  classes: {};
}

export const StandaloneBreadcrumbLabel = ({ content, classes }: StandaloneBreadcrumbLabelProps) => (
  <ul className={classes.container}>
    {mapIndexed(
      (text, index) => (
        <li key={`-${index}`} className={classes.pathComponent}>
          <span className={classes.pathContent}>{text}</span>
        </li>
      ),

      [content, padding],
    )}
  </ul>
);
