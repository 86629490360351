import { compose, isEmpty } from 'ramda';

import { isPlainObject, isUndefined, isNotNil, renameKeys, viewOr } from 'ramda-adjunct';

import { defaultTableSorter } from 'app/components/Table/helpers';
import { possibleValuesOfFeature } from 'app/api/features';
// import objectHash from 'object-hash';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';

import {
  joinWithDot,
  fEmptyArray,
  isNilOrEmpty,
  isNeitherNilNorEmpty,
  enrichObjectsWithId,
  fTrue,
  fNot,
  isOneOf,
  randomInt,
  transformShape,
  unixTimeWithMillis,
  R,
} from '../RamdaHelpers/helpers';

import {
  internalDataTypes,
  specialOperators,
  isTimeWindowOperator,
  isSetRelationshipOperator,
  isInOperator,
  isNotInOperator,
  isExistsOperator,
  isExistsOperatorV2,
  isNestedType,
  FeatureMetadataKeyNames,
  DataTypeAndOps,
  optionDictLens,
  pathDictLens,
  dataTypeDictLens,
  originDictLens,
} from './common';

import { echoLog } from '../DebugHelpers/helpers';

const withKey = value => ({
  key: unixTimeWithMillis() + randomInt(),
  value,
});

const FeatureLevelLabels = ['Vertical', 'Class', 'Category', 'Feature', 'Operator'];

const FeatureLevelLabelsV2 = ['Vertical', 'Category', 'Feature', 'Operator'];

const {
  numberType,
  numberArrayType,
  numberArrayTypeV2,
  stringType,
  stringArrayType,
  stringArrayTypeV2,
  dateType,
  datetimeType,
  datetimeTypeV2,
  timeWindowType,
  nestedType,
} = internalDataTypes;

const { containsAnyOperator, inOperator } = specialOperators;

const isTemporalDataType = isOneOf([dateType, datetimeType, datetimeTypeV2]);
const arrayTypes = [numberArrayType, stringArrayType, stringArrayTypeV2, numberArrayTypeV2];
const isArrayType = isOneOf(arrayTypes);

// Lenses, getters, setters
const optionsByBreadcrumb = R.curry((breadcrumb, dict) => R.prop(breadcrumb, R.view(optionDictLens, dict)));
const optionsByPaths = (paths, dict) => optionsByBreadcrumb(joinWithDot(paths), dict);

const pathsByFeatureName = R.curry((featureName, dict) => R.prop(featureName, R.view(pathDictLens, dict)));

const promiseOfValuesByFeatureId = possibleValuesOfFeature;

const memoizePromiseWith = R.curryN(2, (keyFunc, valueFunc) => {
  const cache = {};

  return (...args) => {
    const key = R.apply(keyFunc, args);
    const retry = () => {
      cache[key] = R.apply(valueFunc, args);
    };

    if (fNot(R.has(key))(cache)) {
      retry();
    }

    return {
      promise: cache[key],
      retry,
    };
  };
});

const featureValuesOf = memoizePromiseWith(
  ({ name }) => name,
  ({ id, values }) =>
    isUndefined(values)
      ? window.location.pathname.includes('/audience/')
        ? Promise.resolve([])
        : promiseOfValuesByFeatureId(id)
      : Promise.resolve(values),
);

const valueHandlers = ['validator', 'stringifier'];

const dataTypeFieldKey = 'dataType';

const updateUserInputElementWithDataType =
  dataType =>
  ({ input }) =>
    R.assoc(dataTypeFieldKey, dataType, input);

const conditionallyRemapInternalDataTypeOnUserInputElement = R.cond([
  [
    ({ operator, input }) =>
      // special remapping from 'date'/'datetime' to 'timeWindow'
      // when the operator is 'LAST' and dateType is date or datetime.
      isTimeWindowOperator(operator) && isTemporalDataType(input[dataTypeFieldKey]),
    updateUserInputElementWithDataType(timeWindowType),
  ],

  [
    ({ operator, input }) => (isInOperator(operator) || isNotInOperator(operator)) && input[dataTypeFieldKey] === stringType,
    updateUserInputElementWithDataType(stringArrayType),
  ],

  [
    ({ operator, input }) => (isInOperator(operator) || isNotInOperator(operator)) && input[dataTypeFieldKey] === stringType,
    updateUserInputElementWithDataType(stringArrayTypeV2),
  ],

  [
    ({ operator, input }) => (isInOperator(operator) || isNotInOperator(operator)) && input[dataTypeFieldKey] === numberType,
    updateUserInputElementWithDataType(numberArrayType),
  ],

  [
    // This is the fallback/default case.
    fTrue,
    ({ input }) => input,
  ],
]);

const newLeafLevel = 3;

const getValueHandlersBy = dataType => R.pick(valueHandlers, DataTypeAndOps[dataType]);

// derivative lenses of dataTypeDictLens
const lensToMetaDataInDataTypeDictByFeature = featureName => R.compose(dataTypeDictLens, R.lensProp(featureName));

const lensOfValueMetaDataInDictByFeatureAndProp = (featureName, propName) =>
  R.compose(lensToMetaDataInDataTypeDictByFeature(featureName), R.lensProp(propName));

const valueMetaDataByFeature = (featureName, dict) => {
  const baseValue = R.view(lensToMetaDataInDataTypeDictByFeature(featureName), dict);

  return {
    ...baseValue,
    options: featureValuesOf({
      name: featureName,
      id: baseValue.featureId,
      values: baseValue.options,
    }),
  };
};

const featureInfoOf = (featureKey, propKeys) => R.compose(R.props(propKeys), R.view(R.compose(originDictLens, R.lensProp(featureKey))));

const [selectedFeatureLens, selectedOperatorLens, selectedUserInputLens] = R.map(R.lensIndex, R.range(0, newLeafLevel));

const [selectedFeatureReader, selectedOperatorReader, selectedUserInputReader] = R.map(
  lens => R.view(R.compose(lens, R.lensProp('selected'))),
  [selectedFeatureLens, selectedOperatorLens, selectedUserInputLens],
);

const valueElementFrom = (index, baseValueElement) => ({
  ...baseValueElement,
  updater: R.curry((value, source) => [
    ...R.take(index, source),
    {
      ...baseValueElement,
      selected: isNilOrEmpty(value) ? undefined : value,
      ...R.pick(['updater'], source[index]),
    },
  ]),
});

const fakeValue = [0];

const operatorElementFrom = (index, options, baseValueElement) => ({
  label: 'Operator',
  options,
  updater: R.curry((operator, source) => {
    const next = index + 1;
    const kept = R.pick(['label', 'options', 'updater'], source[index]);
    const selected = isNilOrEmpty(operator) ? undefined : operator;

    const transformations = R.map(f => f(operator), getValueHandlersBy(baseValueElement.originalDataType));
    const valueElement = conditionallyRemapInternalDataTypeOnUserInputElement({
      operator,
      // input: R.evolve(transformations, baseValueElement),
      input: R.mergeAll([baseValueElement, transformations]),
    });

    const result = [
      ...R.take(index, source),
      {
        ...kept,
        selected,
      },

      ...(selected ? [valueElementFrom(next, valueElement)] : []),
    ];

    // eslint-disable-next-line
    return isExistsOperator(selected) || isExistsOperatorV2(selected) ? result[next].updater(fakeValue, result) : result;
  }),
});

// const hasMeaningfulSelection = fTruthy(R.prop('selected'));
const hasValidSelection = ({ selected, validator = fTrue }) => R.allPass([isNeitherNilNorEmpty, validator])(selected);

const subFeatureLabel = 'sub-feature';

const isSubFeature = R.equals(subFeatureLabel);

const isSubFeatureRule = R.compose(isSubFeature, R.view(R.lensPath([1, 'label'])));

const subFeatureElementFrom = (dict, subFeatures) => {
  const updater = R.curry((operator, source) => {
    const index = 0;
    const kept = R.pick(['key', 'name', 'label', 'options', 'updater'], source[index]);

    if (isNilOrEmpty(operator)) {
      return [kept];
    }

    const baseValueElement = valueMetaDataByFeature(kept.key, dict);

    // todo: support nested sub-features
    // isNestedType(baseValueElement.dataType)

    const transformations = R.map(f => f(operator), getValueHandlersBy(baseValueElement.originalDataType));
    const valueElement = conditionallyRemapInternalDataTypeOnUserInputElement({
      operator,
      input: R.mergeAll([baseValueElement, transformations]),
    });

    const next = index + 1;
    const result = [{ ...kept, selected: operator }, valueElementFrom(next, valueElement)];

    // eslint-disable-next-line
    return isExistsOperator(operator) || isExistsOperatorV2(operator) ? result[next].updater(fakeValue, result) : result;
  });

  return {
    label: subFeatureLabel,
    // todo: improve this and make it work with nested sub-features
    isComplete: data => R.reduce((acc, sub) => acc && R.all(hasValidSelection)(sub), true, data),
    options: R.map(a => [{ ...a, updater }], subFeatures),
  };
};

const nextElementByDataType = (dict, next, options, dataTypeValue) =>
  isNestedType(dataTypeValue.dataType) ? subFeatureElementFrom(dict, options) : operatorElementFrom(next, options, dataTypeValue);

const featureElementFrom = (dict, index) => ({
  label: 'Feature',
  updater: R.curry((feature, source) => {
    const next = index + 1;
    const kept = R.pick(['label', 'updater'], source[index]);

    const [featureId, featureDesc] = isPlainObject(feature) ? [feature.value, feature.desc] : [feature];

    const [originalDesc, originalAlias] = featureInfoOf(featureId, [
      FeatureMetadataKeyNames.featureDesc,
      FeatureMetadataKeyNames.featureLabel,
    ])(dict);

    const [selected, desc, alias] = isNilOrEmpty(featureId) ? [] : [featureId, featureDesc || originalDesc, originalAlias];
    /*
      const [paths, options, desc] = selected ? (pathz => [
        pathz,
        optionsByPaths(pathz, dict),
        readFeatureDescFromDataTypeDict(featureId)(dict),
      ])([
        ...pathsByFeatureName(featureId, dict),
        featureId,
      ]) : [];
    */

    const [paths, options] = selected
      ? (pathz => [pathz, optionsByPaths(pathz, dict)])([...pathsByFeatureName(featureId, dict), featureId])
      : [];

    const result = [
      ...R.take(index, source),
      {
        ...kept,
        selected,
        ...(selected ? { paths, desc, alias } : {}),
      },

      ...(selected ? [nextElementByDataType(dict, next, options, valueMetaDataByFeature(featureId, dict))] : []),
    ];

    return result;
  }),
});

const createSeedData = dict => [featureElementFrom(dict, 0)];

const trueIfEqualsOrPassTest = (a, b, pred) => a === b || pred([a, b]);

const retainUserInput = (extractFrom, oldValue, newValue) => {
  const [oldOp, oldInput, newOp, newInput] = R.chain(extractFrom, [oldValue, newValue]);

  const shouldRetain =
    oldOp &&
    oldInput &&
    newOp &&
    newInput &&
    // operators
    oldOp.selected &&
    newOp.selected &&
    // user inputs
    oldInput.selected &&
    R.isNil(newInput.selected) &&
    trueIfEqualsOrPassTest(
      oldOp.selected,
      newOp.selected,
      R.either(
        R.allPass(R.map(R.none, [isTimeWindowOperator, isSetRelationshipOperator, isExistsOperator, isExistsOperatorV2])),
        R.all(isTimeWindowOperator),
      ),
    ) &&
    fNot(R.any(isNestedType))([oldInput.dataType, newInput.dataType]) &&
    trueIfEqualsOrPassTest(oldInput.dataType, newInput.dataType, R.none(isArrayType));

  return shouldRetain ? [...R.init(newValue), R.assoc('selected', oldInput.selected, R.last(newValue))] : newValue;
};

const retainUserInputIfNecessary2 = (oldValue, newValue) => {
  const extractFrom = transformShape(R.map(R.view, [selectedOperatorLens, selectedUserInputLens]));
  return retainUserInput(extractFrom, oldValue, newValue);
};

const retainSubFeatureUserInputIfNecessary = (oldValue, newValue) => {
  const extractFrom = transformShape(R.map(R.compose(R.view, R.lensIndex), R.range(0, 2)));
  return retainUserInput(extractFrom, oldValue, newValue);
};

// Related to data structures for persisting the rule expressions
const FeatureValueKeyNames = {
  expression: 'expression',
  operator: 'op',
  dataType: 'type',
  operands: 'operands',
  parentFeature: 'parentFeature',
};

const FeatureValueLenses = {
  version: R.lensProp(FeatureValueKeyNames.expression),
  andGroup: R.lensProp(FeatureValueKeyNames.operands),
  andRules: R.lensProp(FeatureValueKeyNames.operands),
  op: R.lensProp(FeatureValueKeyNames.operator),
  parentFeature: R.lensProp(FeatureValueKeyNames.parentFeature),
  dataType: R.lensProp(FeatureValueKeyNames.dataType),
  operands: R.lensProp(FeatureValueKeyNames.operands),
  leftOperand: R.lensPath([FeatureValueKeyNames.operands, 0]),
  rightOperand: R.lensPath([FeatureValueKeyNames.operands, 1]),
};

const FeatureValueGetters = R.mergeAll([
  R.map(R.view, FeatureValueLenses),
  {
    andGroups: R.view(R.compose(FeatureValueLenses.version, FeatureValueLenses.andGroup)),
  },
]);

const readFeatureOperatorUserValueFrom = transformShape([
  FeatureValueGetters.leftOperand,
  FeatureValueGetters.op,
  FeatureValueGetters.rightOperand,
  FeatureValueGetters.parentFeature,
]);

const lensOfFeatureInfoInOptionDictByPath = path => R.compose(optionDictLens, R.lensProp(path));

const isSubFeatureRuleExpression = (parentFeature, operator) => isNeitherNilNorEmpty(parentFeature) && operator === 'AND';
const determineFeatureName = (parentFeature, operator, feature) =>
  isSubFeatureRuleExpression(parentFeature, operator) ? parentFeature : feature;

const findUnknownFeatures = R.curry((dict, andGroups) =>
  R.chain(
    a =>
      R.reduce(
        (acc, datum) => {
          const [feature, operator, , parentFeature] = readFeatureOperatorUserValueFrom(datum);

          const featureName = determineFeatureName(parentFeature, operator, feature);
          return R.view(lensToMetaDataInDataTypeDictByFeature(featureName), dict) ? acc : [...acc, featureName];
        },
        [],
        FeatureValueGetters.andRules(a),
      ),

    andGroups,
  ),
);

const unknownFeatureFoundError = unknowns => `Unknown feature(s) are found in this audience: [${unknowns.join(', ')}]`;

/**
 * The data structure of the rule data as stored in Redux has been changed since `transformOrGroupData()` was written.
 * This function converts the structure to the new format.
 * It leaves the structure unaltered if it is already in the new format.
 * @param {*} ruleData
 */
const sanitiseRuleData = ruleData => {
  let sanitisedData;
  if (ruleData.expression) {
    sanitisedData = ruleData;
  } else {
    const { version, ...rest } = ruleData;
    if (!isEmpty(rest)) {
      const keys = Object.keys(rest);
      keys.sort();
      const firstValue = rest[keys[0]];
      sanitisedData = { version, ...firstValue };
    }
  }
  if (typeof sanitisedData.expression === 'string') {
    sanitisedData = {
      ...sanitisedData,
      expression: JSON.parse(sanitisedData.expression),
    };
  }
  return sanitisedData;
};

const transformOrGroupData = (dict, f, data) => {
  const sanitisedData = sanitiseRuleData(data);
  if (!sanitisedData) throw new Error(`Data has invalid format\n${JSON.stringify(data, null, 2)}`);

  try {
    const andGroups = FeatureValueGetters.andGroups(sanitisedData);
    const unknowns = findUnknownFeatures(dict, andGroups);

    if (unknowns.length > 0) {
      displayError(unknownFeatureFoundError(unknowns));
      throw new Error(unknownFeatureFoundError(unknowns));
    }

    const nm = R.reduce(
      (acc, andGroup) => [
        ...acc,
        ...(isNilOrEmpty(andGroup.operands) ? [] : [withKey(R.map(a => withKey(f(a)), FeatureValueGetters.andRules(andGroup)))]),
      ],

      [],
      andGroups,
    );

    return nm;
  } catch (err) {
    displayError('Error while transforming OR group data.');
    throw err;
  }
};

const toRuleRowStruct2 = R.curry((dict, row) => {
  const [featureName, op, userValue, parentFeature] = readFeatureOperatorUserValueFrom(row);

  if (isSubFeatureRuleExpression(parentFeature, op)) {
    const subFeaturesLens = R.lensPath([1, 'options']);
    const seed = (init => init[0].updater(parentFeature, init))(createSeedData(dict));

    const transformed = R.map(([subMeta, subValue]) => {
      const [, operator, value] = readFeatureOperatorUserValueFrom(subValue);

      return R.reduce(
        (result, [val, read]) => {
          const { selected, updater } = read(result);
          // if already auto-advanced, skip and return acc
          return selected ? result : updater(val, result);
        },
        subMeta,
        R.zip(
          [operator, value],
          R.map(i => R.view(R.lensIndex(i)), R.range(0, 2)),
        ),
      );
    }, R.zip(R.view(subFeaturesLens, seed), FeatureValueGetters.operands(row)));

    return R.set(subFeaturesLens, transformed, seed);
  }

  const dataType = R.view(lensOfValueMetaDataInDictByFeatureAndProp(featureName, dataTypeFieldKey), dict);

  const operator = isArrayType(dataType) && (isInOperator(op) || isNotInOperator(op)) ? containsAnyOperator : op;

  return R.reduce(
    (acc, [value, read]) => {
      const { selected, updater } = read(acc);
      // if already auto-advanced, skip and return acc
      return selected ? acc : updater(value, acc);
    },
    createSeedData(dict),
    R.zip([featureName, operator, userValue], R.map(R.view, [selectedFeatureLens, selectedOperatorLens, selectedUserInputLens])),
  );
});

/**
 * Transform the given rule data from the structure in the Redux store to the structure used by te `OrRuleGroup` component.
 *
 * During creation of a segment, the UI state is contained in the `OrRuleGroup`, and is updated as required in the Redux store
 * based on this state.
 *
 * If there is an initial state required in the `OrRuleGroup`, this function converts the structure in the Redux store
 * back to the structure using by `OrRuleGroup`.
 *
 * Note that in this case, validation of the rules must be done separately. See {@link validateRuleData}.
 * @param {*} dict
 * @param {*} orGroup
 */
const toOrGroupDataStruct2 = (dict, orGroup) => transformOrGroupData(dict, toRuleRowStruct2(dict), orGroup);

/*
  const toRuleSelectionTable =
    R.map(R.map(R.pluck('selected')));

  const andGroupDataHash = andGroupData =>
    objectHash(R.pluck('selected', R.take(5, andGroupData)));

  const orGroupDataHash = orGroupData =>
    objectHash(R.map(d => R.pluck('selected', R.take(5, d)), orGroupData));
*/

// todo: make it work with nested sub-features
const tabularSubFeatureRowTransformer = R.curry((dict, parentFeature, row) => {
  const [subFeature, operator, value] = readFeatureOperatorUserValueFrom(row);

  const subFeatureKey = `${joinWithDot([parentFeature, subFeature])}`;

  const [dataType, originalDataType] = transformShape(
    R.map(prop => R.view(lensOfValueMetaDataInDictByFeatureAndProp(subFeatureKey, prop)), [dataTypeFieldKey, 'originalDataType']),
    dict,
  );

  const { stringifier } = getValueHandlersBy(originalDataType);

  const remappedOperator = isArrayType(dataType) && (isInOperator(operator) || isNotInOperator(operator)) ? containsAnyOperator : operator;

  const paths = R.repeat('', window.location.pathname.includes('/audience/') ? 2 : 3);

  return [
    ...paths,
    subFeature,
    remappedOperator,
    // eslint-disable-next-line
    isExistsOperator(operator) || isExistsOperatorV2(operator) ? '' : stringifier(operator)(value),
  ];
});

const tabularRowTransformer = R.curry((dict, row) => {
  const [featureName, operator, value, parentFeature] = readFeatureOperatorUserValueFrom(row);

  if (isSubFeatureRuleExpression(parentFeature, operator)) {
    const parentPaths = R.prop(parentFeature, R.view(pathDictLens, dict));

    const [{ label: parentLabel } = {}] = R.filter(
      R.propEq('value', parentFeature),
      viewOr([], lensOfFeatureInfoInOptionDictByPath(joinWithDot(parentPaths)), dict),
    );

    const parentElement = [...parentPaths, parentLabel || parentFeature, ...R.repeat('', 2)];

    return [parentElement, ...R.map(tabularSubFeatureRowTransformer(dict, parentFeature), FeatureValueGetters.operands(row))];
  }

  const [dataType, originalDataType] = transformShape(
    R.map(prop => R.view(lensOfValueMetaDataInDictByFeatureAndProp(featureName, prop)), [dataTypeFieldKey, 'originalDataType']),
    dict,
  );

  const { stringifier } = getValueHandlersBy(originalDataType);

  const remappedOperator = isArrayType(dataType) && (isInOperator(operator) || isNotInOperator(operator)) ? containsAnyOperator : operator;

  const paths = R.prop(featureName, R.view(pathDictLens, dict));

  const [{ label: featureLabel } = {}] = R.filter(
    R.propEq('value', featureName),
    viewOr([], lensOfFeatureInfoInOptionDictByPath(joinWithDot(paths)), dict),
  );

  return [
    [
      ...paths,
      featureLabel || featureName,
      remappedOperator,
      // eslint-disable-next-line
      isExistsOperator(operator) || isExistsOperatorV2(operator) ? '' : stringifier(operator)(value),
    ],
  ];
});

const readValue = R.pluck('value');

const tabularCellsFrom = R.compose(R.map(R.compose(R.chain(R.identity), readValue)), readValue);

const toTabularOrGroupData = (dict, orGroupData) => {
  const text = window.location.pathname;
  const dicts = {
    dataTypeDict: dict.dataTypeDict,
    optionDict: dict.optionDict,
    pathDict: dict.pathDict,
    originDict: dict.originDict,
  };

  const abc = {
    headers: [...(text.includes('/audience/') ? FeatureLevelLabelsV2 : FeatureLevelLabels), 'Value'],
    cells: tabularCellsFrom(transformOrGroupData(dicts, tabularRowTransformer(dicts), orGroupData)),
  };

  return abc;
};

const toTableFormat = ({ headers, cells }) => {
  const columns = R.map(
    label => ({
      key: label,
      label,
      isNumeric: false,
      onSort: defaultTableSorter,
    }),

    headers,
  );

  return R.map(
    groupCells => ({
      columns,
      rows: enrichObjectsWithId(R.map(R.zipObj(headers), groupCells)),
    }),

    cells,
  );
};

const toTableDataFormat = R.curry((dict, orGroupData) => toTableFormat(toTabularOrGroupData(dict, orGroupData)));

// restore feature's original dataType
const remapDataTypeForApiPayloadIfNecessary = (dataType, operator) =>
  operator === containsAnyOperator
    ? dataType
    : R.propOr(dataType, dataType, {
        [stringArrayType]: stringType,
        [numberArrayType]: numberType,
        [stringArrayTypeV2]: stringType,
        [numberArrayTypeV2]: numberType,
      });

const remapOperatorForApiPayloadIfNecessary = operator =>
  R.propOr(operator, operator, {
    // Although this translation is not technically correct or can be confusing,
    // 'IN' is what Kamal wants the intersect-operation operator to be.
    [containsAnyOperator]: inOperator,
  });

const subFeatureExtractor = transformShape(
  R.map(R.compose(R.view, R.lensPath), [
    [0, 'name'],
    [0, 'selected'],
    [1, 'selected'],
    [1, dataTypeFieldKey],
  ]),
);

const toSimpleOrNestedFeatureValue = (dataType, simpleValue, feature, subFeatures) => {
  if (!isNestedType(dataType)) {
    return {
      ...simpleValue,
      // type: dataType, // making payload consistent; requires Rule Engine to change too
      dataType,
    };
  }

  const subFeaturesReducer = toFeatureValue2(subFeatureExtractor); // eslint-disable-line no-use-before-define
  const initialAcc = [[], []];

  const [, operands] = R.reduce(subFeaturesReducer, initialAcc, subFeatures);

  return {
    op: 'AND',
    type: 'bool',
    parentFeature: feature,
    operands,
  };
};

const checkForSubFeatureRuleCompleteness = rule => {
  const [, { isComplete, options }] = rule;
  return isComplete(options);
};

const toFeatureValue2 = R.curry((extract, acc, ruleRow) => {
  const isSubFeatRule = isSubFeatureRule(ruleRow);
  const [feature, operator, selected, dataType] = isSubFeatRule
    ? [R.head(extract(ruleRow)), fakeValue, fakeValue, nestedType]
    : extract(ruleRow);

  const shouldProcess = feature && operator && selected && dataType && (isSubFeatRule ? checkForSubFeatureRuleCompleteness(ruleRow) : true);

  return shouldProcess
    ? R.zipWith(
        R.append,
        [
          feature,
          toSimpleOrNestedFeatureValue(
            remapDataTypeForApiPayloadIfNecessary(dataType, operator),
            {
              op: remapOperatorForApiPayloadIfNecessary(operator),
              operands: [feature, selected],
            },

            feature,
            // todo: improve this
            R.prop('options', ruleRow[1]),
          ),
        ],

        acc,
      )
    : acc;
});

const toOrGroupPayload = orGroupData => {
  const extractor = transformShape([
    selectedFeatureReader,
    selectedOperatorReader,
    selectedUserInputReader,
    R.view(R.compose(selectedUserInputLens, R.lensProp(dataTypeFieldKey))),
  ]);

  const andGroupReducer = toFeatureValue2(extractor);

  const initialAcc = [[], []];

  const orGroupReducer = (acc, andGroup) => {
    const [features, operands] = R.reduce(andGroupReducer, initialAcc, andGroup);

    return isNilOrEmpty(operands)
      ? acc
      : R.zipWith(R.concat, acc, [
          features,
          [
            {
              op: 'AND',
              type: 'bool',
              operands,
            },
          ],
        ]);
  };

  const [features, operands] = R.reduce(orGroupReducer, initialAcc, orGroupData);

  return isNilOrEmpty(operands)
    ? []
    : [
        R.uniq(features),
        {
          /* todo: re-enable this when Kamal ES query engine re-enables json versioning
    version: '1',
    expression: {
      op: 'OR',
      type: 'bool',
      operands,
    },
    };
    */
          op: 'OR',
          type: 'bool',
          operands,
        },
      ];
};

const groupValuesByFeatureAndOperator = R.groupBy(a => joinWithDot(transformShape([selectedFeatureReader, selectedOperatorReader], a)));
const bifurcateDuplicates = data => {
  const [subFeatureRules, mainFeatureRules] = R.partition(isSubFeatureRule, data);

  const bifurcated = R.partition(a => R.length(a) > 1, groupValuesByFeatureAndOperator(mainFeatureRules));
  const result = R.zipObj(
    ['duplicates', 'uniques'],
    R.map(a => R.chain(R.identity, R.values(a)), bifurcated),
  );

  return R.mergeWith(R.concat, result, {
    uniques: subFeatureRules,
  });
};

const DataQualityResultValidKey = 'valid';

const segregateByDataQuality = rowData => {
  const ValidKey = DataQualityResultValidKey;

  const newAccFrom = keys => R.zipObj(keys, R.map(fEmptyArray, keys));

  const accKeysFrom = checkers => [...R.pluck('name', checkers), ValidKey];

  const recurIterationPhaseTest = ([checker, ...remainingCheckers], target) =>
    checker ? (checker.test(target) ? checker.name : recurIterationPhaseTest(remainingCheckers, target)) : null;

  const appendAcc = (acc, key, value) => R.assoc(key, [...acc[key], value], acc);

  const recurReduceIterationPhase = ([target, ...remaining], acc, checkers, failFast = false) => {
    if (target) {
      const checkerName = recurIterationPhaseTest(checkers, target);
      if (checkerName && failFast) {
        return appendAcc(acc, checkerName, target);
      }
      const key = checkerName || ValidKey;

      return recurReduceIterationPhase(remaining, appendAcc(acc, key, target), checkers, failFast);
    }

    return acc;
  };

  const bifurcateResult = result => R.map(a => R.pick(a, result), R.partition(R.equals(ValidKey), R.keys(result)));

  const phaseResultHasError = result => R.any(R.length, R.values(result));

  const readSurvived = R.prop(ValidKey);

  const recurReduceBifurcationPhase = ([checker, ...remainingCheckers], data, acc, failFast = false) => {
    if (checker) {
      const [passed, remainingData] = R.partition(checker.test, data);
      const hasCheckedItems = passed.length > 0;
      const updatedAcc = hasCheckedItems ? R.assoc(checker.name, passed, acc) : acc;

      return hasCheckedItems && failFast ? updatedAcc : recurReduceBifurcationPhase(remainingCheckers, remainingData, updatedAcc, failFast);
    }

    return R.assoc(ValidKey, data, acc);
  };

  // executions
  const failFast = false;

  // iteration phase
  const iterativeCheckers = R.map(R.zipObj(['name', 'test']), [
    [
      'incomplete',
      R.ifElse(
        isSubFeatureRule,
        fNot(checkForSubFeatureRuleCompleteness),
        a => R.length(R.filter(isNotNil, R.pluck('selected', a))) < newLeafLevel,
      ),
    ],

    [
      'invalid',
      a => {
        if (isSubFeatureRule(a)) {
          return false;
        }

        const { validator, selected } = R.last(a);
        return !validator(selected);
      },
    ],
  ]);

  const iterationPhaseResult = recurReduceIterationPhase(
    readValue(rowData),
    newAccFrom(accKeysFrom(iterativeCheckers)),
    iterativeCheckers,
    failFast,
  );

  const [iterationPhaseSurvived, iterationResult] = bifurcateResult(iterationPhaseResult);

  if (failFast && phaseResultHasError(iterationResult)) {
    return echoLog(iterationPhaseResult, 'FastFail in recurReduceIterationPhase');
  }

  // bifurcation phase
  const bifurcatingCheckers = [];

  const bifurcationPhaseResult = recurReduceBifurcationPhase(
    bifurcatingCheckers,
    readSurvived(iterationPhaseSurvived),
    newAccFrom(accKeysFrom(bifurcatingCheckers)),
    failFast,
  );

  const [bifurcationPhaseSurvived, bifurcationResult] = bifurcateResult(bifurcationPhaseResult);

  if (failFast && phaseResultHasError(bifurcationResult)) {
    return echoLog(bifurcationPhaseResult, 'FastFail in recurReduceBifurcationPhase');
  }

  // group-by phase
  const remapKeys = renameKeys({
    duplicates: 'conflicting',
    uniques: ValidKey,
  });

  const groupByResult = bifurcateDuplicates(readSurvived(bifurcationPhaseSurvived));

  return R.mergeAll([iterationResult, bifurcationResult, remapKeys(groupByResult)]);
};

const segregateAllByDataQuality = R.map(R.compose(segregateByDataQuality, R.prop('value')));

const toOrGroupPayloadOfValidRules = segregatedData => {
  const validOrGroupData = R.map(R.prop(DataQualityResultValidKey), segregatedData);

  return toOrGroupPayload(validOrGroupData);
};

const dataQualityStats = R.map(R.map(R.length));

const dataErrorStats = data =>
  isNilOrEmpty(data) ? [{ noData: 1 }] : R.map(a => R.filter(b => b > 0, R.omit([DataQualityResultValidKey], a)), dataQualityStats(data));

const dataHasErrors = errorStats => R.any(a => R.any(b => b > 0, R.values(a)), errorStats);

const seedDataError = [
  {
    incomplete: 1,
  },
];

/**
 * Validate the given rule data/expressions.
 *
 * The input rule data structure is that contained in the UI state of the `OrRuleGroup` component (not in the Redux store).
 *
 * The rule data in the Redux store can be converted to the UI structure using {@link toOrGroupDataStruct2}.
 *
 * The return value is an array of error messages, one per `OR` rule group.
 *
 *  - If all rules in the group are valid, this item as an empty object `{}`.
 *
 *  - If there are incomplete rules in the group the object is:
 *    ```
 *    { incomplete: number }
 *    ```
 *    where `incomplete` is the number of incomplete rules in this `OR` rule group.
 *
 *  - Additional possible values are to be documented.
 *
 *
 * ## Sample:
 *
 * ```
 * [
 *  { incomplete: 2 },
 *  {},
 *  { incomplete: 1 },
 * ]
 * ```
 *
 * There are 3 `OR` rule groups.
 *  1. 2 `AND` rules are incomplete in the first group.
 *  2. The second group has no invalid rules.
 *  3. The third group has one incomplete rule.
 *
 * @param {*} ruleData Rules to be validated
 * @returns {Array<{}>} Array of error messages
 * @see toOrGroupDataStruct2
 */
const validateRuleData = compose(dataErrorStats, segregateAllByDataQuality);

export {
  FeatureLevelLabels,
  optionsByBreadcrumb,
  pathsByFeatureName,
  createSeedData,
  selectedFeatureReader,
  selectedFeatureLens,
  toOrGroupDataStruct2,
  toTableDataFormat,
  retainUserInputIfNecessary2,
  retainSubFeatureUserInputIfNecessary,
  withKey,
  segregateAllByDataQuality,
  toOrGroupPayloadOfValidRules,
  dataErrorStats,
  dataHasErrors,
  seedDataError,
  isSubFeature,
  arrayTypes,
  isArrayType,
  R,
  validateRuleData,
};

export type FeatureDicts = {
  dataTypeDict: Record<string, any>;
  optionDictDict: Record<string, any>;
  originDict: Record<string, any>;
  pathDict: Record<string, any>;
};
