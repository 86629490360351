import * as React from 'react';

interface PageProps {
  classes: {
    page?: string;
    header?: string;
  };
  children: React.ReactElement<any>;
  renderHeader?(...args: unknown[]): unknown;
}

export const Page = ({ classes, children, renderHeader = null }: PageProps) => (
  <section className={classes.page}>
    {renderHeader && <section className={classes.header}>{renderHeader()}</section>}
    <section className={classes.body}>{children}</section>
  </section>
);
