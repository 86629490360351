import React, { type JSX } from 'react';
import { sc } from 'app/styles';
import styled, { CSSProp } from 'styled-components';
import { CSVLink as _CSVLink } from 'react-csv';

interface LabelKeyObject {
  label: string;
  key: string;
}

type Props = {
  headers: LabelKeyObject[];
  data: object[];
  filename: string;
  linkText: string;
};

export const DownloadCSV = ({ headers, data = [], filename, linkText = 'Download' }: Props): JSX.Element => {
  return (
    <CSVLink headers={headers} data={data} filename={filename}>
      {linkText}
    </CSVLink>
  );
};

const CSVLink = styled(_CSVLink)`
  display: flex;
  align-items: center;
  padding-left: ${sc.gutterLargest};
  padding-right: ${sc.gutterLargest};
  height: 55px;
  color: ${(props): CSSProp => (props.data && props.data.length > 0 ? sc.primary : sc.greyLight)};
  pointer-events: ${(props): CSSProp => (props.data && props.data.length > 0 ? 'auto' : 'none')};
`;
