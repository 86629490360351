import * as React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import { compose } from 'ramda';
import qs from 'query-string';
import styled from 'styled-components';

import { inject, withAppConfig } from 'app/decorators';
import { binderActions, binderOperations } from 'app/ducks';
import { asDropdownOptionsWithAll } from 'app/features/BannerManagement/common/helpers';
import withSlotGroupTypes from 'app/features/BannerManagement/hoc/withSlotGroupTypes';
import withWidgetTypes from 'app/features/BannerManagement/hoc/withWidgetTypes';
import { urlView } from 'app/features/BannerManagement/routeUtils';
import { Button, Dropdown as _Dropdown, PageMain, Spinner as _Spinner, Tabs as _Tabs, TextField } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { ISlotGroupType, IViewItem } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

import SortableTable from './SortableTable';

const filterViewItems = (value: string, viewItems: Array<IViewItem>) => {
  if (!value || !value.length) {
    return viewItems;
  }

  return viewItems.filter((viewItem: IViewItem) => {
    if ((viewItem.name || '').indexOf(value) > -1) {
      return true;
    }

    if (viewItem.createdBy && viewItem.createdBy.indexOf(value) > -1) {
      return true;
    }

    if (String(viewItem.sourceCreativeId).indexOf(value) > -1) {
      return true;
    }

    if (viewItem.variants && viewItem.variants[0] && String(viewItem.variants[0].catalogBannerId).indexOf(value) > -1) {
      return true;
    }

    return false;
  });
};

type Props = {
  allPlatforms: Array<string>;
  allSites: Array<string>;
  appConfig: IAppConfig;
  binderActions: typeof binderActions;
  binderOperations: typeof binderOperations;
  entityId?: number;
  entityType?: string;
  highlightedViewItem: Record<string, any> | null | undefined;
  history: History;
  loading: boolean;
  onBind: (arg0?: number | null) => (...args: Array<any>) => any;
  onBulkDelete: () => unknown;
  onDelete: (...args: Array<any>) => any;
  orderUpdated: boolean;
  readOnly?: boolean;
  reorderViewItems: (...args: Array<any>) => any;
  saveOrder: () => any;
  selectedSlotGroupType: ISlotGroupType;
  slotGroupTypes: Array<ISlotGroupType>;
  slotId: number;
  slotViewItems: Array<IViewItem>;
  slotViewItemsPage: number;
  slotViewItemsPageSize: number;
  slotViewItemsTotalPages: number;
  storefrontId: number;
  timezone: string;
  viewId: string | number;
};

type State = {
  platformFilter: string;
  searchFilter: string;
  siteFilter: string;
};

class SlotContents extends React.PureComponent<Props, State> {
  state = {
    platformFilter: '',
    searchFilter: '',
    siteFilter: '',
  };

  componentDidMount() {
    const { selectedSlotGroupType } = this.props;
    this.initSlotViewItems(selectedSlotGroupType);
  }

  initSlotViewItems = (slotGroupType: ISlotGroupType) => {
    const {
      slotId,
      binderActions: { resetHighlightedViewItem, setSelectedSlotGroupType },
      binderOperations: { getSlotViewItems },
    } = this.props;

    resetHighlightedViewItem();
    setSelectedSlotGroupType(slotGroupType);
    this.updateSlotGroupTypeQuery(slotGroupType);
    getSlotViewItems(slotId, { type: slotGroupType.value });
  };

  onBindClicked = () => {
    const { onBind } = this.props;
    onBind();
  };

  onChangeFilter = (value: string) => {
    this.setState({ searchFilter: value });
  };

  onChangePage = (page: number) => {
    const {
      binderActions: { resetHighlightedViewItem },
      binderOperations: { getSlotViewItems },
      selectedSlotGroupType,
      slotId,
    } = this.props;

    resetHighlightedViewItem();
    getSlotViewItems(slotId, {
      type: selectedSlotGroupType.value,
      page,
    });
  };

  onChangePlatform = (platformFilter: string) => {
    const { siteFilter } = this.state;
    this.setState({ platformFilter });
    this.onSearch(siteFilter, platformFilter);
  };

  onChangeSite = (siteFilter: string) => {
    const { platformFilter } = this.state;
    this.setState({ siteFilter });
    this.onSearch(siteFilter, platformFilter);
  };

  onChangeSlotGroupTab = (newIndex: number) => {
    const { slotGroupTypes } = this.props;
    this.initSlotViewItems(slotGroupTypes[newIndex]);
  };

  onRemoveClicked = () => {
    const { onBulkDelete } = this.props;
    onBulkDelete();
  };

  onSearch = (siteFilter: string, platformFilter: string) => {
    const {
      binderOperations: { getSlotViewItems },
      selectedSlotGroupType,
      slotId,
    } = this.props;
    const params = { type: selectedSlotGroupType.value };

    if (siteFilter && siteFilter !== 'ALL') {
      params.site = siteFilter;
    }

    if (platformFilter && platformFilter !== 'ALL') {
      params.platform = platformFilter;
    }

    getSlotViewItems(slotId, params);
  };

  updateSlotGroupTypeQuery = (slotGroupType: ISlotGroupType) => {
    const { entityId, entityType, history, storefrontId, viewId } = this.props;

    if (storefrontId) {
      const params = qs.parse(history.location.search);
      const query = Object.assign(params, { slotGroupType: slotGroupType.value });
      history.replace(urlView(viewId, storefrontId, entityId, entityType, query));
    }
  };

  render() {
    const { platformFilter, searchFilter, siteFilter } = this.state;
    const {
      allPlatforms,
      allSites,
      appConfig,
      binderOperations: { getSlotViewItems },
      entityId,
      entityType,
      highlightedViewItem,
      loading,
      onBind,
      onDelete,
      orderUpdated,
      readOnly,
      reorderViewItems,
      selectedSlotGroupType,
      saveOrder,
      slotGroupTypes,
      slotId,
      slotViewItems,
      slotViewItemsPage,
      slotViewItemsPageSize,
      slotViewItemsTotalPages,
      storefrontId,
      timezone,
      viewId,
    } = this.props;

    const selectedTabIndex = selectedSlotGroupType
      ? slotGroupTypes.findIndex(slotGroupType => slotGroupType.value === selectedSlotGroupType.value)
      : slotGroupTypes.findIndex(slotGroupType => slotGroupType.value === slotGroupTypes[0].value);

    // Legacy code uses Array<string> instead of Array<IDropdownOption>, so make backward compatible
    const allPlatformNames = asDropdownOptionsWithAll(allPlatforms).map(({ label }) => label);
    const allSiteNames = asDropdownOptionsWithAll(allSites || []).map(({ label }) => label);

    const { useBulkDelete } = getBannerCreativeConfig(appConfig);

    return (
      <PageMain>
        <ActionsContainer>
          <Search>
            {!!allSiteNames.length && <Dropdown label="Site" value={siteFilter} options={allSiteNames} onChange={this.onChangeSite} />}

            <Dropdown label="Platform" value={platformFilter} options={allPlatformNames} onChange={this.onChangePlatform} />

            <SearchField
              type="text"
              id="search"
              name="search"
              label="Filter by Creator, Creative ID, Banner ID, etc"
              value={searchFilter}
              onChange={ev => this.onChangeFilter(ev.target.value)}
            />
          </Search>

          <Buttons>
            <Button type="secondary" onClick={() => saveOrder()} disabled={!orderUpdated || readOnly}>
              Save Order
            </Button>

            {useBulkDelete && (
              <Button onClick={this.onRemoveClicked} disabled={readOnly}>
                Remove
              </Button>
            )}

            <Button onClick={this.onBindClicked} disabled={readOnly}>
              Bind
            </Button>
          </Buttons>
        </ActionsContainer>

        <Tabs
          tabs={slotGroupTypes}
          value={selectedTabIndex}
          onChange={this.onChangeSlotGroupTab}
          spacing={2}
          shifted={!!slotViewItems.length}
        />

        {loading ? (
          <Spinner size={26} />
        ) : (
          <SortableTable
            entityId={entityId}
            entityType={entityType}
            fetchSlotViewItems={page => getSlotViewItems(slotId, { type: selectedSlotGroupType.value, page })}
            highlightedViewItem={highlightedViewItem}
            onBind={onBind}
            onDelete={onDelete}
            onPageChange={this.onChangePage}
            readOnly={readOnly}
            reorderViewItems={reorderViewItems}
            slotViewItems={filterViewItems(searchFilter, slotViewItems)}
            slotViewItemsPage={slotViewItemsPage}
            slotViewItemsPageSize={slotViewItemsPageSize}
            slotViewItemsTotalPages={slotViewItemsTotalPages}
            storefrontId={storefrontId}
            timezone={timezone}
            viewId={viewId}
          />
        )}
      </PageMain>
    );
  }
}

const mapStateToProps = ({ bannerManagement: { binder } }) => ({
  highlightedViewItem: binder.highlightedViewItem,
  loading: binder.slotViewItemsLoading,
  orderUpdated: binder.orderUpdated,
  slotViewItems: binder.slotViewItems,
  slotViewItemsPage: binder.slotViewItemsPage,
  slotViewItemsPageSize: binder.slotViewItemsPageSize,
  slotViewItemsTotalPages: binder.slotViewItemsTotalPages,
});

export default compose(
  withAppConfig,
  withWidgetTypes,
  withSlotGroupTypes,
  connect(mapStateToProps),
  inject({
    binderActions: binderActions,
    binderOperations: binderOperations,
  }),
)(SlotContents);

const ActionsContainer = styled.section`
  align-items: center;
  display: flex;
  margin-bottom: ${sc.gutterLarge};
  & > div:first-child {
    margin-right: ${sc.gutterSmall};
  }
`;

const Buttons = styled.div`
  & > button {
    margin-right: ${sc.gutterSmaller};
  }
  & > button:last-child {
    margin-right: 0;
  }
`;

const Dropdown = styled(_Dropdown)`
  width: 12rem;
`;

const Search = styled.div`
  display: flex;
  flex-grow: 1;
  & > div {
    margin-right: ${sc.gutterSmall};
  }
  & > div:last-child {
    margin-right: 0;
  }
`;

const SearchField = styled(TextField)`
  flex-grow: 1;
`;

const Spinner = styled(_Spinner)`
  padding: ${sc.gutterLargest};
`;

const Tabs = styled(_Tabs)`
  padding: 0 ${props => (props.shifted ? '80px' : sc.gutter)};
`;
