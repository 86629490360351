import * as React from 'react';

import { FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';

interface SelectAsMenuProps {
  id?: string;
  label?: string;
  selectedId?: string | number;
  items?: {
    id?: string | number;
    name?: string;
  }[];
  handleChange(...args: unknown[]): unknown;
  disabled?: boolean;
  required?: boolean;
  classes?: {
    root?: string;
  };
}

export const SelectAsMenu = ({
  id = 'select',
  label = 'Select',
  selectedId = '',
  items = [],
  handleChange,
  disabled = false,
  required = false,
  classes = {},
}: SelectAsMenuProps) => (
  <FormControl disabled={disabled} className={classes.root}>
    {label && (
      <InputLabel required={required} htmlFor={id}>
        {label}
      </InputLabel>
    )}

    <Select autoWidth value={selectedId} onChange={handleChange} input={<Input name={id} id={id} />}>
      {items.map(item => (
        <MenuItem data-test-id="menu-item" data-qa="menu-item" key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
