import * as React from 'react';
import styled from 'styled-components';
import { Chip, Grid, Typography } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { audienceTypeDisplay } from 'app/utilities/constants';

import { Card, Page } from 'app/components';
import { Button } from 'app/midgarComponents';
import { segmentCreationPermissions, segmentDownloadPermissions } from 'configs/permissions';
import sc from 'app/styles/config';

import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { segmentTypeFlags } from 'app/features/Segments2/utils';

import { Switcher } from './Components/Switcher';
import { markForExtraction } from '../../../api/segments2';
import { formattedText } from './helpers';
import Download from '../components/Download';

const Title = ({ audienceType }) => {
  switch (audienceType) {
    case segmentTypeFlags.CSV_BASED:
      return audienceTypeDisplay.csv;
    case segmentTypeFlags.COMPOSITION:
      return audienceTypeDisplay.composition;
    default:
      return audienceTypeDisplay.rule;
  }
};

interface Props {
  description: string;
  name: string;
  tags: Array<{ id: number; name: string }>;
  audienceType: string;
  classes: {
    title: string;
    grid: string;
    inline: string;
    half: string;
    cardBody: string;
    card: string;
    full: string;
    header: string;
    chip: string;
  };

  toTableData: () => () => void;
  featureMetadata: {};
  includedRules: Array<{}>;
  id: number;
  countOfSegment: (rule: object) => void;
  state: string;
  membershipCsvFileUri: string;
  creatorEmail: string;
  backToTableHandler: () => void;
  handleCloneClicked: () => void;
  isPermitted: IsPermittedFn;
  includedSeedSegments: Array<{}>;
  excludedSeedSegments: Array<{}>;
  includedUniverseSegments: Array<{}>;
  excludedUniverseSegments: Array<{}>;
  maxAudienceSize: number;
  ingestState: string;
  handleScheduleExtraction: () => void;
  audienceCreationRules: object;
  audienceId: number;
  includedSegments: unknown;
  excludedSegments: unknown;
  history: History;
}

export default class Overview extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      extractionStatus: '',
    };
  }
  calculate = () => {
    const { countOfSegment, audienceCreationRules } = this.props;
    countOfSegment(audienceCreationRules);
    return true;
  };

  onClick = (ev: Event) => {
    const { membershipCsvFileUri } = this.props;
    ev.preventDefault();
    ev.stopPropagation();

    const link = document.createElement('a');

    link.setAttribute('download', null);
    link.style.display = 'none';

    document.body.appendChild(link);

    link.setAttribute('href', membershipCsvFileUri);
    link.click();

    document.body.removeChild(link);
    return true;
  };

  render() {
    const {
      description,
      name,
      tags,
      audienceType,
      classes,
      toTableData,
      featureMetadata,
      includedRules,
      id,
      state,
      membershipCsvFileUri,
      creatorEmail,
      backToTableHandler,
      handleCloneClicked,
      isPermitted,
      includedSeedSegments,
      excludedSeedSegments,
      includedUniverseSegments,
      excludedUniverseSegments,
      maxAudienceSize,
      audienceId,
      includedSegments,
      excludedSegments,
      history,
    } = this.props;

    const handleScheduleExtraction = async () => {
      try {
        const response = await markForExtraction(String(audienceId));
        this.setState({ extractionStatus: response?.extractionStatus });
      } catch (error) {
        console.error('Error while mark for extraction', error);
      }
    };

    return (
      <Page
        renderHeader={() => [
          <Typography className={classes.title} key="header" variant="subtitle1">
            <Title audienceType={audienceType} />
            <KeyboardArrowRight />
            {name}
          </Typography>,
          <React.Fragment key="headerLinkDownload">
            {isPermitted(segmentDownloadPermissions) && <Download id={audienceId} />}
          </React.Fragment>,
          <ActionButtons key="actions">
            {audienceType === 'RULE_BASED' && (
              <Button color="primary" onClick={handleScheduleExtraction} data-qa="schedule-extraction-btn">
                Schedule Extraction
              </Button>
            )}

            {audienceType !== 'STATIC' && isPermitted(segmentCreationPermissions) && (
              <Button data-qa="clone-btn" onClick={handleCloneClicked} color="primary">
                Clone
              </Button>
            )}

            <Button data-qa="return-btn" onClick={backToTableHandler} color="primary">
              Return to Audiences V2
            </Button>
          </ActionButtons>,
        ]}
      >
        <section className={classes.grid}>
          <Grid className={classes.inline}>
            <Grid item className={classes.half}>
              <Card className={classes.card} label="General">
                <Typography>Description: {description}</Typography>
                <Typography>Creator: {creatorEmail}</Typography>
                {this.state.extractionStatus && <Typography>Extraction Status: {formattedText(this.state.extractionStatus)}</Typography>}
              </Card>
            </Grid>
            <Grid item className={classes.half}>
              <Card label="Tag manager">
                <section className={classes.cardBody}>
                  {tags.map(({ name: chipName, id: tagId }) => (
                    <Chip label={chipName} key={tagId} className={classes.chip} />
                  ))}
                </section>
              </Card>
            </Grid>
          </Grid>
          {/* The audience extraction table will be implemented further once the BE API is ready*/}
          {/* {audienceType === 'RULE_BASED' && (
             <Grid item className={classes.full}>
               <Card label="Audience Extraction Table" className={classes.header}>
                 <section className={classes.cardBody}>
                   <ScheduleExtractionTable />
                 </section>
               </Card>
             </Grid>
            )} */}

          <Grid item className={classes.full}>
            <Switcher
              calculate={this.calculate}
              {...{
                audienceType,
                classes,
                id,
                state,
                membershipCsvFileUri,
                toTableData,
                featureMetadata,
                includedRules,
                includedSeedSegments,
                excludedSeedSegments,
                includedUniverseSegments,
                excludedUniverseSegments,
                maxAudienceSize,
                includedSegments,
                excludedSegments,
                history,
              }}
            />
          </Grid>
        </section>
      </Page>
    );
  }
}

const ActionButtons = styled.section`
  & > * {
    margin-right: ${sc.gutterSmaller};
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
