import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';

import { IMasterplanApiParams } from 'app/api/masterplan/types';
import { DrawerError as _DrawerError } from 'app/components';
import { withPermissions } from 'app/decorators';
import { masterplanPrioritiesOperations } from 'app/ducks';
import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { Button, Page, PageHeader, PageMain } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { ICampaignPriority } from 'app/types';
import { masterplanEditPermissions } from 'configs/permissions';
import { displayWarning } from 'app/helpers/NotificationHelpers/helpers';

import { LIST_MANUAL, LIST_SYSTEM } from './constants';
import { IListVariant } from './constants';
import DragAndDropPriorityList from './DragAndDropPriorityList';
import { IMasterplanFilterProps } from '../common';
import { Filters, Spinner } from '../common';
import { triggerNames } from '../constants';
import { parseErrorDebugMessage, parseErrorMessage, searchCampaigns } from '../utils';

const LEFT_ARROW = String.fromCharCode(8701);
const RIGHT_ARROW = String.fromCharCode(8702);

type DndItem = {
  droppableId: IListVariant;
  index: number;
};

type DragResult = {
  destination: DndItem;
  draggableId: number;
  source: DndItem;
};

type Props = IMasterplanFilterProps & {
  error: Error;
  getPriorities: (arg0: IMasterplanApiParams) => (...args: Array<any>) => any;
  getSystemPriorities: (arg0: IMasterplanApiParams) => (...args: Array<any>) => any;
  isPermitted: IsPermittedFn;
  loading: boolean;
  manualPriorities: Array<ICampaignPriority>;
  masterplanPrioritiesOperations: typeof masterplanPrioritiesOperations;
  savePriorities: () => (...args: Array<any>) => any;
  systemPreview?: boolean;
  systemPriorities: Array<ICampaignPriority>;
  updatePriorities: (arg0: Array<ICampaignPriority>, arg1: Array<ICampaignPriority>) => (...args: Array<any>) => any;
};

type State = {
  isDirty?: boolean;
  selected: {
    manualPriorities: Array<ICampaignPriority>;
    systemPriorities: Array<ICampaignPriority>;
  };
};

class Priorities extends React.PureComponent<Props, State> {
  state = {
    selected: {
      manualPriorities: [],
      systemPriorities: [],
    },
  };

  componentDidMount() {
    const { date, getPriorities, medium, trigger } = this.props;
    getPriorities({ date, medium, trigger });
  }

  componentDidUpdate(prevProps) {
    const { date, getPriorities, medium, trigger } = this.props;
    if (date !== prevProps.date || medium !== prevProps.medium || trigger !== prevProps.trigger) {
      getPriorities({ date, medium, trigger });
      this.setState({ isDirty: false }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  getList = (variant: IListVariant) => this.props[variant]; // eslint-disable-line react/destructuring-assignment

  getSelected = (variant: IListVariant) => this.state.selected[variant]; // eslint-disable-line react/destructuring-assignment

  handleDeselect = (variant: IListVariant, campaigns: Array<ICampaignPriority>) => {
    const newSelected = campaigns.reduce((acc, campaign) => acc.filter(c => c.id !== campaign.id), [...this.getSelected(variant)]);

    const { selected } = this.state;
    this.setState({
      selected: {
        ...selected,
        [variant]: newSelected,
      },
    });
  };

  handleDragEnd = (dragResult: DragResult) => {
    if (!dragResult || !dragResult.destination || !dragResult.source) {
      return;
    }

    const {
      destination: { droppableId: toList, index: toIndex },
      source: { droppableId: fromList, index: fromIndex },
    } = dragResult;

    if (toList === LIST_SYSTEM) {
      console.error('Dropping to system priorities is not allowed'); // eslint-disable-line no-console
      return;
    }

    const { manualPriorities: rawManualPriorities, systemPriorities: rawSystemPriorities } = this.props;

    const manualPriorities = rawManualPriorities.slice();
    const systemPriorities = rawSystemPriorities.slice();

    if (fromList === LIST_MANUAL && toList === LIST_MANUAL) {
      const srcCampaign = manualPriorities[fromIndex];
      manualPriorities.splice(fromIndex, 1);
      manualPriorities.splice(toIndex, 0, srcCampaign);
      this.handleDeselect(LIST_MANUAL, [srcCampaign]);
    } else if (fromList === LIST_SYSTEM && toList === LIST_MANUAL) {
      // TODO: Clear preview flag -- priorities are now invalidated
      const srcCampaign = systemPriorities[fromIndex];
      systemPriorities.splice(fromIndex, 1);
      manualPriorities.splice(toIndex, 0, srcCampaign);
      this.handleDeselect(LIST_SYSTEM, [srcCampaign]);
    }

    this.handleUpdatePriorities(manualPriorities, systemPriorities);
  };

  handleGetSystemPriorities = () => {
    const { date, getSystemPriorities, medium, trigger } = this.props;
    getSystemPriorities({ date, medium, trigger });
  };

  handleSavePriorities = () => {
    const { savePriorities } = this.props;
    savePriorities();
    this.setState({ isDirty: false });
  };

  handleSelect = (variant: IListVariant, campaign: ICampaignPriority) => {
    const newSelected = [...this.getSelected(variant)];
    const index = newSelected.findIndex(c => c.id === campaign.id);
    if (index >= 0) {
      newSelected.splice(index, 1);
    } else {
      newSelected.push(campaign);
    }

    const { selected } = this.state;
    this.setState({
      selected: {
        ...selected,
        [variant]: newSelected,
      },
    });
  };

  handleSend = (variant: IListVariant) => () => {
    const campaigns = this.getSelected(variant);
    if (!campaigns || !campaigns.length) {
      return undefined;
    }

    const srcVariant = variant;
    const destVariant = variant === LIST_MANUAL ? LIST_SYSTEM : LIST_MANUAL;

    const src = this.getList(srcVariant);
    const dest = this.getList(destVariant);

    const resSrc = src.reduce(
      ({ matches, rest }, thisCampaign) =>
        campaigns.findIndex(c => c.id === thisCampaign.id) >= 0
          ? { matches: [...matches, thisCampaign], rest }
          : { matches, rest: [...rest, thisCampaign] },
      { matches: [], rest: [] },
    );

    const newDest = [...resSrc.matches, ...dest];

    this.handleDeselect(srcVariant, campaigns);

    if (variant === LIST_MANUAL) {
      this.handleUpdatePriorities(resSrc.rest, newDest);
    } else {
      this.handleUpdatePriorities(newDest, resSrc.rest);
    }
  };

  handleUpdatePriorities = (manualPriorities: Array<ICampaignPriority>, systemPriorities: Array<ICampaignPriority>) => {
    const { isDirty } = this.state;
    if (!isDirty) {
      displayWarning('Cannot preview system priorities when changes are pending');
    }
    this.setState({ isDirty: true });
    const { updatePriorities } = this.props;
    updatePriorities(manualPriorities, systemPriorities);
  };

  render() {
    const {
      date,
      error,
      handleChangeDate,
      handleChangeMedium,
      handleChangeTrigger,
      handleSearch,
      isPermitted,
      loading,
      manualPriorities: manualPrioritiesRaw,
      medium,
      search,
      systemPreview,
      systemPriorities: systemPrioritiesRaw,
      trigger,
    } = this.props;

    const { isDirty } = this.state;
    const isEditable = isPermitted(masterplanEditPermissions) && !loading;

    const manualPriorities = searchCampaigns(manualPrioritiesRaw, search);
    const systemPriorities = searchCampaigns(systemPrioritiesRaw, search);

    return (
      <Page>
        <PageHeader title="Masterplan">
          <HeaderButtonsSection>
            <Button type="primary" onClick={this.handleSavePriorities} disabled={!isEditable || !isDirty}>
              Save
            </Button>
          </HeaderButtonsSection>
        </PageHeader>

        <PageMain>
          <Filters
            date={date}
            handleChangeDate={handleChangeDate}
            handleChangeMedium={handleChangeMedium}
            handleChangeTrigger={handleChangeTrigger}
            handleSearch={handleSearch}
            medium={medium}
            search={search}
            trigger={trigger}
          />

          {loading ? (
            <Spinner />
          ) : (
            <>
              {error && <DrawerError error={parseErrorMessage(error)}>{parseErrorDebugMessage(error)}</DrawerError>}

              <DragDropContext onDragEnd={this.handleDragEnd}>
                <PrioritiesLayout>
                  <PrioritiesSection>
                    <PrioritiesHeader>
                      <Title>Manual Priority Overrides</Title>
                    </PrioritiesHeader>

                    <DragAndDropPriorityList
                      campaigns={manualPriorities}
                      droppableId={LIST_MANUAL}
                      onSelect={this.handleSelect}
                      search={search}
                      selected={this.getSelected(LIST_MANUAL)}
                      showRecurring={trigger === triggerNames.scheduled}
                      variant={LIST_MANUAL}
                      dataQa="manualPriorities"
                    />
                  </PrioritiesSection>

                  <MoveControls>
                    <Action onClick={this.handleSend(LIST_MANUAL)} disabled={(this.getSelected(LIST_MANUAL) || []).length === 0}>
                      {RIGHT_ARROW}
                    </Action>
                    <Action
                      onClick={this.handleSend(LIST_SYSTEM)}
                      data-qa="masterplan-left"
                      disabled={(this.getSelected(LIST_SYSTEM) || []).length === 0}
                    >
                      {LEFT_ARROW}
                    </Action>
                  </MoveControls>

                  <PrioritiesSection>
                    <PrioritiesHeader>
                      <Title>System Priorities</Title>

                      {systemPriorities && systemPriorities.length > 1 && (
                        <div>
                          <PreviewPrioritiesButton onClick={this.handleGetSystemPriorities} disabled={systemPreview || isDirty}>
                            Preview System Priorities
                          </PreviewPrioritiesButton>
                        </div>
                      )}
                    </PrioritiesHeader>

                    <DragAndDropPriorityList
                      campaigns={systemPriorities}
                      droppableId={LIST_SYSTEM}
                      isDropDisabled
                      onSelect={this.handleSelect}
                      search={search}
                      selected={this.getSelected(LIST_SYSTEM)}
                      showPriorities={systemPreview && !isDirty}
                      showRecurring={trigger === triggerNames.scheduled}
                      variant={LIST_SYSTEM}
                    />
                  </PrioritiesSection>
                </PrioritiesLayout>
              </DragDropContext>
            </>
          )}
        </PageMain>
      </Page>
    );
  }
}

export default compose(
  withPermissions,
  connect(
    ({
      masterplan: {
        priorities: { error, loading, manualPriorities, systemPreview, systemPriorities },
      },
    }) => ({
      error,
      loading,
      manualPriorities,
      systemPreview,
      systemPriorities,
    }),

    masterplanPrioritiesOperations,
  ),
)(Priorities);

const Action = styled.a`
  display: block;
  font-size: ${sc.fontSizeLargest};
  ${props =>
    props.disabled
      ? `
    color: ${sc.greyLight};
    cursor: default;
  `
      : ''}
`;

const DrawerError = styled(_DrawerError)`
  margin-bottom: ${sc.gutterLargest};
`;

const HeaderButtonsSection = styled.div`
  & > * {
    float: right;
    margin-right: ${sc.gutterSmall};
    vertical-align: top;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

const MoveControls = styled.div`
  width: 23px;
  margin: auto 5px;
`;

const PreviewPrioritiesButton = styled(Button).attrs(() => ({
  type: 'secondary',
  size: 'small',
}))`
  font-weight: normal;
  font-size: ${sc.fontSizeSmaller};
`;

const PrioritiesHeader = styled.div`
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: space-between;
  margin: 0 0 ${sc.gutterSmall} ${sc.gutterSmall};
`;

const PrioritiesLayout = styled.div`
  display: flex;
`;

const PrioritiesSection = styled.section`
  flex-grow: 1;
  min-width: 10rem;
  width: calc(50% - 33px);
`;

const Title = styled.div`
  color: ${sc.headingColor};
  font-weight: bold;
`;

// TODO: If a locked indicator is needed, use the following:
/*
<LockLabel>
  { masterplan.locked ? (
    <React.Fragment>
      <Icon name="lock" />
      <span>Locked</span>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Icon name="lock-open" />
      <span>Unlocked</span>
    </React.Fragment>
  ) }
</LockLabel>

const Locked = styled.div`
  display: inline-block;
  background-color: ${sc.greyLighter};
  border-radius: 5px;
  padding: ${sc.gutterSmaller};

  & > span {
    display: inline-block;
    padding: 0 2px 0 0;
    color: ${sc.headingColor};
    font-size: ${sc.fontSizeSmaller};
    text-transform: uppercase;
  }
`;

const LockLabel = styled.span`
  display: flex;
  align-items: center;
  background-color: ${sc.greyLighter};
  border-radius: 5px;
  padding: ${sc.gutterSmaller};

  & > span {
  display: inline-block;
  padding: 0 2px 0 0;
  color: ${sc.headingColor};
  font-size: ${sc.fontSizeSmaller};
  text-transform: uppercase;
  }
`;

<Button
  type="secondary"
  onClick={lockMasterplan}
  disabled={!isEditable}
>
  Lock
</Button>
*/
