import { channelsPermissions } from 'app/features/Channels/link.config';
import { urlStorefronts } from 'app/features/BannerManagement/routeUtils';
import { permissionReducer } from 'app/utilities/permissions';
import * as permissions from 'configs/permissions';
import * as RoutesConfig from 'configs/routes';
import { ConfigsState } from 'app/types';
import { INavItem } from './Layout';

export default (userPermissions: Array<string>, config: ConfigsState): Array<INavItem> => {
  const { supportedRoutes } = config;

  const isPermitted = permissionReducer(userPermissions);

  return [
    {
      key: 'campaigns',
      label: 'Campaigns',
      to: RoutesConfig.CAMPAIGNS,
      relativeMatch: true,
      iconName: 'campaign',
      disable: !isPermitted(permissions.campaignReadPermissions) || !supportedRoutes.includes(RoutesConfig.CAMPAIGNS),
    },

    {
      key: 'segments',
      label: 'Audiences',
      to: RoutesConfig.SEGMENTS_LANDING,
      relativeMatch: true,
      iconName: 'folder-open',
      disable: !isPermitted(permissions.segmentReadPermissions) || !supportedRoutes.includes(RoutesConfig.SEGMENTS_LANDING),
    },

    {
      key: 'audience',
      label: 'Audiences V2',
      to: RoutesConfig.SEGMENTS_LANDING_2,
      relativeMatch: true,
      iconName: 'grid',
      disable: !isPermitted(permissions.segmentReadPermissions) || !supportedRoutes.includes(RoutesConfig.SEGMENTS_LANDING_2),
    },

    {
      key: 'features',
      label: 'Features',
      to: RoutesConfig.FEATURES,
      relativeMatch: true,
      iconName: 'filters',
      disable: !supportedRoutes.includes(RoutesConfig.FEATURES),
    },

    {
      key: 'channels',
      label: 'Channels',
      to: RoutesConfig.CHANNELS,
      iconName: 'grid',
      disable: !isPermitted(channelsPermissions) || !supportedRoutes.includes(RoutesConfig.CHANNELS),
      children: [
        {
          key: 'banner-email',
          label: 'Email',
          to: RoutesConfig.CHANNELS_EMAIL_TEMPLATE_REPORTING,
          disable:
            !isPermitted(permissions.templateReadPermissions) || !supportedRoutes.includes(RoutesConfig.CHANNELS_EMAIL_TEMPLATE_REPORTING),
        },

        {
          key: 'banner-debug',
          label: 'Banner Debug',
          to: RoutesConfig.CHANNELS_BANNER_DEBUG_UPCOMING,
          disable:
            !isPermitted(permissions.bannerDebugReadPermissions) || !supportedRoutes.includes(RoutesConfig.CHANNELS_BANNER_DEBUG_UPCOMING),
        },
      ],
    },

    {
      key: 'masterplan',
      label: 'Masterplan',
      to: RoutesConfig.MASTERPLAN,
      relativeMatch: true,
      disable: !isPermitted(permissions.masterplanReadPermissions) || !supportedRoutes.includes(RoutesConfig.MASTERPLAN),
      iconName: 'file',
    },

    {
      key: 'banners',
      label: 'Banner Management',
      to: RoutesConfig.BANNERS,
      disable: !isPermitted(permissions.bannerManagementReadPermissions) || !supportedRoutes.includes(RoutesConfig.BANNERS),
      iconName: 'settings',
      children: [
        {
          key: 'banner-creatives',
          label: 'Banner Creatives',
          to: RoutesConfig.BANNERS_CREATIVE,
          disable: !isPermitted(permissions.bannerManagementReadPermissions) || !supportedRoutes.includes(RoutesConfig.BANNERS_CREATIVE),
        },

        {
          key: 'banner-storefronts',
          label: 'Banner Slot Binder',
          to: urlStorefronts(),
          disable:
            !isPermitted(permissions.bannerManagementReadPermissions) || !supportedRoutes.includes(RoutesConfig.BANNERS_STOREFRONTS_REPORT),
        },
      ],
    },

    {
      key: 'cashbackConfig',
      label: 'Cashback Promo',
      to: RoutesConfig.CASHBACK_MANAGEMENT,
      iconName: 'settings',
      disable: !isPermitted(permissions.allCashbackManagementPermissions) || !supportedRoutes.includes(RoutesConfig.CASHBACK_MANAGEMENT),
      children: [
        {
          key: 'cashback-banners',
          label: 'Banners',
          to: RoutesConfig.CASHBACK_MANAGEMENT_BANNERS,
          disable:
            !isPermitted(permissions.allCashbackManagementPermissions) ||
            !supportedRoutes.includes(RoutesConfig.CASHBACK_MANAGEMENT_BANNERS),
        },

        {
          key: 'user-budget-ledger',
          label: 'User Budget',
          to: RoutesConfig.CASHBACK_MANAGEMENT_USER_BUDGET_TOKENS,
          disable:
            !isPermitted(permissions.allCashbackManagementPermissions) ||
            !supportedRoutes.includes(RoutesConfig.CASHBACK_MANAGEMENT_USER_BUDGET_TOKENS),
        },

        {
          key: 'global-payment-limit',
          label: 'Global Payment Limits',
          to: RoutesConfig.CASHBACK_MANAGEMENT_GLOBAL_LIMITS,
          disable:
            !isPermitted(permissions.allCashbackManagementPermissions) ||
            !supportedRoutes.includes(RoutesConfig.CASHBACK_MANAGEMENT_GLOBAL_LIMITS),
        },

        {
          key: 'event-schema',
          label: 'Event Schema',
          to: RoutesConfig.CASHBACK_MANAGEMENT_EVENT_SCHEMAS,
          disable:
            !isPermitted([permissions.CASHBACK_MANAGEMENT_EVENT_SCHEMA_CREATE]) ||
            !supportedRoutes.includes(RoutesConfig.CASHBACK_MANAGEMENT_EVENT_SCHEMAS),
        },

        {
          key: 'cashback-stackable-groups',
          label: 'Stackable Groups',
          to: RoutesConfig.CASHBACK_MANAGEMENT_STACKABLE_GROUPS,
          disable:
            !isPermitted(permissions.allCashbackManagementPermissions) ||
            !supportedRoutes.includes(RoutesConfig.CASHBACK_MANAGEMENT_STACKABLE_GROUPS),
        },
      ],
    },

    {
      key: 'users',
      iconName: 'account',
      label: 'Users',
      relativeMatch: true,
      to: RoutesConfig.USERS,
    },
  ];
};
