import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { IMasterplanApiParams } from 'app/api/masterplan/types';
import { DrawerError } from 'app/components';
import { masterplanExecutionsOperations } from 'app/ducks';
import { Icon, Page, PageHeader, PageMain, Spinner } from 'app/midgarComponents';
import {
  ResponsiveTableContainer,
  Table as _Table,
  TableBody,
  TableCell as _TableCell,
  TableHeader,
  TableHeaderCell as _TableHeaderCell,
  TableRow as _TableRow,
} from 'app/midgarComponents/Table/StripedTable';

import { sc } from 'app/styles';
import { ICampaignExecution, IAppConfig } from 'app/types';
import { dedupeStrategies } from 'app/utilities/constants';
import withAppConfig from 'app/decorators/withAppConfig';
import { MANUAL, finalStates, CSV_HEADERS } from '../constants';
import { IMasterplanFilterProps } from '../common';
import { Filters, Highlight, Pill as _Pill } from '../common';
import { parseErrorDebugMessage, parseErrorMessage, toFinalState, searchCampaigns, extendExecutions } from '../utils';
import { compose } from 'recompose';

type Props = IMasterplanFilterProps & {
  error: Error;
  executions: Array<ICampaignExecution>;
  getExecutions: (arg0: IMasterplanApiParams) => void;
  loading: boolean;
  appConfig: IAppConfig;
};

class Executions extends React.Component<Props> {
  componentDidMount() {
    const { date, getExecutions, medium, trigger } = this.props;
    getExecutions({ date, medium, trigger });
  }

  componentDidUpdate(prevProps) {
    const { date, getExecutions, medium, trigger } = this.props;
    if (date !== prevProps.date || medium !== prevProps.medium || trigger !== prevProps.trigger) {
      getExecutions({ date, medium, trigger });
    }
  }

  renderPriorityColumn(execution) {
    const { appConfig } = this.props;

    if (appConfig.hideNonManualPriorityType) {
      return (
        <Priority>
          {execution.priorityType === MANUAL && (
            <>
              {execution.priority}
              <sup>*</sup>
            </>
          )}
        </Priority>
      );
    }

    return (
      <Priority>
        {execution.priority}
        {execution.priorityType === MANUAL && <sup>*</sup>}
      </Priority>
    );
  }

  stateIcons = {
    [finalStates.success]: <IconSuccess />,
    [finalStates.failed]: <IconFailed />,
    [finalStates.waiting]: <IconWaiting />,
    [finalStates.processing]: <IconProcessing />,
    [finalStates.unknown]: <IconUnknown />,
  };

  render() {
    const {
      date,
      error,
      executions: executionsRaw,
      handleChangeDate,
      handleChangeMedium,
      handleChangeTrigger,
      handleSearch,
      loading,
      medium,
      search,
      trigger,
      appConfig,
    } = this.props;

    const executions = searchCampaigns(executionsRaw, search);

    const formattedExecutions = extendExecutions(executions);

    const legend = appConfig.hideNonManualPriorityType ? '* = Manual overrides and priority ' : '* = Manual Priority';
    const priorityHeader = appConfig.hideNonManualPriorityType ? 'Manual Priority' : 'Priority';

    return (
      <Page>
        <PageHeader title="Executions" />

        <PageMain>
          <Filters
            date={date}
            handleChangeDate={handleChangeDate}
            handleChangeMedium={handleChangeMedium}
            handleChangeTrigger={handleChangeTrigger}
            handleSearch={handleSearch}
            medium={medium}
            search={search}
            trigger={trigger}
            downloadCSVHeaders={CSV_HEADERS}
            downloadCSVData={formattedExecutions}
            downloadCSVFilename={'executions.csv'}
          />

          {loading ? (
            <Spinner />
          ) : (
            <>
              {error && (
                <Section>
                  <DrawerError error={parseErrorMessage(error)}>{parseErrorDebugMessage(error)}</DrawerError>
                </Section>
              )}

              <section>
                <Legend>{legend}</Legend>

                <ResponsiveTableContainer>
                  <Table>
                    <TableHeader>
                      <TableHeaderCell />
                      <TableHeaderCell>{priorityHeader}</TableHeaderCell>
                      <TableHeaderCell>Campaign</TableHeaderCell>
                      <TableHeaderCell>Type</TableHeaderCell>
                      <TableHeaderCell>Schedule Type</TableHeaderCell>
                      <TableHeaderCell centered>Push Type</TableHeaderCell>
                      <TableHeaderCell centered>Site</TableHeaderCell>
                      <TableHeaderCell>Dedupe</TableHeaderCell>
                      {/* <TableHeaderCell>Status</TableHeaderCell> */}
                      <TableHeaderCell>Audience</TableHeaderCell>
                      <TableHeaderCell>Acquired</TableHeaderCell>
                      <TableHeaderCell>Attempted</TableHeaderCell>
                    </TableHeader>

                    <TableBody>
                      {formattedExecutions.map(exec => (
                        <TableRow key={exec.id} data-qa="execution-table-row">
                          <Status>{this.stateIcons[toFinalState(exec.taskState, exec.deliveryState)]}</Status>

                          {this.renderPriorityColumn(exec)}

                          <Name>
                            <Highlight text={exec.name} highlight={search} />
                            <Id>
                              ID:
                              <Highlight text={String(exec.id)} highlight={search} />
                            </Id>
                          </Name>

                          <PlanType>{exec.planType}</PlanType>
                          <ScheduleType>{exec.isRecurring}</ScheduleType>
                          <PushType>{exec.pushType}</PushType>
                          <PushSite>{exec.pushSite}</PushSite>

                          <Dedupe>{exec.dedupeStrategy && <DedupePill dedupe={exec.dedupeStrategy}>{exec.dedupeLabel}</DedupePill>}</Dedupe>

                          {/* <Status>{toHumanizedStatus(exec.taskState, exec.deliveryState)}</Status> */}

                          <Audience>
                            <Metric>{exec.countPerExecutionNumber}</Metric>
                          </Audience>

                          <Acquired>
                            <Metric>{exec.acquiredNumber}</Metric>
                            <Percent>{exec.acquiredPercent}</Percent>
                          </Acquired>

                          <Attempted>
                            <Metric>{exec.deliveredNumber}</Metric>
                            <Percent>{exec.deliveredPercent}</Percent>
                          </Attempted>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </ResponsiveTableContainer>
              </section>
            </>
          )}
        </PageMain>
      </Page>
    );
  }
}

export default compose(
  withAppConfig,
  connect(
    ({
      masterplan: {
        executions: { error, executions, loading },
      },
    }) => ({ error, executions, loading }),
    masterplanExecutionsOperations,
  ),
)(Executions);

const Table = styled(_Table)`
  font-size: ${sc.fontSizeSmall};
  width: 100%;
`;

const TableCell = styled(_TableCell)`
  vertical-align: middle;
  width: 1%;
  white-space: nowrap;
`;

const TableHeaderCell = styled(_TableHeaderCell)`
  text-align: ${props => (props.centered ? 'center' : 'left')};
`;

const TableRow = styled(_TableRow)``;

const MetricsCol = styled(TableCell)`
  text-align: right;
`;

const Acquired = MetricsCol;

const Attempted = MetricsCol;

const Audience = MetricsCol;

const Dedupe = TableCell;

const DedupePill = styled(_Pill)`
  background-color: ${props => {
    if (props.dedupe === dedupeStrategies.none) {
      return sc.greyLight;
    }
    if (props.dedupe === dedupeStrategies.hardLock) {
      return sc.secondary;
    }
    if (props.dedupe === dedupeStrategies.softLock) {
      return sc.tertiary;
    }
  }};
  font-size: ${sc.fontSizeSmallest};
  text-transform: uppercase;
  width: 3rem;
`;

const IconFailed = styled(Icon).attrs(() => ({ name: 'cancel' }))`
  color: ${sc.danger};
`;

const IconProcessing = styled(Icon).attrs(() => ({ name: 'ellipsis' }))`
  color: ${sc.primary};
`;

const IconSuccess = styled(Icon).attrs(() => ({ name: 'checkmark' }))`
  color: ${sc.success};
`;

const IconUnknown = styled(Icon).attrs(() => ({ name: 'help' }))`
  color: ${sc.warning};
`;

const IconWaiting = styled(Icon).attrs(() => ({ name: 'time' }))`
  color: ${sc.warning};
`;

const Id = styled.div`
  color: ${sc.grey};
  font-size: ${sc.fontSizeSmallest};
  font-weight: 400;
`;

const Legend = styled(Id)`
  margin: ${sc.gutterSmallest};
`;

const Metric = styled.div``;

const Name = styled(TableCell)`
  width: auto;
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Percent = Id;

const PlanType = TableCell;

const ScheduleType = TableCell;

const Priority = styled(TableCell)`
  text-align: center;
`;

const PushSite = styled(TableCell)`
  width: auto;
  text-align: center;
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const PushType = styled(TableCell)`
  width: auto;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: ${sc.gutterLargest};
`;

const Status = TableCell;
