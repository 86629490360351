import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Action, ICampaign } from 'app/types';
import { IAppConfig } from 'configs/apps/types';
import { DrawerError } from 'app/components';

import CampaignDetails from './CampaignDetails';
import ChannelDetails from './ChannelDetails';
import Header from './Header';
import MetricsDetails from './MetricsDetails';
import { CampaignEdit } from '../CampaignEdit/CampaignEdit';

interface Params {
  type: string;
}

type Props = {
  appConfig: IAppConfig;
  campaigns: Array<ICampaign>;
  createCampaign: (...args: Array<any>) => any;
  general: Record<string, any>;
  match: RouteComponentProps<Params>['match'];
  setCampaignField: (arg0: Record<string, any>) => Action;
  valid: boolean;
  validationFailures: Array<any>;
  user: Record<string, any>;
};

type State = {
  modal?: 'bannerId' | 'campaignExpiry';
};

export default class Overview extends React.PureComponent<Props, State> {
  state = {};

  openModal = ({ modal }) => this.setState({ modal });

  closeModal = () => this.setState({ modal: undefined });

  render() {
    const { modal } = this.state;

    const { general: campaign, match, setCampaignField, user, valid, validationFailures } = this.props;

    const inEditMode = ['new', 'edit'].includes(match.params.type);

    return (
      <section>
        <Header
          title={campaign.name}
          {...this.props}
          {...campaign}
          inEditMode={inEditMode}
          validated={valid}
          validationFailures={validationFailures}
          openModal={this.openModal}
        />

        {campaign.saveError && campaign.saveError.details && (
          <DrawerError error={campaign.saveError.message} errorDetails={campaign.saveError.details} defaultExpanded />
        )}

        <CampaignDetails {...this.props} {...campaign} />

        <MetricsDetails {...this.props} />

        <ChannelDetails {...this.props} {...campaign} />

        {modal && (
          <CampaignEdit campaign={campaign} closeModal={this.closeModal} modal={modal} setCampaignField={setCampaignField} user={user} />
        )}
      </section>
    );
  }
}
