import * as React from 'react';
import { css } from 'styled-components';
import { map, equals } from 'ramda';
import { isNeitherNilNorEmpty } from 'app/helpers/RamdaHelpers/helpers';

import { EditActions } from './EditActions';
import { ColumnSwitch } from './ColumnSwitch';
import { TableRow } from '../Table/TableRow';

const renderColumn = props => column => <ColumnSwitch {...props} key={column.key} column={column} />;

interface InternalRowProps {
  columns?: unknown[];
  rowEditable?: boolean; //eslint-disable-line;
  handleRowClick?(...args: unknown[]): unknown;
  row: Record<string, any>;
}

const InternalRow = ({ handleRowClick, columns = [], rowEditable = true, rowHoverOutline, ...props }: InternalRowProps) => {
  const tableRowProps = isNeitherNilNorEmpty(handleRowClick)
    ? {
        onClick: event => handleRowClick(props.row, event),
        style: { cursor: 'pointer' },
        $hover: true,
        rowHoverOutline,
      }
    : {};

  if (props.row.disabled) {
    tableRowProps.style = { ...tableRowProps.style, pointerEvents: 'none', cursor: 'not-allowed', opacity: 0.4 };
  }

  return (
    <TableRow
      data-qa="editable-table-row"
      {...tableRowProps}
      $hoverStyles={css`
        background-color: red;
      `}
    >
      {map(renderColumn({ ...props, handleRowClick }))(columns)}
      {rowEditable && <EditActions {...props} />}
    </TableRow>
  );
};

interface RowProps {
  row: object;
  onChange?(...args: unknown[]): unknown; //eslint-disable-line;
  onSave?(...args: unknown[]): unknown;
  editState?: boolean;
  onRowMount?(...args: unknown[]): unknown;
}

export class Row extends React.Component<RowProps> {
  constructor(props) {
    super(props);
    this.state = {
      clonedRow: { ...props.row },
      editState: props.editState ?? false,
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) =>
    !equals(nextProps.row, prevState.clonedRow)
      ? { editState: nextProps.editState, clonedRow: { ...nextProps.row } }
      : { ...prevState, editState: nextProps.editState };

  componentDidMount() {
    const { onRowMount, row } = this.props;
    if (onRowMount) onRowMount(row);
  }

  handleChange = ev => {
    const { clonedRow } = this.state;
    const { onChange } = this.props;

    const newRow = {
      ...clonedRow,
      ...ev,
    };

    return onChange
      ? onChange(newRow)
      : this.setState(prevState => ({
          ...prevState,
          clonedRow: newRow,
        }));
  };

  handleSave = () => {
    const { clonedRow } = this.state;
    const { onSave } = this.props;
    this.setState(state => ({ ...state, editState: false }));
    onSave(clonedRow);
  };

  handleEdit = () => {
    this.setState(state => ({ ...state, editState: true }));
  };

  handleCancel = () => {
    const { row } = this.props;
    this.setState(() => ({ editState: false, clonedRow: row }));
  };

  render() {
    return (
      <InternalRow
        {...this.props}
        {...this.state}
        onChange={this.handleChange}
        onSave={this.handleSave}
        onEdit={this.handleEdit}
        onCancel={this.handleCancel}
      />
    );
  }
}
