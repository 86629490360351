import React from 'react';
import { Toolbar, Typography, TextField } from '@material-ui/core';

import { Search } from '@material-ui/icons';

import { isNeitherNilNorEmpty } from 'app/helpers/RamdaHelpers/helpers';

interface TableTitleWithSearchBarProps {
  title: string;
  isFiltered: boolean;
  handleFilter?(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

export const TableTitleWithSearchBar = ({ title, isFiltered, handleFilter, classes }: TableTitleWithSearchBarProps) => (
  <Toolbar className={classes.root}>
    <div
      className={classes.title}
      style={{
        alignItems: 'flex-end',
        display: 'flex',
      }}
    >
      <Typography variant="h6">{title}</Typography>
      {isFiltered ? (
        <Typography
          variant="caption"
          style={{
            marginLeft: '0.2rem',
          }}
        >
          <i>(filtered)</i>
        </Typography>
      ) : undefined}
    </div>
    {isNeitherNilNorEmpty(handleFilter) ? (
      <>
        <div className={classes.spacer} />
        <div
          className={classes.actions}
          style={{
            alignItems: 'flex-end',
            display: 'flex',
          }}
        >
          <Search
            style={{
              marginRight: '0.5rem',
            }}
          />

          <TextField id="search" label="Search" type="search" margin="dense" onChange={handleFilter} className={classes.textField} />
        </div>
      </>
    ) : undefined}
  </Toolbar>
);
