import { ISearchDef } from 'app/hocs/withReporting';
import { IAppConfig } from 'configs/apps/types';
import { getChannelNames } from 'app/ducks/configStore/utils';
import { channelNameBanner } from 'configs/channels/constants';
import { getChannelsConfig } from 'configs/channels';
import { IChannelConfig } from 'configs/channels/types';

export const searchParams = {
  bannerId: 'banner_id',
  channel: 'medium',
  createdBy: 'createdby',
  expiry: 'expiry_date',
  firstOccurrence: 'occurrence',
  hasPromoCode: 'valid_promocode',
  id: 'id',
  name: 'name',
  promocode: 'promocode',
  recurring: 'recurring',
  triggerType: 'trigger_type',
  segment: 'segment_id', // TODO: Use name from CMA
  state: 'state',
  tag: 'tag',
  viewId: 'banner_viewId',
  category: 'category2',
};

// `label` is only needed for those in the Search Bar
const searchByBannerId = { name: searchParams.bannerId, label: 'By Banner ID', type: 'number' }; // TODO: Only for BANNER
const searchByCreatedBy = { name: searchParams.createdBy };
const searchByExpiry = { name: searchParams.expiry };
const searchByFirstOccurrence = { name: searchParams.firstOccurrence };
const searchById = { name: searchParams.id, label: 'By Campaign ID', type: 'number' };
const searchByName = { name: searchParams.name, label: 'By Name' };
const searchByRecurring = { name: searchParams.recurring };
const searchByTriggerType = { name: searchParams.triggerType };
const searchBySegment = { name: searchParams.segment };
const searchByTag = { name: searchParams.tag, label: 'By Tag' };
const searchByViewId = { name: searchParams.viewId, label: 'By View ID', type: 'number' }; // TODO: Only for BANNER
const searchByCategory = { name: searchParams.category };

export const searchByState: ISearchDef = {
  name: searchParams.state,
  options: [
    { id: '', label: 'All States' },
    { id: 'Draft', label: 'Draft' },
    { id: 'Created', label: 'Created' },
    { id: 'Started', label: 'Started' },
    { id: 'Paused', label: 'Paused' },
    { id: 'Finished', label: 'Finished' },
    { id: 'Error', label: 'Error' },
    { id: 'Stopped', label: 'Stopped' },
  ],
};

// TODO: We need definers to allows conditional rendering of search. Better is to inject searchDefs in withSearch, and check for a search parameter.
export const searchByChannelDefiner = ({ appConfig }: { appConfig?: IAppConfig }): ISearchDef | null | undefined => {
  const channelNames = appConfig ? getChannelNames(appConfig) : [];

  if (channelNames.length === 1) {
    // If there is only 1 channel available, then do not search
    return undefined;
  }

  const channelConfigs: Array<IChannelConfig> = Object.values(getChannelsConfig(appConfig));
  const labelByName = channelConfigs.reduce((acc, config) => ({ ...acc, [config.name]: config.displayName || config.name }), {});

  const options = channelNames.reduce(
    (acc, name) => [...acc, { id: name, label: labelByName[name]?.replace('_', ' ') }],
    [{ id: '', label: 'All Channels' }],
  );

  return {
    name: searchParams.channel,
    options,
  };
};

export const searchBarDefiner = ({ appConfig }: { appConfig?: IAppConfig }) => {
  const channelNames = appConfig ? getChannelNames(appConfig) : [];

  const defs = [searchByName, searchByTag, searchById];
  channelNames.includes(channelNameBanner) && defs.push(searchByBannerId, searchByViewId);
  return defs;
};

export const searchDefiner = ({ appConfig }: { appConfig?: IAppConfig }) => {
  let searchDefs = [
    ...searchBarDefiner({ appConfig }),
    searchByCreatedBy,
    searchByExpiry,
    searchByFirstOccurrence,
    searchByRecurring,
    searchByTriggerType,
    searchBySegment,
    searchByState,
    searchByCategory,
  ];

  const searchByChannel = searchByChannelDefiner({ appConfig });
  if (searchByChannel) {
    searchDefs = [...searchDefs, searchByChannel];
  }

  return searchDefs;
};

export const defaultSearch = ({ userEmail }: { userEmail: string }) => ({
  [searchParams.createdBy]: userEmail,
});
