import { useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';

import { fetchVariantTypes } from 'app/api/bannerManagementV2/viewItems';
import { mapStateToVariantTypes } from 'app/ducks/bannerManagement/variantTypes/selectors';
import { IVariantType } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

import { State } from './reducer';
import reducer, { Actions, initialState } from './reducer';
type Props = {
  appConfig: IAppConfig;
  setVariantType: (arg0: string | null | undefined) => void;
  variantType?: IVariantType;
  widgetType: string;
};

export default ({ appConfig, setVariantType, variantType, widgetType }: Props): State => {
  const creativeConfig = getBannerCreativeConfig(appConfig);
  if (!creativeConfig.useVariantType) {
    return initialState;
  }

  const { variantTypes } = useSelector(mapStateToVariantTypes);
  const [state, dispatch] = useReducer(reducer, initialState);
  const prevState = useRef(undefined);

  useEffect(() => {
    const fetchWidgetSubtypes = async (widgetType: string) => {
      dispatch(Actions.fetchStart());

      try {
        const widgetSubtypes: Array<IVariantType> = await fetchVariantTypes(widgetType);
        dispatch(Actions.fetchSuccess(widgetSubtypes));

        // TODO: This code is kept for when a better solution is found for handling a changed widgetType.
        // Because of the time taken to fetch the new variant types, the old variant type is retained in the UI.
        // This makes for bad UX, so the Fields component clears the variantType immediately.
        // It would be better to hide the variantType until the fetch is complete,
        // then either retain it or clear it, based on whether it's a valid value.
        // See the TODO comment in the Fields component.
        const hasWidgetSubtypes = widgetSubtypes?.length > 0;
        const matchesWidgetSubtype = !!widgetSubtypes?.find(w => w.id === variantType?.id);
        const matchesVariantType = !!variantTypes?.find(v => v.id === variantType?.id);

        if ((hasWidgetSubtypes && !matchesWidgetSubtype) || (!hasWidgetSubtypes && !matchesVariantType)) {
          setVariantType(null);
        }
      } catch (err) {
        const msg = `Error while fetching variantTypes for ${widgetType}`;
        const errorDetail = err?.error?.msg || err?.message;
        console.error(msg, err);

        dispatch(Actions.fetchError(`${msg}${errorDetail ? `[${errorDetail}]` : ''}`));
        setVariantType(null);
      }
    };

    const prevWidgetType = prevState.current?.widgetType;
    prevState.current = { widgetType };

    if (widgetType && widgetType !== prevWidgetType) {
      fetchWidgetSubtypes(widgetType);
    }
  }, [setVariantType, variantType, variantTypes, widgetType]);

  return state;
};
