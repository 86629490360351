import React from 'react';

import { IconButton } from '@material-ui/core';

import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';

interface TablePaginationActionsProps {
  page: number;
  lastPageIndex: number;
  handleFirstPageButtonClick(...args: unknown[]): unknown;
  handleBackButtonClick(...args: unknown[]): unknown;
  handleNextButtonClick(...args: unknown[]): unknown;
  handleLastPageButtonClick(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

export const TablePaginationActions = ({
  page: pageIndex,
  lastPageIndex,
  handleFirstPageButtonClick,
  handleBackButtonClick,
  handleNextButtonClick,
  handleLastPageButtonClick,
  classes,
}: TablePaginationActionsProps) => {
  const isOnFirstPage = pageIndex === 0;
  const isOnLastPage = pageIndex >= lastPageIndex;

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={isOnFirstPage} aria-label="Go to First Page">
        <FirstPage />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={isOnFirstPage} aria-label="Go to Previous Page">
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton onClick={handleNextButtonClick} disabled={isOnLastPage} aria-label="Go to Next Page">
        <KeyboardArrowRight />
      </IconButton>

      <IconButton onClick={handleLastPageButtonClick} disabled={isOnLastPage} aria-label="Go to Last Page">
        <LastPage />
      </IconButton>
    </div>
  );
};
