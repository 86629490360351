import React from 'react';
import { Input, Menu, MenuItem, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';

import { Search as SearchIcon, DoneAll as SelectionIcon, NotInterested as NotFoundIcon, Close as CloseIcon } from '@material-ui/icons';

import { isPlainObject } from 'ramda-adjunct';

import MultiValueTextField from 'app/components/MultiValueTextField';

import { R } from 'app/helpers/RamdaHelpers/helpers';
import { useEffect } from 'react';

const toMenuItem = R.curry(({ selectedItemStyle, nonSelectedItemStyle }, selections, handleSelect, option) => {
  const [optionValue, optionLabel] = isPlainObject(option) ? [option.value, option.label] : [option];
  const isSelected = selections.includes(optionValue);
  const text = optionLabel || optionValue;

  return (
    <MenuItem
      key={optionValue}
      value={optionValue}
      selected={isSelected}
      className={isSelected ? selectedItemStyle : nonSelectedItemStyle}
      onClick={event => handleSelect(event, optionValue)}
    >
      {isSelected && (
        <ListItemIcon>
          <SelectionIcon />
        </ListItemIcon>
      )}

      <ListItemText inset disableTypography primary={text} />
    </MenuItem>
  );
});

interface FilterMultiSelectProps {
  id: string;
  options:
    | string
    | {
        value?: string;
        label?: string;
      }[];
  label: string;
  selections?: string[];
  stringifier(...args: unknown[]): unknown;
  handleSelect(...args: unknown[]): unknown;
  handleOpen(...args: unknown[]): unknown;
  handleClose(...args: unknown[]): unknown;
  handleFilter(...args: unknown[]): unknown;
  isDropdownOpen: boolean;
  transformOrigin: Record<string, string>;
  classes: Record<string, string>;
}

export const FilterMultiSelect = ({
  id,
  label,
  options,
  selections = [],
  stringifier,
  handleSelect,
  handleOpen,
  handleClose,
  handleFilter,
  isDropdownOpen,
  transformOrigin,
  classes,
}: FilterMultiSelectProps) => {
  const anchorRef = React.useRef(null);
  const filterRef = React.useRef(null);

  useEffect(() => {
    if (filterRef) filterRef.current?.focus();
  }, []);

  return (
    <>
      <MultiValueTextField
        {...{
          id,
          label,
          stringifier,
          handleOpen,
          anchorRef,
          classes,
          values: selections,
          tooltipId: `chip-input-tooltip-${id}`,
        }}
      />

      <Menu
        key="dropdown"
        open={isDropdownOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorReference="anchorEl"
        transformOrigin={transformOrigin}
      >
        <div
          // className={classes.actions}
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            // marginRight: '0.8rem',
          }}
        >
          <SearchIcon
            style={{
              marginTop: '1rem',
              marginLeft: '0.8rem',
              marginRight: '0.3rem',
            }}
          />

          <Input
            margin="dense"
            type="search"
            placeholder="Search..."
            className={classes.textField}
            style={{
              minWidth: 200,
            }}
            onChange={handleFilter}
            ref={filterRef}
          />

          <IconButton
            aria-label="Close"
            // color="primary"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>

        {options.length < 1 && (
          <ListItem disabled>
            <ListItemIcon>
              <NotFoundIcon />
            </ListItemIcon>
            <ListItemText primary="No item found." secondary="Clear search to see all." style={{ padding: 0 }} />
          </ListItem>
        )}

        {options.length > 0 && R.map(toMenuItem(classes, selections, handleSelect), options)}
      </Menu>
    </>
  );
};
