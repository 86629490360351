import * as React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';

import { ImageUpload, Input as _Input } from 'app/midgarComponents';
import { ApiImageUpload } from 'app/helpers/ImageUploadManager/ApiImageUpload';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { useSelector, useDispatch } from 'react-redux';
import * as campaignApi from 'app/api/campaigns';
import { updateTimelineNotificationContent, updateCategoryList } from 'app/ducks/campaigns/campaign/TimelineNotificationCreative/actions';
import { notificationLinkValidator } from 'app/helpers/TimelineNotificationHelper/';

import { sc } from 'app/styles';

type Props = {
  apiFileUpload: (...args: Array<any>) => any;
};

const DefaultSender = ({ apiFileUpload }: Props) => {
  const {
    TimelineNotificationCreative: {
      categoryList,
      payload: {
        sender: { name, link, icon },
      },
    },
  } = useSelector(
    ({
      campaigns: {
        campaign: { TimelineNotificationCreative },
      },
    }) => ({ TimelineNotificationCreative }),
  );

  const dispatch = useDispatch();

  const onUpload = async (fileUri: string, file: File) => {
    if (file) {
      const { status, url } = await apiFileUpload(file);

      if (status === 'SUCCESSFUL') {
        displaySuccess('Image upload Successful');
        dispatch(updateTimelineNotificationContent({ icon: url }));
      } else {
        displayError(`Image upload failed with the API status ${status}`);
      }
    } else {
      displayError('The file was rejected. It may have the wrong extension.');
    }
  };

  React.useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        if (categoryList && !Object.keys(categoryList).length) {
          const categoryListResponse = await campaignApi.getCategoryList();
          const tempCategoryList = {};

          categoryListResponse.map(
            item =>
              (tempCategoryList[item.threadId] = {
                ...item,
                name: item.displayName,
              }),
          );

          dispatch(updateCategoryList({ categoryList: tempCategoryList }));
        }

        if (name === '' && link === '' && icon === '' && !Object.keys(categoryList).length) {
          const campaignTypes = await campaignApi.getCategories();
          dispatch(updateTimelineNotificationContent({ name: campaignTypes.name, link: campaignTypes.link, icon: campaignTypes.icon }));
        }
      } catch (error) {
        displayError('Issues with service, Please report to the dev team!');
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateContentValue = (ev: Record<string, any>) =>
    dispatch(updateTimelineNotificationContent({ [ev.target.name]: ev.target.value }));
  const updateDeeplinkValue = ev => dispatch(updateTimelineNotificationContent({ [ev.target.name]: ev.target.value.trim() }));
  const handleBlur = ev => dispatch(updateTimelineNotificationContent({ [ev.target.name]: ev.target.value.trim() }));

  return (
    <Flex>
      <ImageUpload
        id="upload-big-picture"
        placeholder="Default Sender Icon less than 5MB"
        imageUrl={icon}
        onUpload={(uri, file) => onUpload(uri, file)}
        onRemove={() => {
          dispatch(updateTimelineNotificationContent({ icon: '' }));
        }}
      />

      <div style={{ width: '75%' }}>
        {notificationLinkValidator(icon, 'Sender') && (
          <Message>
            <Pill>URL must start with https:// or http://</Pill>
          </Message>
        )}

        <Input
          value={icon}
          name="icon"
          id="Icon"
          placeholder="Sender Icon - less than 255 characters"
          onChange={updateDeeplinkValue}
          maxLength="255"
        />

        <Input
          value={name}
          name="name"
          id="Name"
          placeholder="Sender Name - less than 10 characters"
          onChange={updateContentValue}
          maxLength="10"
          onBlur={handleBlur}
        />

        {notificationLinkValidator(link, 'Sender') && (
          <Message>
            <Pill>URL must start with https:// or http://</Pill>
          </Message>
        )}

        <Input
          value={link}
          name="link"
          id="Link"
          placeholder="Sender Link - less than 255 characters"
          onChange={updateDeeplinkValue}
          maxLength="255"
        />
      </div>
    </Flex>
  );
};

export default compose(ApiImageUpload)(DefaultSender);

const Flex = styled.div`
  display: flex;
  justify-content: normal;
`;
const Input = styled(_Input)`
  height: auto;
  border-color: ${props => (props.invalid ? sc.danger : sc.greyLighter)};
`;

const Pill = styled.span`
  background-color: #eeeeee;
  border: solid 1px #eeeeee;
  color: ${sc.greyDarkest};
  border-radius: 4px;
  display: inline-block;
  justify-self: end;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: justify;
  width: inherit;
`;

const Message = styled.div`
  position: absolute;
  border-color: ${props => (props.invalid ? sc.danger : sc.greyLighter)};
  margin: -0.7rem 0;
  display: flex;
  justify-content: 1rem;
  justify-items: flex-start;
`;
