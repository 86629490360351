import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { lensPath, set } from 'ramda';

import { inject } from 'app/decorators';
import { ImageUpload, Icon, Button, TextField as _TextField, Dropdown as _Dropdown } from 'app/midgarComponents';
import { InteractiveTypes } from 'app/features/Push2/Creation/Push2Constants';
import * as Push2CreativeActions from 'app/ducks/campaigns/campaign/push2Creative/actions';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { ApiImageUpload } from 'app/helpers/ImageUploadManager/ApiImageUpload';
import uid from 'app/utilities/uid';

type State = {
  keys: Array<string>;
};

class ImageCarousel extends React.Component<
  {
    carousel: Array<any>;
    Push2CreativeActions: typeof Push2CreativeActions;
    apiFileUpload: (...args: Array<any>) => any;
  },
  State
> {
  constructor(props) {
    super(props);
    const { carousel } = props;
    this.state = {
      keys: carousel.map(uid),
    };
  }

  onUpload = async (fileUri: string, file: File, index) => {
    if (file) {
      const {
        apiFileUpload,
        carousel,
        Push2CreativeActions: { setCarouselImage },
      } = this.props;
      const targetImage = carousel[index];

      const { status, url } = await apiFileUpload(file);
      if (status === 'SUCCESSFUL') {
        targetImage.url = url;
        setCarouselImage(targetImage, index);
      } else {
        displayError(`Image upload failed with the API status ${status}`);
      }
    } else {
      displayError('The file was rejected. It may have the wrong extension.');
    }
  };

  addImage = () => {
    const {
      Push2CreativeActions: { addCarouselImage },
    } = this.props;
    const { keys } = this.state;
    keys.push(uid());
    this.setState({
      keys,
    });

    addCarouselImage();
  };

  removeImage = index => {
    const {
      Push2CreativeActions: { removeCarouselImage },
    } = this.props;
    const { keys } = this.state;
    keys.splice(index, 1);
    this.setState({
      keys,
    });

    removeCarouselImage(index);
  };

  unsetImageUrl = index => {
    const {
      carousel,
      Push2CreativeActions: { setCarouselImage },
    } = this.props;
    const image = carousel[index];
    image.url = '';
    setCarouselImage(image, index);
  };

  handleChange = (value, field, index) => {
    const {
      carousel,
      Push2CreativeActions: { setCarouselImage },
    } = this.props;
    let image = carousel[index];
    const imageLens = lensPath(field.split('.'));
    image = set(imageLens, value, image);
    setCarouselImage(image, index);
  };

  render() {
    const { carousel } = this.props;
    const { keys } = this.state;

    return (
      <section>
        <Button onClick={this.addImage}>
          <Icon name="add" /> Add
        </Button>
        <CarouselRow>
          {carousel.map((image, index) => (
            <CarouselImageGroup key={keys[index]}>
              <Icon name="remove" onClick={() => this.removeImage(index)} />
              <ImageUpload
                placeholder="Upload Carousel Image"
                imageUrl={image.url}
                onUpload={(fileUri, file) => this.onUpload(fileUri, file, index)}
                onRemove={() => this.unsetImageUrl(index)}
              />

              <TextField
                id={`url-${index}`}
                name={`url-${index}`}
                label="URL"
                value={image.url}
                margin="dense"
                onChange={e => this.handleChange(e.target.value, 'url', index)}
              />

              <TextField
                id={`description-${index}`}
                name={`description-${index}`}
                label="Description"
                value={image.description}
                margin="dense"
                onChange={e => this.handleChange(e.target.value, 'description', index)}
              />

              <Dropdown
                required
                value={image.action.type}
                options={InteractiveTypes}
                label="Action"
                onChange={value => this.handleChange(value, 'action.type', index)}
              />

              <TextField
                id={`value-${index}`}
                name={`value-${index}`}
                label="URI for Action"
                value={image.action.value}
                margin="dense"
                onChange={e => this.handleChange(e.target.value, 'action.value', index)}
              />
            </CarouselImageGroup>
          ))}
        </CarouselRow>
      </section>
    );
  }
}

export default compose(
  inject({ Push2CreativeActions }),
  connect(
    ({
      campaigns: {
        campaign: {
          push2Creative: {
            payload: {
              notification: {
                content: { carousel },
              },
            },
          },
        },
      },
    }) => ({
      carousel,
    }),
  ),

  ApiImageUpload,
)(ImageCarousel);

const CarouselRow = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
`;

const CarouselImageGroup = styled.div`
  width: 220px;
  .ic-icon {
    cursor: pointer;
    float: right;
  }
`;

const TextField = styled(_TextField)`
  width: 200px;
`;

const Dropdown = styled(_Dropdown)`
  width: 200px;
`;
