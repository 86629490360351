import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { DialogShell } from 'app/components';
import { triggerTypes } from 'app/utilities/constants';
import { bannerMediumId, cashbackMediumId } from 'configs/channels/mediumIds';
import { TimeRange } from './sections/TimeRange';
// eslint-disable-next-line import/no-cycle
import { TriggerSelection } from './TriggerSelection';
import Location from '../Location';
import OrderAbandon from './sections/OrderAbandon';
import { StartEndDateTime } from './sections/StartEndDateTime';
import { ValidDays } from './sections/ValidDays';

const partialScheduleMediums = [bannerMediumId, cashbackMediumId];

const stateToProps = ({
  campaigns: {
    campaign: {
      general: { useEveryoneAudience },
    },
  },
}) => ({ useEveryoneAudience });

interface FullScheduleProps {
  min: string;
  classes: {
    inputContainer?: string;
  };
  triggerType: string;
  updateRecurring(...args: unknown[]): unknown;
  setTimeRange(...args: unknown[]): unknown;
  timeRange?: {
    from: string;
    to: string;
  };
  mediumId: number;
}

class FullSchedule extends React.PureComponent<FullScheduleProps> {
  state = {
    triggerPayload: null,
    toggleModalState: triggerPayload => this.setState({ triggerPayload }),
  };

  handleClose(okay) {
    if (okay) {
      const { setCampaignField } = this.props;
      const { triggerPayload } = this.state;
      setCampaignField({ useEveryoneAudience: false, ...triggerPayload });
    }

    this.setState({ triggerPayload: null });
  }

  render() {
    const {
      classes,
      updateRecurring,
      setTimeRange,
      triggerType,
      timeRange = {
        from: '00',
        to: '24',
      },

      mediumId,
      useEveryoneAudience,
    } = this.props;
    const { triggerPayload, toggleModalState } = this.state;
    const showPartialSchedule = partialScheduleMediums.includes(mediumId);

    return (
      <>
        <DialogShell dialogHeader="Confirm Scheduling Change" isOpen={!!triggerPayload} onClose={okay => this.handleClose(okay)}>
          <Typography>
            &quot;One-time&quot; and &quot;Recurring&quot; scheduling are not supported after choosing &quot;Send to Everyone&quot;. Do you
            want to uncheck &quot;Send to Everyone&quot;?
          </Typography>
        </DialogShell>
        <TriggerSelection
          triggerType={triggerType}
          mediumId={mediumId}
          toggleModalState={toggleModalState}
          updateRecurring={updateRecurring}
          useEveryoneAudience={useEveryoneAudience}
        />

        <Container>
          <StartEndDateTime />
          {!showPartialSchedule && <ValidDays />}
          <section>
            {!showPartialSchedule && triggerType === triggerTypes.location ? (
              <TimeRange timeRange={timeRange} classes={classes} setTimeRangeFn={setTimeRange} />
            ) : null}
          </section>
          {triggerType === triggerTypes.location && <Location {...this.props} />}
          {triggerType === triggerTypes.orderAbandon && <OrderAbandon />}
        </Container>
      </>
    );
  }
}

export default connect(stateToProps)(FullSchedule);

const Container = styled.section`
  margin: 1rem 0;
`;
