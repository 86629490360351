import * as React from 'react';
import { withProps } from 'recompose';
import { compose } from 'ramda';
import { Typography } from '@material-ui/core';
import { findErrors } from 'app/utilities/variable';

const setErrors = withProps(({ html, hashedValues, variables }) => ({
  errors: compose(findErrors)({ html: html || '', hashedValues, variables }),
}));

interface VariableErrorsProps {
  errors: {
    variableErrors?: string[];
    featureErrors?: string[];
    totalErrors?: number;
  };
}

const VariableErrors = ({ errors }: VariableErrorsProps) => (
  <section>
    {errors.featureErrors.length ? (
      <Typography style={{ color: 'red' }}>
        {errors.featureErrors.join(', ')} {errors.featureErrors.length > 1 ? 'are not valid features' : 'is not a valid feature'}
      </Typography>
    ) : null}
    {errors.variableErrors.length ? (
      <Typography style={{ color: 'red' }}>{errors.variableErrors.join(', ')} requires a default value</Typography>
    ) : null}
  </section>
);

export default setErrors(VariableErrors);
