import * as React from 'react';
import ReactDOM from 'react-dom';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { TextField, MenuItem } from '@material-ui/core';
import Paper from 'app/midgarComponents/Paper';

interface renderInputProps {
  classes: {
    textField?: string;
    input?: string;
  };
  value: string;
  ref: string;
}

const renderInput = ({ classes, value, ref, ...other }: renderInputProps) => (
  <TextField
    data-qa="autocomplete-input"
    value={value}
    className={classes.textField}
    inputRef={ref}
    InputProps={{
      classes: {
        input: classes.input,
      },

      ...other,
    }}
  />
);

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          ),
        )}
      </div>
    </MenuItem>
  );
};

// TODO: Pass in styles for scrolling the suggestions
const renderSuggestionsContainer = id => options => {
  const { containerProps, children } = options;
  const container = document.getElementById(id);
  const { bottom, left } = container ? container.getBoundingClientRect() : 0;
  return ReactDOM.createPortal(
    <Paper
      {...containerProps}
      style={{
        maxHeight: 200,
        overflow: 'auto',
        top: bottom,
        left,
        width: '20rem',
      }}
      $square
    >
      {children}
    </Paper>,
    document.getElementById('app'),
  );
};

interface AutocompleteProps {
  value?: string;
  suggestions?: string[];
  id: string;
  placeholder: string;
  handleChange(...args: unknown[]): unknown;
  handleSuggestionsFetchRequested(...args: unknown[]): unknown;
  handleSuggestionSelected(...args: unknown[]): unknown;
  classes: {
    container?: string;
    input?: string;
    suggestion?: string;
    suggestionsContainerOpen?: string;
    suggestionsList?: string;
    textField?: string;
  };
}

export const Autocomplete = ({
  value = '',
  suggestions = [],
  id,
  placeholder,
  handleChange,
  handleSuggestionsFetchRequested,
  handleSuggestionSelected,
  classes,
}: AutocompleteProps) => (
  <Autosuggest
    id={id}
    data-qa={id}
    theme={{
      container: classes.container,
      suggestionsContainerOpen: classes.suggestionsContainerOpen,
      suggestionsList: classes.suggestionsList,
      suggestion: classes.suggestion,
    }}
    renderInputComponent={renderInput}
    suggestions={suggestions}
    shouldRenderSuggestions={() => true}
    renderSuggestion={renderSuggestion}
    onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
    onSuggestionsClearRequested={() => undefined}
    getSuggestionValue={suggestion => suggestion}
    renderSuggestionsContainer={renderSuggestionsContainer(id)}
    onSuggestionSelected={handleSuggestionSelected}
    inputProps={{
      classes,
      placeholder,
      value,
      onChange: handleChange,
      id,
    }}
  />
);
