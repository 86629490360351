import { match, map, reduce, compose } from 'ramda';
import { handlebarsHelpers } from 'app/utilities/constants';

export const getTemplateStrings = match(/\{{[{]?(.*?)[}]?\}}/g);

export const mapTemplateStrings = templated =>
  map(s => {
    let variableString = s.match(/\{{[{]?(.*?)[}]?\}}/)[1];

    if (templated) {
      variableString = variableString
        .replace(new RegExp(`(#|\\/|@)?(${handlebarsHelpers.join('|')})`, 'g'), '')
        .trim()
        .split(' ')
        .map(variableWithPrefixes => variableWithPrefixes.split('.').pop())
        .filter(Boolean);
    } else {
      variableString = variableString.trim().split().filter(Boolean);
    }

    return { template: s, strings: variableString };
  });

export const verifyVariableIntegrity = (hashedValues, variableStrings) => {
  const integrityErrors = [];

  variableStrings.forEach(string => {
    if (string && isNaN(string) && !hashedValues[string]) {
      integrityErrors.push(`${string} does not exist`);
    }
  });

  return integrityErrors;
};

const separateErrors = ({ hashedValues }) =>
  reduce(
    (acc, template) => {
      const exemptionFilteredStrings = template.strings.filter(str => str !== 'unsubscribe');
      const compoundedFeatureErrors = verifyVariableIntegrity(hashedValues, exemptionFilteredStrings);

      if (compoundedFeatureErrors.length) {
        return {
          ...acc,
          featureErrors: [...acc.featureErrors, ...compoundedFeatureErrors],
          totalErrors: acc.totalErrors + compoundedFeatureErrors.length,
        };
      }

      return acc;
    },
    { featureErrors: [], variableErrors: [], totalErrors: 0 },
  );

export const findErrors = ({ html, hashedValues, templated = false }) =>
  compose(separateErrors({ hashedValues }), mapTemplateStrings(templated), getTemplateStrings)(html);
