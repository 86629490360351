import React, { ReactNode, CSSProperties } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import { isNeitherNilNorEmpty, R } from 'app/helpers/RamdaHelpers/helpers';
import { addComponentPropsIf, addComponentPropsIfNot } from 'app/helpers/ComponentHelpers/helpers';

interface DialogShellProps {
  isOpen: boolean;
  className?: string;
  dialogHeader?: string | ReactNode;
  dialogProps?: Record<string, string>;
  onEntering?: () => void;
  contentContainerStyle?: CSSProperties;
  handleOkay: () => void;
  okayButtonText?: string;
  handleCancel: () => void;
  cancelButtonText?: string;
  children?: ReactNode | ReactNode[] | string;
}

export const DialogShell = ({
  isOpen,
  className,
  dialogHeader,
  dialogProps,
  onEntering,
  contentContainerStyle,
  handleOkay,
  okayButtonText = 'OK',
  handleCancel,
  cancelButtonText = 'Cancel',
  children = [],
}: DialogShellProps) => {
  const hasHeader = isNeitherNilNorEmpty(dialogHeader);
  const titleId = 'DialogShellTitleId';

  return (
    <Dialog
      className={className}
      data-qa="dialog-shell"
      maxWidth="sm"
      {...addComponentPropsIfNot(R.isNil(onEntering), {
        onEntering,
      })}
      {...addComponentPropsIf(hasHeader, {
        'aria-labelledby': titleId,
      })}
      {...dialogProps}
      PaperProps={{
        style: { willChange: '' },
      }}
      open={isOpen}
      onClose={(event, reason) => {
        if (!['backdropClick', 'escapeKeyDown'].includes(reason)) {
          handleCancel();
        }
      }}
    >
      {hasHeader && (
        <DialogTitle
          id={titleId}
          style={{
            padding: '24px 24px 12px 24px',
          }}
        >
          {dialogHeader}
        </DialogTitle>
      )}

      <DialogContent {...(isNeitherNilNorEmpty(contentContainerStyle) ? { style: contentContainerStyle } : {})}>{children}</DialogContent>

      <DialogActions>
        <Button data-qa="dialog-shell-cancel-button" onClick={handleCancel} color="primary">
          {cancelButtonText}
        </Button>
        <Button data-qa="dialog-shell-ok-button" onClick={handleOkay} color="primary">
          {okayButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
