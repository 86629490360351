import React from 'react';

import { FormControl, Grid, Select, MenuItem } from '@material-ui/core';

import RuleTextEdit from 'app/components/RuleTextEdit';

import { R } from 'app/helpers/RamdaHelpers/helpers';

const toMenuItem = R.curry((classes, selected, { label, value }) => (
  <MenuItem key={value} value={value} className={selected === value ? classes.boldFont : classes.regularFont}>
    {label}
  </MenuItem>
));

const inputProps = id => ({
  name: id,
  id,
});

interface EditWithDropdownProps {
  id: string;
  label: string;
  dataType: string;
  inputValue?: string;
  validator(...args: unknown[]): unknown;
  handleChangeInput(...args: unknown[]): unknown;
  options: object[];
  optionValue?: string;
  handleChangeOption(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

export const EditWithDropdown = ({
  id,
  label,
  dataType,
  inputValue,
  validator,
  handleChangeInput,
  options,
  optionValue,
  handleChangeOption,
  classes,
}: EditWithDropdownProps) => (
  <FormControl className={classes.formControl}>
    <Grid container direction="row" alignItems="flex-end" justifyContent="flex-start">
      <RuleTextEdit
        id={`${id}-input`}
        label={label}
        dataType={dataType}
        value={inputValue}
        validator={validator}
        onChange={handleChangeInput}
        handleChange={handleChangeInput}
        classes={classes}
        style={{
          width: 60,
        }}
      />

      <Select value={optionValue} onChange={handleChangeOption} inputProps={inputProps(`${id}-option`)} autoWidth>
        {R.map(toMenuItem(classes, optionValue), options)}
      </Select>
    </Grid>
  </FormControl>
);
