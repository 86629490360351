import * as React from 'react';
import { TextField } from 'app/midgarComponents';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { MAX_AUDIENCE, MAX_AUDIENCE_FRIENDLY_STR } from 'app/features/Campaigns/campaignReach';
import { AppConfig } from '../../../../configs/apps/typescript/types';

import type { JSX } from 'react';

type Props = {
  maxAudienceRequired: boolean;
  maxAudienceLimit: string | null;
  appConfig: AppConfig;
  error?: string | object | boolean;
  value: number;
  setFieldValue: Function;
};

const MaxAudience: React.FC<Props> = ({ appConfig, maxAudienceLimit, maxAudienceRequired, error, value, setFieldValue }): JSX.Element => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (appConfig.enableMaxAudienceLimit) {
      if (!maxAudienceRequired) {
        // clear max aud if it is not required any more
        setFieldValue('customerCountPerExecution', '');
      }
    }
  }, [appConfig.enableMaxAudienceLimit, dispatch, maxAudienceRequired, setFieldValue]);

  const label = !appConfig.enableMaxAudienceLimit
    ? `Max Audience (${MAX_AUDIENCE_FRIENDLY_STR})`
    : appConfig.enableMaxAudienceLimit && maxAudienceRequired
    ? `Max Audience ${maxAudienceLimit ? `(${Number(maxAudienceLimit) / 1000000}M)` : ''}`
    : '';

  return (
    <>
      {!appConfig.enableMaxAudienceLimit ||
        (appConfig.enableMaxAudienceLimit && maxAudienceRequired && (
          <MaxAudienceField
            type="number"
            id="customerCountPerExecution"
            name="customerCountPerExecution"
            label={label}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
              if (appConfig.enableMaxAudienceLimit) {
                if (maxAudienceLimit && typeof maxAudienceLimit === 'number' && value <= maxAudienceLimit) {
                  setFieldValue('customerCountPerExecution', ev?.target?.value);
                }
              } else if (value <= MAX_AUDIENCE) {
                setFieldValue('customerCountPerExecution', ev?.target?.value);
              }
            }}
            value={value}
            required={maxAudienceRequired}
            error={error}
            data-qa="max-audience-field"
          />
        ))}
    </>
  );
};

export default MaxAudience;

const MaxAudienceField = styled(TextField)`
  width: 28.5rem;
`;
