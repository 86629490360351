import { findChannelSetup } from 'app/ducks/configStore/utils';
import { IAppConfig } from 'configs/apps/types';
import { channelNameEmail } from 'configs/channels/constants';
import { IEmail } from 'configs/channels/types';

type IEmailCreativeConfig = Partial<{
  senderNames: Array<string>;
  useReplyTo: boolean; // Default = true
}>;

export type IEmailConfig = {
  name: IEmail;
  creative?: IEmailCreativeConfig;
};

export const getEmailCreativeConfig = (appConfig: IAppConfig): IEmailCreativeConfig => {
  const channelSetup = findChannelSetup(appConfig, channelNameEmail) || {};
  const emailCreative = channelSetup.creative || {};
  return {
    ...emailCreative,
    useReplyTo: emailCreative.useReplyTo !== false,
  };
};
