import React from 'react';
import styled from 'styled-components';
import { Typography, Modal } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { sc } from 'app/styles';
import { Button as _Button, Icon, Page, PageHeader } from 'app/midgarComponents';
import { triggerTypes } from 'app/utilities/constants';

import { ICampaign } from 'app/types';
import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';

import { withAppConfig } from 'app/decorators/index';
import { IAppConfig } from 'configs/apps/types';
import General from '../General/General';
import Target from '../Target';
import Channel from '../Channel';
import { DrawerError } from 'app/components';

type Props = {
  campaign: ICampaign;
  newCampaign: (...args: Array<any>) => any;
  history: {
    location: {
      search: string;
    };
  };

  isPermitted: IsPermittedFn;
  match: {
    params: {
      type: string;
    };
  };

  saveAsDraft: (...args: Array<any>) => any;
  setHiddenFormsSubmitted: (...args: Array<any>) => any;
  resetHiddenFormsSubmitted: (...args: Array<any>) => any;
  toggleSubmitAllForms: (...args: Array<any>) => any;
  setCampaignField: (...args: Array<any>) => any;
  valid: boolean;
  validationFailures?: Array<string>;
  classes: {};
  appConfig: IAppConfig;
};

type State = {
  openModal: boolean;
  postSubmitFn: ((...args: Array<any>) => any) | null;
};

const TRIGGER_WARNING = 'You have selected location based trigger or allDay campaign, please verify.';

class New extends React.Component<Props, State> {
  state = {
    openModal: false,
    postSubmitFn: null,
  };

  componentDidMount() {
    const { resetHiddenFormsSubmitted } = this.props;
    resetHiddenFormsSubmitted();
  }

  componentDidUpdate() {
    const {
      campaign: {
        general: { formErrors, hiddenFormsSubmitted, submitAllForms, mediumId },
      },

      resetHiddenFormsSubmitted,
      setCampaignField,
    } = this.props;
    const { postSubmitFn } = this.state;
    const allHiddenFormsSubmitted = Object.values(hiddenFormsSubmitted).reduce(
      (acc, submittedFormValue) => Boolean(acc && submittedFormValue),
      true,
    );

    const containsInlineErrors = Object.values(formErrors).filter(Boolean).length;

    if ((allHiddenFormsSubmitted || containsInlineErrors || !mediumId) && postSubmitFn && submitAllForms) {
      resetHiddenFormsSubmitted();

      setCampaignField({ submitAllForms: false });
      postSubmitFn();
      this.setState({ postSubmitFn: null });
    }
  }

  isAppliedAllDay = () => {
    const {
      campaign: { general },
    } = this.props;
    return general.timeRange.from === '00' && general.timeRange.to === '24';
  };

  verifyFormState = () => {
    const { match, history, campaign, valid } = this.props;

    if (!valid || (campaign.general.triggerType === triggerTypes.location && this.isAppliedAllDay())) {
      if (!this.state.openModal) this.setState({ openModal: true });
    } else {
      // TODO: This push appears to happen multiple times, which makes the subsequent "back" button require repeated clicks
      const path = match.params.type === 'new' ? '/campaigns/new/overview' : `/campaigns/edit/${campaign.general.id}/overview`;
      history.push(path);
    }
  };

  renderWarningModal = () => {
    const { openModal } = this.state;
    const {
      match,
      campaign,
      classes: { modalStyles },
      validationFailures,
      valid,
    } = this.props;
    const path = match.params.type === 'new' ? '/campaigns/new/overview' : `/campaigns/edit/${campaign.general.id}/overview`;

    return (
      <Modal open={openModal}>
        <div className={modalStyles} data-qa="create-campaign-warning-modal">
          <Heading2 variant="h6" gutterBottom id="modal-title" color={valid ? sc.subHeadingColor : sc.danger}>
            {valid ? 'Confirmation' : 'Validation Error'}
            {valid ? null : <DangerIcon name="warning" color={sc.danger} />}
          </Heading2>
          <Typography variant="subtitle1" id="simple-modal-description">
            <WarningMessage data-qa="create-campaign-warning-message">
              {!valid ? validationFailures.join('\r\n\n') : TRIGGER_WARNING}
            </WarningMessage>
          </Typography>
          <Button onClick={() => this.setState({ openModal: false })}>Keep Editing</Button>
          {valid && (
            <Button variant="contained" color="primary" component={Link} to={path}>
              Continue
            </Button>
          )}
        </div>
      </Modal>
    );
  };

  persistAndSetPostSubmitFn = postSubmitFn => {
    const {
      appConfig: { enableGoals },
      setHiddenFormsSubmitted,
      setCampaignField,
    } = this.props;

    // TODO: Need better system for filtering out conditionally
    // hidden form sections
    if (!enableGoals) setHiddenFormsSubmitted({ measurements: true });

    setCampaignField({ submitAllForms: true });
    this.setState({ postSubmitFn });
  };

  render() {
    const { campaign, match, saveAsDraft } = this.props;
    const campaignTitle = match.params.type === 'new' ? 'New Campaign' : 'Edit Campaign';

    return (
      <Page>
        <PageHeader title={campaignTitle} sticky>
          <section>
            <Button type="secondary" onClick={() => this.persistAndSetPostSubmitFn(saveAsDraft)} data-qa="button-save-as-draft">
              Save as Draft
            </Button>

            <Button onClick={() => this.persistAndSetPostSubmitFn(this.verifyFormState)} data-qa="button-proceed-to-overview">
              Proceed to Overview
            </Button>
          </section>
        </PageHeader>

        {campaign.general.saveError && campaign.general.saveError.details && (
          <DrawerError
            error={campaign.general.saveError.message}
            errorDetails={campaign.general.saveError.details}
            handleDismiss={this.props.resetHiddenFormsSubmitted}
          />
        )}

        <Content>
          <General {...campaign.general} />

          <Target />

          <Channel {...campaign.general} />
        </Content>

        {this.renderWarningModal()}
      </Page>
    );
  }
}

export default withAppConfig(New);

const Heading2 = styled.h2`
  font-size: ${sc.fontSizeLarge};
  color: ${props => (props && props.color ? props.color : sc.subHeadingColor)};
`;

const DangerIcon = styled(Icon)`
  margin: 0 5px;
`;

const Button = styled(_Button)`
  margin-right: ${sc.gutter};
  &:last-child {
    margin-right: 0;
  }
`;

const Content = styled.section`
  height: calc(100% - 90px);
  overflow: scroll;
`;

const WarningMessage = styled.pre`
  margin: 1rem 0;
  white-space: pre-wrap;
  line-height: ${sc.fontSize};
  font-size: ${sc.fontSizeSmall};
`;
