import * as React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { sc } from 'app/styles';
import { Formik, Form as _Form, Field } from 'formik';
import { initializeTargetValues } from './formValues';
import AudienceFilter from './AudienceFilter';
import PotentialReach from './PotentialReach';
import UseEveryone from './UseEveryone';
import Audiences from './Audiences';
import MaxAudience from './MaxAudience';
import { AutoSubmit, ToggleWithLabel } from 'app/midgarComponents';
import { AudiencesFormikProps, FormikField } from 'app/utilities/types/formik';
import { Segments } from '../../../types/typescript/Segments';
import { campaignTargetSchema } from './validationSchema';
import { getChannelCreativeConfig } from 'configs/channels';
import { setFormErrors, getCampaignSizeSuccess } from 'app/ducks/campaigns/campaign/actions';
import { AppConfig } from '../../../../configs/apps/typescript/types';
import { UPDATECONFIGSTORE } from 'app/ducks/configStore/types';
import type { JSX } from 'react';

interface Props {
  includedSegments: Segments;
  excludedSegments: Segments;
  includedSegmentsFilters: Segments;
  excludedSegmentsFilters: Segments;
  includedSegmentsGeoFilters: Segments;
  customerCountPerExecution: number;
  audienceFiltersEnabled: boolean;
  useEveryoneAudience: boolean;
  exclusionCampaign: boolean;
  saveTargetValues: (values: unknown) => void;
  maxAudienceRequired: boolean;
  appConfig: AppConfig;
  mediumId: number;
  triggerType: string;
  filterEnableHandler: (enable: boolean) => void;
  getEligibleSegments: (
    segments: Segments,
    ineligibleItems: Segments,
    allowBoostSegments: boolean,
    audienceFiltersEnabled: boolean,
  ) => Segments;
  segments: Segments;
  getSegments: (value: string, type: string) => Promise<void>;
  segmentsFetching: boolean;
  supportedTypes: string;
  maxAudienceLimit: string | null;
  audienceId: Segments;
  submitAllForms: boolean;
  versionChecked: boolean;
  setVersionChecked: (value: boolean) => void;
}

const AudienceVersions = (props: Props): JSX.Element => {
  const {
    includedSegments,
    excludedSegments,
    includedSegmentsFilters,
    excludedSegmentsFilters,
    includedSegmentsGeoFilters,
    customerCountPerExecution,
    audienceFiltersEnabled,
    useEveryoneAudience,
    exclusionCampaign,
    saveTargetValues,
    maxAudienceRequired,
    appConfig,
    mediumId,
    triggerType,
    filterEnableHandler,
    getEligibleSegments,
    segments,
    getSegments,
    segmentsFetching,
    supportedTypes,
    maxAudienceLimit,
    submitAllForms,
    versionChecked,
    audienceId = [],
    setVersionChecked,
  } = props;
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={initializeTargetValues({
        includedSegments,
        excludedSegments,
        includedSegmentsFilters,
        excludedSegmentsFilters,
        includedSegmentsGeoFilters,
        customerCountPerExecution,
        audienceFiltersEnabled,
        useEveryoneAudience,
        exclusionCampaign,
        audienceId,
      })}
      onSubmit={saveTargetValues}
      validationSchema={campaignTargetSchema({ maxAudienceRequired, exclusionCampaign, versionChecked })}
      enableReinitialize
    >
      {({ handleSubmit, setFieldValue, values }: typeof AudiencesFormikProps): JSX.Element => {
        const resetAudience = (flag: boolean) => {
          setVersionChecked(flag);
          dispatch(getCampaignSizeSuccess(0));
          values.includedSegments.length = 0;
          values.excludedSegments.length = 0;
          values.audienceId.length = 0;
          values.audienceFiltersEnabled = false;
          values.includedSegmentsFilters.length = 0;
          values.excludedSegmentsFilters.length = 0;
          dispatch({ type: UPDATECONFIGSTORE, payload: { ...appConfig, audiences: { ...appConfig.audiences, versionChecked: flag } } });
        };
        return (
          <Form onSubmit={handleSubmit}>
            {!useEveryoneAudience ? (
              <>
                <StyledFragment>
                  <Header>Audiences</Header>
                  <ToggleWithLabel
                    optionLabels={['V1', 'V2']}
                    onChange={(): void => resetAudience(!versionChecked)}
                    checked={versionChecked}
                    name="audienceVersionToggle"
                    disabled={false}
                    className="toggle-switch"
                  />
                </StyledFragment>
              </>
            ) : (
              <Header>Audiences</Header>
            )}
            <div>
              {getChannelCreativeConfig(appConfig, mediumId).sendToEveryone && !appConfig.isSecureRoom && (
                <UseEveryone
                  mediumId={mediumId}
                  triggerType={triggerType}
                  appConfig={appConfig}
                  useEveryoneAudience={useEveryoneAudience}
                  filterEnableHandler={filterEnableHandler}
                  setFieldValue={setFieldValue}
                  resetAudience={resetAudience}
                />
              )}
              {!useEveryoneAudience && (
                <>
                  <Audiences
                    includedSegments={values.includedSegments}
                    excludedSegments={values.excludedSegments}
                    includedsegmentOptions={getEligibleSegments(
                      segments,
                      values.includedSegmentsFilters,
                      true,
                      values.audienceFiltersEnabled,
                    )}
                    excludedsegmentOptions={getEligibleSegments(
                      segments,
                      values.excludedSegmentsFilters,
                      false,
                      values.audienceFiltersEnabled,
                    )}
                    fetchSegments={getSegments}
                    segmentsFetching={segmentsFetching}
                    supportedTypes={supportedTypes}
                    exclusionCampaign={exclusionCampaign}
                    versionChecked={versionChecked}
                    audienceId={values.audienceId}
                    mediumId={mediumId}
                    resetAudience={(flag: boolean) => resetAudience(flag)}
                  />
                  {!versionChecked && (
                    <>
                      <AudienceFilter
                        includedsegmentOptions={getEligibleSegments(
                          segments,
                          values.includedSegments,
                          false,
                          values.audienceFiltersEnabled,
                        )}
                        excludedsegmentOptions={getEligibleSegments(
                          segments,
                          values.excludedSegments,
                          false,
                          values.audienceFiltersEnabled,
                        )}
                        includedSegmentsFilters={values.includedSegmentsFilters}
                        excludedSegmentsFilters={values.excludedSegmentsFilters}
                        segmentsFetching={segmentsFetching}
                        fetchSegments={getSegments}
                        audienceFiltersEnabled={values.audienceFiltersEnabled}
                        filterEnableHandler={filterEnableHandler}
                        exclusionCampaign={exclusionCampaign}
                        setFieldValue={setFieldValue}
                      />
                      <Field name="customerCountPerExecution">
                        {({ field: { name, value, onChange }, form: { touched, errors } }: typeof FormikField): JSX.Element => (
                          <MaxAudience
                            appConfig={appConfig}
                            maxAudienceLimit={maxAudienceLimit}
                            maxAudienceRequired={maxAudienceRequired}
                            setFieldValue={setFieldValue}
                            error={touched.customerCountPerExecution && errors.customerCountPerExecution}
                            value={values.customerCountPerExecution as number}
                          />
                        )}
                      </Field>
                    </>
                  )}
                </>
              )}
            </div>

            <PotentialReach
              useEveryoneAudience={useEveryoneAudience}
              includedSegments={values.includedSegments}
              excludedSegments={values.excludedSegments}
              isExclusionCampaign={exclusionCampaign}
              includedSegmentsFilters={values.includedSegmentsFilters}
              excludedSegmentsFilters={values.excludedSegmentsFilters}
              includedSegmentsGeoFilters={values.includedSegmentsGeoFilters}
              audienceFiltersEnabled={values.audienceFiltersEnabled}
              maxAudienceLimit={Number(maxAudienceLimit)}
            />
            <AutoSubmit formName="audiences" setStoreValidityState={setFormErrors} submitTrigger={submitAllForms} />
          </Form>
        );
      }}
    </Formik>
  );
};

const Form = styled(_Form)`
  > section {
    margin-bottom: ${sc.gutter};
  }
`;

const Header = styled.h2`
  color: ${sc.secondary};
  font-size: ${sc.fontSizeLarge};
  margin-bottom: ${sc.gutterSmall};
  text-transform: uppercase;
`;

const StyledFragment = styled.div`
  display: ruby;
`;

export default AudienceVersions;
