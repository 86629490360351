import React from 'react';
import { Avatar } from '@material-ui/core';

interface OrSeparatorProps {
  classes: Record<string, string>;
}

export const OrSeparator = ({ classes }: OrSeparatorProps) => (
  <div className={classes.row}>
    <Avatar className={classes.avatar}>OR</Avatar>
  </div>
);
