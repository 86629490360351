import { Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.error.main,
  },
  summary: {},
  details: {},
  detailsPre: {
    whiteSpace: 'pre-wrap',
    marginTop: '1rem',
  },
  divider: {
    backgroundColor: theme.palette.common.black,
  },
});
