import * as api from 'app/api/segments';
import { Action, DispatchFn, IQueryParams } from 'app/types';

import * as actions from './actions';

export const getDownloadStatus =
  (id: number) =>
  async (dispatch: DispatchFn): Action => {
    dispatch(actions.checkDownloadStatus(id));
    try {
      const { state, urls: downloadUrls } = await api.downloadSegment(id);
      dispatch(actions.checkDownloadStatusSuccess({ state, downloadUrls, id }));
    } catch (e) {
      dispatch(actions.checkDownloadStatusError(id));
    }
  };

export const getAllSegmentsPaged =
  (params: IQueryParams) =>
  async (dispatch: DispatchFn): Action => {
    dispatch(actions.getAll());
    try {
      const { first, last, number, totalElements, size, content: segments } = await api.getSegmentsPaged(params);

      return dispatch(
        actions.getAllSuccess(segments, {
          first,
          last,
          number,
          size,
          totalElements,
        }),
      );
    } catch (e) {
      return dispatch(actions.getAllFail(e));
    }
  };
