import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import RuleValueEdit from './RuleValueEdit';

interface RuleValueEditWithLoadingProps {
  promise: Promise<unknown>;
  onSuccess?: (result: unknown) => void;
  onFailure?: (error: Error) => void;
}

const RuleValueEditWithLoading: React.FC<RuleValueEditWithLoadingProps> = ({ promise, onSuccess, onFailure, ...props }) => {
  const [isAwaiting, setIsAwaiting] = useState(true);

  useEffect(() => {
    const handleSuccess = (result: unknown) => {
      setIsAwaiting(false);
      onSuccess?.(result);
    };

    const handleFailure = (error: Error) => {
      setIsAwaiting(false);
      onFailure?.(error);
    };

    if (promise) {
      promise.then(handleSuccess).catch(handleFailure);
    }
  }, [promise, onSuccess, onFailure]);

  if (isAwaiting) {
    return <CircularProgress size={20} />;
  }

  return <RuleValueEdit {...props} />;
};

export default RuleValueEditWithLoading;
