import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

interface LoadingProps {
  size?: number;
  classes: {
    root?: string;
  };
}

export const Loading = ({ size = 50, classes }: LoadingProps) => <CircularProgress className={classes.root} size={size} />;
