import * as React from 'react';
import styled from 'styled-components';

import { FormControlLabel, Checkbox } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

import { TemplateDropdown } from 'app/features/Personalization/TemplateDropdown';
import { emptyTemplate } from 'app/features/Personalization/TemplateSelection';
import { Input } from 'app/midgarComponents';
import { Tooltip } from 'app/components';
import SelectDeeplink from './SelectDeeplink';

interface DeeplinkSelectionProps {
  setDeeplink(...args: unknown[]): unknown;
  updateDeeplinkExtra(...args: unknown[]): unknown;
  deeplink: Record<string, any>;
  selectedDeeplink?: {
    label?: string;
    key?: string;
    value?: string;
    extra?: any[];
  };
  isDeeplink: boolean;
  toggleDeeplink?(...args: unknown[]): unknown;
}

export const DeeplinkSelection = ({
  setDeeplink,
  updateDeeplinkExtra,
  deeplink,
  isDeeplink,
  selectedDeeplink,
  toggleDeeplink,
  showTemplateToggleV2 = false,
  disableTemplate = true,
  currTemplate = emptyTemplate,

  setCurrTemplate = () => {
    /* no-op */
  },
}: DeeplinkSelectionProps) => {
  return (
    <div data-qa="legacy-deeplink">
      {toggleDeeplink && (
        <FormControlLabel key="isDeeplink" control={<Checkbox checked={isDeeplink} onChange={toggleDeeplink} />} label="Deeplink" />
      )}

      {isDeeplink && (
        <FlexContainer>
          <SelectDeeplink required selectedDeeplink={selectedDeeplink} id="type" label="Deeplink type" setDeeplink={setDeeplink} />
          <VerticalFlexContainer>
            {selectedDeeplink &&
              selectedDeeplink.extra &&
              selectedDeeplink.extra.map(({ key, required, label, desc, supportTemplate = false }) => (
                <React.Fragment key={key}>
                  {showTemplateToggleV2 && supportTemplate && (
                    <SpaceContainer>
                      <TemplateDropdown
                        disabled={disableTemplate}
                        onChange={e => updateDeeplinkExtra({ [key]: e.target.value })}
                        name="legacy-deeplink"
                        currTemplate={currTemplate}
                        setCurrTemplate={setCurrTemplate}
                      ></TemplateDropdown>
                    </SpaceContainer>
                  )}

                  <div>
                    <Input
                      required={required}
                      value={deeplink[key] || (deeplink.options && deeplink.options[key]) || ''}
                      id={key}
                      data-qa={`deeplink-extra-${key}`}
                      placeholder={label}
                      onChange={e => updateDeeplinkExtra({ [key]: e.target.value })}
                      width="15rem"
                      height="4.5rem"
                      margin="auto 0.5rem"
                    />

                    {desc && (
                      <Tooltip id={`${key}-${label}-description}`} title={desc}>
                        <HelpOutline />
                      </Tooltip>
                    )}
                  </div>
                </React.Fragment>
              ))}
          </VerticalFlexContainer>
        </FlexContainer>
      )}
    </div>
  );
};

const FlexContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-flow: wrap;
  margin-bottom: 1rem;
`;

const VerticalFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpaceContainer = styled.div`
  margin-bottom: 1rem;
  margin-left: 0.5rem;
`;
