import * as React from 'react';
import { Button, Typography } from '@material-ui/core';
import { NavLink } from 'reactstrap';

interface NotFoundProps {
  classes?: {
    root?: string;
    error?: string;
    button?: string;
  };
  errorTitle: string;
  goBackPath: string;
  feature: string;
}

export const NotFound = ({ errorTitle, goBackPath, feature, classes = {} }: NotFoundProps) => (
  <section className={classes.root}>
    <Typography className={classes.error} align="center" variant="h5">
      {errorTitle}
    </Typography>
    <Button variant="contained" color="primary" component={NavLink} to={goBackPath} className={classes.button} size="large">
      View all {feature}
    </Button>
  </section>
);
