import { RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import qs from 'query-string';
import { isEmpty } from 'ramda';

import { BANNERS_BINDER_ITEM_OVERVIEW } from 'configs/routes';

interface Params {
  storefrontId: string;
  viewId: string;
  viewItemId: string;
}

export const urlViewItemOverview = (
  viewItemId: number,
  viewId: number,
  storefrontId: number,
  entityId?: number,
  entityType?: string,
  query?: Record<string, string>,
) => {
  const baseUrl = BANNERS_BINDER_ITEM_OVERVIEW.replace(':storefrontId', String(storefrontId))
    .replace(':viewId', String(viewId))
    .replace(':viewItemId', String(viewItemId));

  if (entityId || entityType || !isEmpty(query)) {
    const fullQuery = {
      ...(query || {}),
      ...(entityId ? { entityId } : {}),
      ...(entityType ? { entityType } : {}),
    };

    return `${baseUrl}?${qs.stringify(fullQuery)}`;
  }

  return baseUrl;
};

export const parseRouteViewItemOverview = ({ history, match }: { history: History; match: RouteComponentProps<Params>['match'] }) => {
  const { storefrontId, viewId, viewItemId } = match.params;
  const { entityId, entityType } = qs.parse(history.location.search);

  return {
    storefrontId: Number(storefrontId),
    viewId: Number(viewId),
    viewItemId: Number(viewItemId),
    ...(entityId ? { entityId: Number(entityId) } : {}),
    ...(entityType ? { entityType } : {}),
  };
};
