import * as React from 'react';

import { DrawerError } from 'app/components';
import { Page, PageHeader } from 'app/midgarComponents';

import { PageMain, Spinner } from '../common/styledComponents';
import { ButtonGroup } from './ButtonGroup';
import General from './General';
import EmailDetails from './EmailDetails';
import Design from './Design';

import { ITemplate, IUploadResult } from './types';
import { IMedium } from 'app/types';

type Props = {
  pageTitle: string;
  template: ITemplate;
  setName: (...args: Array<any>) => any;
  setDescription: (...args: Array<any>) => any;
  setContent: (...args: Array<any>) => any;
  setSubject: (...args: Array<any>) => any;
  setSendFrom: (...args: Array<any>) => any;
  setSendFromName: (...args: Array<any>) => any;
  setReplyTo: (...args: Array<any>) => any;
  setReplyToName: (...args: Array<any>) => any;
  emailSenders?: Array<
    Partial<{
      id?: number | string;
      name?: string;
    }>
  >;

  emailSendersLoading?: boolean;
  saveButtonLabel: string;
  saveButtonTooltip?: string;
  saveButtonDisabled?: boolean;
  handleSave: (...args: Array<any>) => any;
  fileUploads?: Array<IUploadResult>;
  apiFileUpload: (...args: Array<any>) => any;
  setFileUploads: (...args: Array<any>) => any;
  handleAcceptedFiles?: (...args: Array<any>) => any;
  handleRejectedFiles?: (...args: Array<any>) => any;
  handleTestDelivery: (...args: Array<any>) => any;
  handleDismissError: (...args: Array<any>) => any;
  newTemplate: (arg0: { id?: number; medium?: IMedium }) => void;
};

export const TemplatePage = ({
  pageTitle,
  template,
  setName,
  setDescription,
  setContent,
  setSubject,
  setSendFrom,
  setSendFromName,
  setReplyTo,
  setReplyToName,
  emailSenders = [],
  emailSendersLoading = false,
  saveButtonLabel,
  saveButtonTooltip = '',
  saveButtonDisabled = false,
  handleSave,
  fileUploads = [],
  apiFileUpload,
  setFileUploads,
  handleAcceptedFiles,
  handleRejectedFiles,
  handleTestDelivery,
  handleDismissError,
  newTemplate,
}: Props) => (
  <Page>
    <PageHeader title={pageTitle} backLink="/channels/email">
      <ButtonGroup
        disabled={saveButtonDisabled}
        label={saveButtonLabel}
        tooltipText={saveButtonTooltip}
        handleSave={handleSave}
        handleReset={() => newTemplate({ id: template.id, medium: template.medium })}
      />
    </PageHeader>

    <PageMain>
      {template.loading ? (
        <Spinner />
      ) : (
        <>
          {(template.error || template.errorDetails) && (
            <DrawerError defaultExpanded error={template.error} errorDetails={template.errorDetails} handleDismiss={handleDismissError} />
          )}

          <General name={template.name} setName={setName} description={template.description} setDescription={setDescription} />
          <EmailDetails
            title="EMAIL DETAILS"
            subject={template.subject}
            sender={template.sendFrom}
            senderName={template.sendFromName}
            replyTo={template.replyTo}
            replyToName={template.replyToName}
            allEmailSenders={emailSenders}
            emailSendersLoading={emailSendersLoading}
            setSubject={setSubject}
            setSender={setSendFrom}
            setSenderName={setSendFromName}
            setReplyTo={setReplyTo}
            setReplyToName={setReplyToName}
            handleTestDelivery={handleTestDelivery}
          />

          <Design
            content={template.content}
            fileUploads={fileUploads}
            setContent={setContent}
            apiFileUpload={apiFileUpload}
            setFileUploads={setFileUploads}
            handleAcceptedFiles={handleAcceptedFiles}
            handleRejectedFiles={handleRejectedFiles}
          />
        </>
      )}
    </PageMain>
  </Page>
);
