import * as React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import { fetchViews } from 'app/api/bannerManagementV2/views';
import { DrawerError } from 'app/components';
import { withAppConfig, withPermissions } from 'app/decorators';
import { storefrontActions, storefrontOperations, storefrontsMgmtOperations } from 'app/ducks';
import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { displayError, displayWarning } from 'app/helpers/NotificationHelpers/helpers';
import { Button, Page, PageHeader, PageMain, Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IStorefront } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { bannerManagementEditPermissions } from 'configs/permissions';
import { IAppConfig } from 'configs/apps/types';

import { __DEV__ } from 'configs/settings';
import { Header as _Header } from '../../common/components';
import { isReadOnlyStorefront } from '../../common/helpers';
import { parsePathStorefrontEdit, urlStorefronts, urlViewNew } from '../../routeUtils';
import Storefront from './Storefront';
import Views from './Views';
import { validate } from './validate';

type Props = {
  appConfig: IAppConfig;
  deleteStorefront: () => (...args: Array<any>) => any;
  entityId?: number;
  entityType?: string;
  error?: string;
  fetchStorefront: (storefrontId: number, entityType?: string, entityId?: number) => (...args: Array<any>) => any;
  fetchStorefrontUsers: (arg0: number) => (...args: Array<any>) => any;
  history: History;
  isPermitted: IsPermittedFn;
  loading: boolean;
  readOnly: boolean;
  resetStorefront: () => (...args: Array<any>) => any;
  saveStorefront: () => (...args: Array<any>) => any;
  saving: boolean;
  setStorefrontType: (arg0: string) => (...args: Array<any>) => any;
  storefront: IStorefront;
  storefrontId?: number;
};

const mapStateToProps = (
  {
    acls: { storefrontsMgmt },
    bannerManagement: {
      storefront: { error, loading, saving, storefront },
    },

    user,
  },

  { storefrontId },
) => ({
  error,
  loading,
  readOnly: isReadOnlyStorefront(user, storefrontsMgmt)(storefrontId),
  saving,
  storefront,
});

const mapDispatchToProps = (dispatch: Dispatch<{ type: string }>, { appConfig }: { appConfig: IAppConfig }) => {
  const { bmsVersion } = getBannerCreativeConfig(appConfig);

  return {
    deleteStorefront: () => dispatch(storefrontOperations.deleteStorefront()),
    fetchStorefront: (storefrontId: number, entityType?: string, entityId?: number) =>
      dispatch(storefrontOperations.getStorefront(bmsVersion)(storefrontId, entityType, entityId)),
    fetchStorefrontUsers: (storefrontId: number) => dispatch(storefrontsMgmtOperations.getAclSFUsers(storefrontId)),
    resetStorefront: () => dispatch(storefrontActions.resetStorefront()),
    saveStorefront: () => dispatch(storefrontOperations.saveStorefront()),
    setStorefrontType: (entityType: string) => dispatch(storefrontActions.setStorefrontType(entityType)),
  };
};

type State = {
  deleting: boolean;
  shouldValidate: boolean;
};

class Overview extends React.PureComponent<Props, State> {
  bannerConfig = getBannerCreativeConfig(this.props.appConfig); // eslint-disable-line react/destructuring-assignment

  isNew = !this.props.storefrontId; // eslint-disable-line react/destructuring-assignment

  state = {
    deleting: false,
    shouldValidate: false,
  };

  componentDidMount() {
    const { entityId, entityType, fetchStorefront, fetchStorefrontUsers, resetStorefront, setStorefrontType, storefrontId } = this.props;

    if (storefrontId) {
      fetchStorefront(storefrontId, entityType, entityId);
    } else {
      resetStorefront();
    }

    if (entityType) {
      setStorefrontType(entityType);
    }

    const { useAclService } = this.bannerConfig;
    if (storefrontId && useAclService && !__DEV__) {
      fetchStorefrontUsers(storefrontId);
    }
  }

  componentWillUnmount() {
    const { resetStorefront } = this.props;
    resetStorefront();
  }

  onDelete = async () => {
    const { deleteStorefront, entityId, entityType, history, storefrontId } = this.props;

    this.setState({ deleting: true });
    try {
      const views = await fetchViews(storefrontId, entityType, entityId);
      if (views && views.length) {
        displayWarning('Storefront must have no views before deleting');
      } else {
        deleteStorefront();
        history.push(urlStorefronts());
      }
    } catch (err) {
      console.error('Error while checking views before deleting', err); // eslint-disable-line no-console
      displayError('Error while checking views before deleting');
    }
    this.setState({ deleting: false });
  };

  onSave = () => {
    const { history, saveStorefront, storefront } = this.props;
    this.setState({ shouldValidate: true });
    if (validate(storefront)) {
      saveStorefront();
      history.push(urlStorefronts());
      // TODO: Better to go to the newly created page, but we need the new ID, which is not returned directly by the API
    }
  };

  render() {
    const { entityId, entityType, error, isPermitted, loading, readOnly, saving, storefront, storefrontId } = this.props;
    const { deleting, shouldValidate } = this.state;

    const isFormValid = validate(storefront);
    const { useStorefrontCreate, useStorefrontDelete, useStorefrontUpdate, useViewCreate } = this.bannerConfig;

    const useSaveButton = (this.isNew && useStorefrontCreate) || (!this.isNew && useStorefrontUpdate);
    const saveLabel = this.isNew ? 'Create' : 'Save';

    return (
      <Page>
        <PageHeader title={storefrontId ? `Storefront ${storefrontId}` : 'New Storefront'} backLink={urlStorefronts()}>
          {saving ? (
            <Spinner />
          ) : (
            isPermitted(bannerManagementEditPermissions) && (
              <ButtonsContainer>
                {useStorefrontDelete && !this.isNew && (
                  <DeleteButton disabled={!storefrontId || deleting} onClick={this.onDelete} type="primary">
                    {deleting ? <Spinner /> : 'Delete'}
                  </DeleteButton>
                )}

                {useSaveButton && (
                  <Button disabled={shouldValidate && !isFormValid} onClick={this.onSave} type="primary">
                    {saveLabel}
                  </Button>
                )}
              </ButtonsContainer>
            )
          )}
        </PageHeader>

        <PageMain>
          {error ? (
            <DrawerError
              error={`Error while ${this.isNew ? 'saving' : 'retrieving'} the storefront`}
              errorDetails={JSON.stringify(error, null, 2)} // TODO: Generate a clean error message
            />
          ) : (
            <>
              <Section>
                <Storefront readOnly={readOnly || !useStorefrontUpdate} shouldValidate={shouldValidate} />
              </Section>

              {!this.isNew && !loading && (
                <Section>
                  <HeaderRow>
                    <Header>Views</Header>

                    {useViewCreate &&
                      isPermitted(bannerManagementEditPermissions) &&
                      storefrontId !== null &&
                      storefrontId !== undefined && (
                        <Button size="small" to={urlViewNew(storefrontId, entityId, entityType)} type="secondary">
                          Create View
                        </Button>
                      )}
                  </HeaderRow>

                  <Views entityId={entityId} entityType={entityType} readOnly={readOnly} storefrontId={storefrontId} />
                </Section>
              )}
            </>
          )}
        </PageMain>
      </Page>
    );
  }
}

export default compose(
  withAppConfig,
  withPermissions,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withProps(parsePathStorefrontEdit),
)(Overview);

const ButtonsContainer = styled.div`
  & > button {
    margin-right: ${sc.gutterSmall};
  }
`;

const DeleteButton = styled(Button)`
  width: 6rem;
`;

const Header = styled(_Header)`
  background-color: transparent;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Section = styled.section`
  margin-bottom: ${sc.gutter};
`;
