import React from 'react';
import { prop } from 'ramda';
import { connect } from 'react-redux';
import { sc } from 'app/styles';

// eslint-disable-next-line import/no-cycle
import Icon from 'app/midgarComponents/Icon';
import styled from 'styled-components';
import TestDelivery from 'app/components/TestDelivery';
import { ConnectedTimelineNotification } from 'app/ducks';
import * as campaignApi from 'app/api/campaigns';
import { DISPLAY as display } from '../timelineNotificationConfigConstants';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { Grid } from 'app/midgarComponents';
import { triggerDisplayValues } from 'app/utilities/constants';
import { formatDate, formatTime } from 'app/utilities/date';
import { channelNameTimelineNotification } from 'configs/channels/constants';
import * as timelineNotificationActions from 'app/ducks/campaigns/campaign/TimelineNotificationCreative/actions';

import {
  MainPage,
  Header,
  Name,
  Details,
  LeftColumn,
  RightColumn,
  Label,
  Text,
  Section,
} from '../../Campaigns/Overview/common/ChannelDetailsComponents';

import RecurringOverview from '../../Channels/RecurringOverview';

import { IAppConfig } from 'configs/apps/types';
import { ChannelStatus } from '../../../components/ChannelStatus/ChannelStatus';

const printLabelText = (label: string, text?: string, highlight?: boolean, isTextArea?: boolean) => (
  <Section $highlight={highlight}>
    <Label>{label}</Label>
    {isTextArea ? <InnerTextarea>{text}</InnerTextarea> : <Text>{text || 'Not Set'}</Text>}
  </Section>
);

const mapStateToProps = ({
  campaigns: {
    campaign: {
      TimelineNotificationCreative: { categoryList },
    },
  },
}) => ({
  categoryList,
});

const mapDispatchToProps = {
  updateCategoryList: timelineNotificationActions.updateCategoryList,
};

class TimelineNotificationOverview extends React.PureComponent<{
  appConfig: IAppConfig;
  variables: Array<any>;
  scheduling: Partial<{
    expiryDate: string;
    startDate: string;
    startTime: string;
    validDays: string;
    miniumInterval: string;
    maximumLimit: string;
  }>;

  state: string;
  cron: string;
  days: Array<string>;
  triggerType: string;
  notificationDelivery: (...args: Array<any>) => any;
  dedupeStrategy: string;
  criteria: Partial<{
    predicateFilter: string;
    eventName: string;
    predicateFilterObject: Array<any>;
    delayTime: null | { value: number; unit: string };
  }>;

  startDate: string;
  startTime: string;
  originalCampaign?: Record<string, any>;
}> {
  componentDidMount = async () => {
    const { categoryList, updateCategoryList } = this.props;

    if (categoryList && !Object.keys(categoryList).length) {
      try {
        const categoryListResponse = await campaignApi.getCategoryList();
        const tempCategoryList = {};

        categoryListResponse.map(
          item =>
            (tempCategoryList[item.threadId] = {
              ...item,
              name: item.displayName,
            }),
        );

        updateCategoryList({ categoryList: tempCategoryList });
      } catch (error) {
        displayError('Error fetching categories');
      }
    }
  };

  render() {
    const { appConfig, state, startDate, startTime, scheduling, triggerType, notificationDelivery, originalCampaign } = this.props;

    return (
      <MainPage>
        <ConnectedTimelineNotification>
          {({
            platforms,
            categoryList,
            payload: {
              title,
              messageLink,
              templateId,
              backgroundImage,
              threadId,
              threadType,
              sender: { icon },
            },
          }) => {
            const triggerTypeDiff = originalCampaign && originalCampaign.triggerType !== triggerType;
            const startDateDiff = originalCampaign && originalCampaign.startDate !== startDate;
            const startTimeDiff = originalCampaign && originalCampaign.startTime !== startTime;
            const threadTypeDiff = originalCampaign && originalCampaign.creative?.payload?.threadType !== threadType;
            const titleDiff = originalCampaign && originalCampaign.creative?.payload?.title !== title;
            const messageLinkDiff = originalCampaign && originalCampaign.creative?.payload?.messageLink !== messageLink;
            const templateIdDiff = originalCampaign && originalCampaign.creative?.payload?.templateId !== templateId;

            return (
              <>
                <Header>
                  <Name>{display}</Name>
                  <ChannelStatus state={state} />
                </Header>
                <Details>
                  <LeftColumn>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} data-qa="overview-trigger-type">
                        {printLabelText('Trigger Type: ', triggerDisplayValues[triggerType], triggerTypeDiff)}
                      </Grid>
                      <Grid item xs={12} sm={6} data-qa="overview-start-date">
                        {printLabelText('Start Date: ', formatDate(prop('Start Date', scheduling)), startDateDiff)}
                      </Grid>
                      <Grid item xs={12} sm={6} data-qa="overview-trigger-time">
                        {printLabelText('Trigger time: ', formatTime(prop('Start Date', scheduling)), startTimeDiff)}
                      </Grid>
                      <Grid item xs={12} sm={6} data-qa="overview-push-type">
                        {printLabelText('Categories: ', categoryList[threadId]?.displayName || threadType, threadTypeDiff)}
                      </Grid>
                      <Grid item xs={12} sm={6} data-qa="overview-message-link">
                        {printLabelText('Message Link: ', messageLink, messageLinkDiff)}
                      </Grid>
                      <Grid item xs={12} sm={6} data-qa="overview-message-link">
                        {printLabelText('Template Id: ', templateId, templateIdDiff)}
                      </Grid>
                      <Grid item xs={12} sm={6} data-qa="overview-message">
                        {printLabelText('Message: ', title, titleDiff, true)}
                      </Grid>
                      <RecurringOverview {...this.props} />
                    </Grid>
                  </LeftColumn>
                  <RightColumn>
                    {!appConfig.isSecureRoom && (
                      <section>
                        <TestDelivery
                          buttonText="Send Samples"
                          deliveryChannel={channelNameTimelineNotification}
                          handleSend={notificationDelivery}
                          rowsMax={3}
                        />
                      </section>
                    )}

                    <NotificationCard>
                      <NotificationCardIcon src={icon} alt="icon" />
                      <NotificationMainCard>
                        <NotificationImage src={backgroundImage} alt="image" />
                        <NotificationTitle>{title}</NotificationTitle>
                        {messageLink && (
                          <NotificationDetails>
                            View details
                            <Icon name="chevron-right" />
                          </NotificationDetails>
                        )}
                      </NotificationMainCard>
                      <NotificationLable>{formatTime(prop('Start Date', scheduling), 'hh:mm') || 'N/A'}</NotificationLable>
                    </NotificationCard>
                  </RightColumn>
                </Details>
              </>
            );
          }}
        </ConnectedTimelineNotification>
      </MainPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineNotificationOverview);

const InnerTextarea = styled.p`
  width: 100%;
  color: #536d90;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: 26px;
  min-height: 14rem;
  height: auto;
  border: none;
  padding: ${sc.gutter} ${sc.gutterSmall} ${sc.gutterSmallest};
  font-size: ${sc.fontSizeSmall};
  font-weight: normal;
  background-color: #f7f7f7;
`;

const NotificationCard = styled.div`
  display: flex;
  margin: 8px 0;
`;

const NotificationCardIcon = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
`;

const NotificationTitle = styled.p`
  width: 100%;
  padding: 8px 16px;
  color: #242323;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-size: 18px;
  line-height: 26px;
`;

const NotificationImage = styled.img`
  width: 100%;
  border-radius: 20px 20px 0 0;
  border: 0.5px solid #f7f7f7;
`;

const NotificationDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border-top: 0.5px solid #ddd;
  color: #a9a9a9;
  line-height: 18px;
  font-size: 18px;
`;

const NotificationMainCard = styled.div`
  flex: 1;
  min-width: 0;
  border-radius: 20px;
  background-color: #f7f7f7;
  margin: 0 6px 0 10px;
`;

const NotificationLable = styled.p`
  align-self: flex-end;
  color: #909090;
`;
