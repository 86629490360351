import React from 'react';

import { OrRuleGroup, SegmentCount } from 'app/components';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';

const onCalcuate = (validateRules, calculate) => () => {
  const { isValid, errorMessage, rules } = validateRules();

  if (isValid) calculate(rules);
  else displayError(errorMessage);

  return isValid;
};

interface RulesProps {
  rules: object[];
  errors?: object[];
  pathDict: Record<string, unknown[]>;
  originDict: Record<string, object>;
  pathsOfFeature(...args: unknown[]): unknown;
  optionsByPathKey(...args: unknown[]): unknown;
  newRowValue: object[];
  initialRuleForGroup: {
    key: number;
    value: object[];
  };
  updateState(...args: unknown[]): unknown;
  validateRules(...args: unknown[]): unknown;
  countOfQuery(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

export const Rules = ({
  rules,
  errors = [],
  pathDict,
  originDict,
  pathsOfFeature,
  optionsByPathKey,
  newRowValue,
  initialRuleForGroup,
  updateState,
  validateRules,
  countOfQuery,
  classes,
}: RulesProps) => (
  <section className={classes.customContainer}>
    <SegmentCount calculate={onCalcuate(validateRules, countOfQuery)} classes={classes} calcOnLoad={false} />
    <OrRuleGroup
      data={rules}
      newItem={newRowValue}
      {...{
        errors,
        pathDict,
        originDict,
        pathsOfFeature,
        optionsByPathKey,
        initialRuleForGroup,
        updateState,
      }}
    />
  </section>
);
