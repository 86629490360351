import * as React from 'react';
import ReactDropzone from 'react-dropzone';
import { CircularProgress } from '@material-ui/core';

interface DropzoneProps {
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  classes?: {
    root?: string;
    loading?: string;
  };
}

export const Dropzone = ({ children, loading = false, disabled = false, classes = {}, ...otherProps }: DropzoneProps) => (
  <ReactDropzone
    disabled={loading || disabled}
    className={classes.root}
    activeClassName={classes.loading}
    disabledClassName={classes.loading}
    {...otherProps}
  >
    <section>{children}</section>
    {loading && <CircularProgress />}
  </ReactDropzone>
);
