import React, { useState, useCallback, useEffect, ReactNode } from 'react';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { deepCopy } from 'app/utilities/deepCopy';
import { Icon } from 'app/midgarComponents';

type DraggableLocation = {
  droppableId: string;
  index: number;
};

type DropResult = {
  destination: DraggableLocation;
  draggableId: string;
  reason: 'DROP' | 'CANCEL';
  source: DraggableLocation;
  type: string;
};

type Props = {
  rows: Array<any>;
  readOnly?: boolean;
  updatePriortiesFn?: (arg0: Array<any>) => any;
  hideRow?: (arg0: any) => boolean;
  identifier: string;
  onClickRow?: (arg0: any) => void;
  hoverCursor?: string;
  withCheckbox?: boolean;
  withMoveToTop?: boolean;
  children: (arg0: any, arg1: number, arg2?: (arg0: any[], arg1: any, arg2: number) => {}) => ReactNode;
};

const arrangeFirst = (draggedRow, sortedSelectedRows, identifier) => {
  const selectedIds = sortedSelectedRows.map(id => parseInt(id));
  const indexToRemove = selectedIds.indexOf(draggedRow[identifier]);
  if (indexToRemove !== -1) {
    selectedIds.splice(indexToRemove, 1);
  }
  return [draggedRow[identifier], ...selectedIds];
};

const DraggableList = (props: Props) => {
  const {
    rows,
    readOnly,
    hideRow = num => false,
    children,
    identifier,
    updatePriortiesFn = rows => {
      /* no-op */
    },
    onClickRow,
    hoverCursor = 'grab',
    withCheckbox,
    withMoveToTop,
  } = props;

  const [selectedIds, setSelectedIds] = useState({});
  const [multiselectIndex, setMultiselectIndex] = useState(-1);
  const [isMultiSelectKeyPress, setIsMultiSelectKeyPress] = useState(false);

  useEffect(() => {
    const kd = e => {
      if ([93, 16, 17].includes(e.keyCode)) {
        setIsMultiSelectKeyPress(true);
      }
    };
    const ku = e => {
      if ([93, 16, 17].includes(e.keyCode)) {
        setIsMultiSelectKeyPress(false);
      }
    };
    window.addEventListener('keydown', kd);
    window.addEventListener('keyup', ku);
    return () => {
      window.removeEventListener('keydown', kd);
      window.removeEventListener('keyup', ku);
    };
  }, []);

  const getRowsFromIds = useCallback(ids => ids.map(id => rows.find(row => row[identifier] === id)), [rows, identifier]);

  const onDragStart = useCallback(
    draggingItem => {
      const id = parseInt(draggingItem.draggableId);
      if (typeof selectedIds[id] === 'undefined') {
        setSelectedIds({
          ...selectedIds,
          [id]: draggingItem.source.index,
        });
      }
    },
    [selectedIds],
  );

  const adjustPriority = useCallback(
    (rowsToMove, ids, newIndex) => {
      const newRows = deepCopy(rows);
      let isBeforeNewIndex = true;
      newRows.splice(newIndex, 0, ...rowsToMove);
      let i = 0;
      let toRemove = rowsToMove.length;
      while (i < newRows.length) {
        if (i === newIndex) {
          i += rowsToMove.length;
          isBeforeNewIndex = false;
          continue;
        }
        if (ids[newRows[i][identifier]] > -1) {
          newRows.splice(i, 1);
          toRemove--;
          if (isBeforeNewIndex) {
            newIndex--;
          }
        } else {
          i++;
        }
        if (!toRemove) {
          break;
        }
      }

      // Update selected ids after moving everything to new positions
      const newIndexIds = {};
      for (let x = newIndex; x < newIndex + Object.keys(ids).length; x++) {
        if (ids[newRows[x][identifier]] > -1) {
          newIndexIds[newRows[x][identifier]] = x;
        }
      }
      setSelectedIds(newIndexIds);

      updatePriortiesFn(newRows);
    },
    [identifier, rows, updatePriortiesFn],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, reason, source } = result;
      let newSelectedIds = selectedIds;
      const src = rows[source.index];

      let keysSorted = Object.keys(newSelectedIds).sort((a, b) => selectedIds[a] - selectedIds[b]);
      if (keysSorted.length) {
        keysSorted = getRowsFromIds(arrangeFirst(src, keysSorted, identifier));
      } else {
        keysSorted = [src];
        newSelectedIds = { [src[identifier]]: source.index };
      }

      if (reason === 'DROP' && destination) {
        let newIndex = destination.index;
        if (destination.index > source.index) {
          newIndex++;
        }
        adjustPriority(keysSorted, newSelectedIds, newIndex);
      }
      setMultiselectIndex(-1);
      setSelectedIds({});
    },
    [adjustPriority, getRowsFromIds, rows, identifier, selectedIds],
  );

  const onKeyDown = useCallback((event: KeyboardEvent, snapshot) => {
    // already used
    if (event.defaultPrevented) {
      return;
    }

    if (snapshot.isDragging) {
      return;
    }

    if (event.keyCode !== 13) {
      return;
    }

    // we are using the event for selection
    event.preventDefault();
  }, []);

  const toggleSelectionInGroup = useCallback(
    (id, index) => {
      const newSelectedIds = deepCopy(selectedIds);
      const toggled = typeof newSelectedIds[id] === 'undefined';
      if (toggled) {
        newSelectedIds[id] = index;
      } else {
        delete newSelectedIds[id];
      }
      setSelectedIds(newSelectedIds);
    },
    [selectedIds],
  );

  const setMultiSelectGroup = useCallback(
    endIndex => {
      let i = multiselectIndex;
      const newSelections = {};
      if (endIndex > i) {
        while (i <= endIndex) {
          newSelections[rows[i][identifier]] = i;
          i++;
        }
      } else {
        while (i >= endIndex) {
          newSelections[rows[i][identifier]] = i;
          i--;
        }
      }
      setSelectedIds(newSelections);
    },
    [identifier, multiselectIndex, rows],
  );

  const setSingleRow = useCallback(
    (id, index) => {
      if (withCheckbox) {
        const ids = deepCopy(selectedIds);
        if (typeof ids[id] !== 'undefined') {
          delete ids[id];
        } else {
          ids[id] = index;
        }
        setSelectedIds(ids);
      } else {
        setSelectedIds({ [id]: index });
      }
    },
    [selectedIds, withCheckbox],
  );

  const performAction = useCallback(
    (event: MouseEvent | KeyboardEvent, banner, index) => {
      if (wasToggleInSelectionGroupKeyUsed(event)) {
        toggleSelectionInGroup(banner[identifier], index);
        setMultiselectIndex(index);
        return;
      }

      if (wasMultiSelectKeyUsed(event)) {
        if (multiselectIndex === -1) {
          setMultiselectIndex(index);
        } else {
          setMultiSelectGroup(index);
        }
        return;
      }

      if (onClickRow) {
        onClickRow(banner);
        return;
      }

      setSingleRow(banner[identifier], index);
      setMultiselectIndex(index);
    },
    [identifier, multiselectIndex, onClickRow, setMultiSelectGroup, toggleSelectionInGroup, setSingleRow],
  );

  // Using onClick as it will be correctly
  // preventing if there was a drag
  const onClickDraggable = useCallback(
    (event: MouseEvent, banner, index) => {
      if (event.defaultPrevented) {
        return;
      }

      // marking the event as used
      event.preventDefault();
      performAction(event, banner, index);
    },
    [performAction],
  );

  // Determines if the platform specific toggle selection in group key was used
  const wasToggleInSelectionGroupKeyUsed = (event: MouseEvent | KeyboardEvent) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  // Determines if the multiSelect key was used
  const wasMultiSelectKeyUsed = (event: MouseEvent | KeyboardEvent) => event.shiftKey;

  const handleClickCheckbox = (e, row, i) => {
    e.stopPropagation();
    toggleSelectionInGroup(row[identifier], i);
  };

  const moveToTop = (row, event, index) => {
    event.stopPropagation();
    const newSelectedIds = { ...selectedIds, [row[identifier]]: index };
    let keysSorted = Object.keys(newSelectedIds).sort((a, b) => selectedIds[a] - selectedIds[b]);
    keysSorted = getRowsFromIds(arrangeFirst(row, keysSorted, identifier));

    adjustPriority(keysSorted, newSelectedIds, 0);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId="campaigns">
        {providedDroppable => (
          <TableContainer>
            <div ref={providedDroppable.innerRef} {...providedDroppable.droppableProps}>
              {rows.map((row, i) => (
                <Draggable key={String(row[identifier])} draggableId={String(row[identifier])} index={i} isDragDisabled={readOnly}>
                  {(providedDraggable, snapshot) => (
                    <DraggableItem
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                      hide={hideRow(row)}
                      isSelected={typeof selectedIds[row[identifier]] !== 'undefined'}
                      onKeyDown={(event: KeyboardEvent) => onKeyDown(event, snapshot)}
                      onClick={(event: MouseEvent) => onClickDraggable(event, row, i)}
                      isMultiSelectKeyPress={isMultiSelectKeyPress}
                      cursor={hoverCursor}
                      withCheckbox={withCheckbox}
                    >
                      <Flex>
                        {!readOnly && (
                          <>
                            {withCheckbox && (
                              <Checkbox
                                color="primary"
                                checked={typeof selectedIds[row[identifier]] !== 'undefined'}
                                onClick={e => handleClickCheckbox(e, row, i)}
                                inputProps={{ 'aria-label': 'checkbox' }}
                              />
                            )}

                            {withMoveToTop && (
                              <div onClick={event => moveToTop(row, event, i)}>
                                <MoveToTopIcon title="Move to top" name="arrow-left" />
                              </div>
                            )}
                          </>
                        )}

                        {children(row, i)}
                      </Flex>
                    </DraggableItem>
                  )}
                </Draggable>
              ))}

              {providedDroppable.placeholder}
            </div>
          </TableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;

const Flex = styled.div`
  display: inline-flex;
  width: 100%;
`;

const TableContainer = styled('div')`
  height: calc(100vh - 9rem);
  overflow-y: scroll;
`;

const DraggableItem = styled.div`
  cursor: ${({ isDragDisabled, isMultiSelectKeyPress, cursor }) =>
    isMultiSelectKeyPress && !isDragDisabled ? 'default' : cursor} !important;
  height: ${({ hide }) => (hide ? '0px' : 'inherit')};
  background-color: ${({ isSelected, withCheckbox }) => (isSelected && !withCheckbox ? sc.subHeadingColor : 'transparent')};
  &:hover {
    background-color: ${({ isSelected, withCheckbox }) => (isSelected && !withCheckbox ? sc.subHeadingColor : sc.greyLighter)};
  }
  border-bottom: ${({ hide }) => (hide ? '0px' : `1px solid ${sc.greyLighter}`)};
  overflow-y: hidden;
`;

const MoveToTopIcon = styled(Icon)`
  transform: rotate(90deg);
  cursor: pointer;
  margin-top: 0.75rem;
  &:hover {
    color: ${sc.primary};
  }
`;
