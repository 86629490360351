import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { DrawerError } from 'app/components';
import { Button, Spinner as _Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';

import useBannerCampaignCreative from '../../common/useBannerCampaignCreative';
import { IconLink } from '../../common/components';
import useStorefrontsCma from '../../common/useStorefrontsCma';
import _BannerSpecSelection from './BannerSpecSelection';
import useStorefrontPermissions from './useStorefrontPermissions';

type Props = {
  className?: string;
};

export default ({ className }: Props) => {
  const { addBannerSpec: _addBannerSpec, bannerSpecs, removeBannerSpec } = useBannerCampaignCreative();
  // TODO: Use storefrontsCma for now. In the future, only use storefrontsBms since they both delegate to Seller Panel
  const { permittedStorefronts, storefronts, storefrontsError, storefrontsLoading } = useStorefrontsCma();
  const { isCampaignEditable, canEditStorefront } = useStorefrontPermissions();

  const addBannerSpec = useCallback(_addBannerSpec, [_addBannerSpec]);

  useEffect(() => {
    if (bannerSpecs.filter(b => !!b).length === 0) {
      addBannerSpec();
    }
  }, [addBannerSpec, bannerSpecs]);

  return (
    <section className={className}>
      {storefrontsError ? (
        <DrawerError error="Error Retrieving Storefronts" errorDetails={storefrontsError} />
      ) : storefrontsLoading ? (
        <Spinner />
      ) : (
        <>
          {bannerSpecs?.map(
            (bannerSpec, i) =>
              !!bannerSpec && (
                <BannerRow key={i}>
                  <BannerSpecSelection
                    bannerSpec={bannerSpec}
                    index={i}
                    permittedStorefronts={permittedStorefronts}
                    storefronts={storefronts}
                    storefrontsError={storefrontsError}
                    storefrontsLoading={storefrontsLoading}
                  />

                  <Delete>
                    <IconLink
                      disabled={!canEditStorefront(bannerSpec?.storefrontId)}
                      name="remove"
                      onClick={() => removeBannerSpec(i)}
                      size="32"
                    />
                  </Delete>
                </BannerRow>
              ),
          )}

          <div>
            <Button disabled={!isCampaignEditable} onClick={addBannerSpec} size="small" type="secondary">
              Add Banner
            </Button>
          </div>
        </>
      )}
    </section>
  );
};

const BannerRow = styled.div`
  display: flex;
`;

const BannerSpecSelection = styled(_BannerSpecSelection)`
  flex-grow: 1;
`;

const Delete = styled.div`
  margin-top: ${sc.gutterSmaller};
`;

const Spinner = styled(_Spinner)`
  min-height: 7rem;
  padding-top: 3rem;
`;
