import { ICampaignType, ICampaignObjective } from 'app/types/typescript/Campaign';

export type GeneralForm = {
  name: string;
  tags: Array<{ id?: number | string; name: string }>;
  categories?: Array<{ id?: number }>;
  campaignPhase: { id?: number | string } | null;
  campaignType?: ICampaignType | null;
  campaignObjective: ICampaignObjective | null;
};

export const initializeGeneralValues = (storedValues: GeneralForm): object => ({
  name: storedValues.name || '',
  tags: storedValues.tags || [],
  categories: storedValues.categories || [],
  campaignPhase: storedValues.campaignPhase || null,
  campaignType: storedValues.campaignType || null,
  campaignObjective: storedValues.campaignObjective || null,
});
