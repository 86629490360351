import * as React from 'react';

import { TableCell } from '../Table/TableCell';
import { TableRow } from '../Table/TableRow';

interface LoadingTableProps {
  columns: object[];
}

export const LoadingTable = ({ columns }: LoadingTableProps) => (
  <TableRow>
    <TableCell colSpan={columns.length}>Loading...</TableCell>
  </TableRow>
);
