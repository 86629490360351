import { createLoadingAction, createErrorAction } from 'app/helpers/DuckHelpers/helpers';
import { GET_FEATURES_LOADING, GET_FEATURES_SUCCESS, GET_FEATURES_FAILURE, SET_REAL_TIME_SCHEMA_DATA } from './types';

const loading = createLoadingAction(GET_FEATURES_LOADING);

const succeedWith = data => ({
  type: GET_FEATURES_SUCCESS,
  payload: { data },
});

const failWith = createErrorAction(GET_FEATURES_FAILURE);

const setRealTimeSchemaData = (data, eventId, eventName, schemaId?) => ({
  type: SET_REAL_TIME_SCHEMA_DATA,
  payload: {
    data,
    eventId,
    eventName,
    schemaId,
  },
});

export { loading, succeedWith, failWith, setRealTimeSchemaData };
