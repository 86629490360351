import React from 'react';
import { toast, ToastPosition } from 'react-toastify';
import { Button } from '@material-ui/core';
import { Undo as UndoIcon } from '@material-ui/icons';

const { error, success, warn, info } = toast;

const config = {
  position: 'top-center' as ToastPosition,
};

const defaultMessage = 'Something went wrong';

const displayToast = (toastType: (message: string, config: object) => void, message: string | object) => {
  if (typeof message === 'object') {
    toastType(defaultMessage, config);
  } else {
    toastType(message || defaultMessage, config);
  }
};

const toastTypes = [error, success, warn, info];
const [displayError, displaySuccess, displayWarning, displayInfo] = toastTypes.map(
  toastType => (message: string | object) => displayToast(toastType, message),
);

interface ToastUndoProps {
  message: string;
  undo: () => void;
}

const ToastUndo: React.FC<ToastUndoProps> = ({ message, undo }) => (
  <div>
    {message}
    <Button
      onClick={() => {
        undo();
      }}
      color="primary"
      style={{ marginLeft: '1rem' }}
    >
      <UndoIcon style={{ marginRight: '0.5rem' }} />
      Undo
    </Button>
  </div>
);

const displayUndo = (message: string, undo?: () => void) => toast.info(<ToastUndo message={message} undo={undo || (() => {})} />, config);

export { displayError, displaySuccess, displayWarning, displayInfo, displayUndo };
