import * as types from './types';
import { ITag, ITemplate } from 'app/types';

const get = () => ({
  type: types.GET_TEMPLATE,
});

const getSuccess = (template: ITemplate) => ({
  type: types.GET_TEMPLATE_SUCCESS,
  payload: { template },
});

const getFail = (error: string, errorDetails?: string) => ({
  type: types.GET_TEMPLATE_FAIL,
  payload: { error, errorDetails },
});

const post = () => ({
  type: types.POST_TEMPLATE,
});

const postSuccess = templateId => ({
  type: types.POST_TEMPLATE_SUCCESS,
  payload: { templateId },
});

const postFail = (error: string, errorDetails?: string) => ({
  type: types.POST_TEMPLATE_FAIL,
  payload: { error, errorDetails },
});

const setError = (error: string, errorDetails?: string) => ({
  type: types.SET_ERROR,
  payload: { error, errorDetails },
});

const clearError = () => ({
  type: types.CLEAR_ERROR,
});

const newTemplate = (params: { id?: number; medium?: string } = {}) => ({
  type: types.NEW_TEMPLATE,
  payload: { id: params.id, medium: params.medium },
});

const setName = (name: string) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { name },
});

const setDescription = (description: string) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { description },
});

const setContent = (content: string) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { content },
});

const setMediumId = (mediumId: number) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { mediumId },
});

const setSubject = (subject: string) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { subject },
});

const setSendFrom = (sendFrom: string) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { sendFrom },
});

const setSendFromName = (sendFromName: string) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { sendFromName },
});

const setReplyTo = (replyTo: string) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { replyTo },
});

const setReplyToName = (replyToName: string) => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { replyToName },
});

const addTag = (tag: ITag) => ({
  type: types.ADD_TAG,
  payload: { tag },
});

const removeTag = (tag: ITag) => ({
  type: types.REMOVE_TAG,
  payload: { tag },
});

export {
  get,
  getSuccess,
  getFail,
  post,
  postSuccess,
  postFail,
  setError,
  clearError,
  newTemplate,
  setName,
  setDescription,
  setContent,
  setMediumId,
  setSubject,
  setSendFrom,
  setSendFromName,
  setReplyTo,
  setReplyToName,
  addTag,
  removeTag,
};
