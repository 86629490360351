import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Badge } from '@material-ui/core';

import { ExpandMore, Group } from '@material-ui/icons';
import { mapIndexed, R } from 'app/helpers/RamdaHelpers/helpers';

import Table from '../Table';
import OrSeparator from '../OrSeparator';

const toAndRuleGroup = R.curry(({ lastIndex, orSeperator, classes }, { columns, rows }, index) => (
  <React.Fragment key={index}>
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>
          <Badge className={classes.badge} badgeContent={rows.length} color="primary">
            <Group />
          </Badge>
          AND Rule Group #{index + 1}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Table columns={columns} rows={rows} />
      </AccordionDetails>
    </Accordion>
    {index !== lastIndex && orSeperator}
  </React.Fragment>
));

interface ReadOnlyOrRuleGroupProps {
  data: object[];
  classes: Record<string, string>;
}

export const ReadOnlyOrRuleGroup = ({ data, classes, ...otherProps }: ReadOnlyOrRuleGroupProps) => {
  const lastIndex = data.length - 1;
  const orSeperator = <OrSeparator classes={classes} />;

  return (
    <div classes={classes.container}>
      {mapIndexed(
        toAndRuleGroup({
          lastIndex,
          orSeperator,
          classes,
          ...otherProps,
        }),

        data,
      )}
    </div>
  );
};
