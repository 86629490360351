import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { updatePayloadContent } from 'app/ducks/campaigns/campaign/TimelineNotificationCreative/actions';
import { notificationLinkValidator } from 'app/helpers/TimelineNotificationHelper/';
import { Input as _Input, TextArea as _TextArea } from 'app/midgarComponents';
import { sc } from 'app/styles';

const Generic = () => {
  const {
    TimelineNotificationCreative: {
      payload: { title, messageLink, backgroundImage },
    },
  } = useSelector(
    ({
      campaigns: {
        campaign: { TimelineNotificationCreative },
      },
    }) => ({ TimelineNotificationCreative }),
  );

  const dispatch = useDispatch();
  const updateLinkValue = ev => dispatch(updatePayloadContent({ [ev.target.name]: ev.target.value.trim() }));
  const updateContentValue = (ev: Record<string, any>) => {
    dispatch(updatePayloadContent({ [ev.target.name]: ev.target.value.replace(/\\n/g, '\n').replace(/\n/g, '\n') }));
  };
  const handleBlur = ev => dispatch(updatePayloadContent({ [ev.target.name]: ev.target.value.trim() }));

  return (
    <>
      <Input
        value={backgroundImage}
        name="backgroundImage"
        id="backgroundImage"
        placeholder="Notification Image Link"
        onChange={updateLinkValue}
      />

      {notificationLinkValidator(messageLink, 'Notification') && (
        <Message>
          <Pill>URL must start with paypay://, https:// or http://</Pill>
        </Message>
      )}

      <Input
        value={messageLink}
        name="messageLink"
        id="messageLink"
        placeholder="Message Link URL - less than 355 characters"
        onChange={updateLinkValue}
        maxLength="355"
      />

      <InputStyled
        value={title}
        name="title"
        id="Message"
        placeholder="Message - less than 300 characters"
        onChange={updateContentValue}
        maxLength="300"
        onBlur={handleBlur}
      />
    </>
  );
};

const Input = styled(_Input)`
  height: auto;
  border-color: ${props => (props.invalid ? sc.danger : sc.greyLighter)};
`;

const InputStyled = styled(_TextArea)`
  white-space: pre-wrap;
  margin-bottom: 10px;
`;

const Pill = styled.span`
  background-color: #eeeeee;
  border: solid 1px #eeeeee;
  color: ${sc.greyDarkest};
  border-radius: 4px;
  display: inline-block;
  justify-self: end;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: justify;
  width: inherit;
`;

const Message = styled.div`
  position: absolute;
  border-color: ${props => (props.invalid ? sc.danger : sc.greyLighter)};
  margin: 4.4rem 0;
  display: flex;
  justify-content: 1rem;
  justify-items: flex-start;
`;

export default Generic;
