import React from 'react';

import { FullTable, StatusIndicator } from 'app/midgarComponents';
import { segmentDownloadPermissions } from 'configs/permissions';
import { ISegment } from 'app/types';

import Download from '../../components/Download';
import Actions from '../Actions';
import { Title, Sub } from './styledComponents';

type IsPermitted = (permission: string[]) => boolean;

const tableColumns = (isPermitted: IsPermitted) => {
  const genTableColumn = (label: string, key: string, customComponent?: unknown, isNumeric?: boolean) => ({
    key,
    label,
    isNumeric,
    customComponent,
  });

  const renderNameColumn = (_, { name, id, ingestState }) => (
    <>
      <Title>{name}</Title>
      <Sub>ID: {id}</Sub>
      <StatusIndicator status={ingestState} />
    </>
  );

  const renderActions = (key, row) => <Actions isPermitted={isPermitted} {...row} />;

  return [
    genTableColumn('Name', 'name', renderNameColumn),
    genTableColumn('Description', 'description'),
    genTableColumn('Creator', 'createdBy'),
    genTableColumn('Tags', 'tags', (key, { tags }) => tags.map(({ name }) => name).join(', ')),
    genTableColumn('Download', 'download', (key, row) => <Download {...row} />),
    {
      ...genTableColumn('Actions', 'actions', renderActions),
    },
  ];
};

const rows = (segments: { id: number }[]) => segments.map((x: { id: number }) => (x.id ? { ...x, to: `/segments/${x.id}` } : x));

const columns = (isPermitted: IsPermitted) =>
  isPermitted(segmentDownloadPermissions) ? tableColumns(isPermitted) : tableColumns(isPermitted).filter(({ key }) => key !== 'download');

type Props = {
  segments: Array<ISegment>;
  isPermitted: (val: any) => boolean;
  handleRowClick: (...args: Array<any>) => any;
  isLoading: boolean;
  msgIfEmpty?: string;
};

export const SegmentTable = ({ segments, isPermitted, handleRowClick, isLoading, msgIfEmpty }: Props) => (
  <FullTable
    rows={rows(segments)}
    rowEditable={false}
    rowsLoading={isLoading}
    columns={columns(isPermitted)}
    handleRowClick={handleRowClick}
    msgIfEmpty={msgIfEmpty}
  />
);
