import { IUserBudgetToken } from 'app/features/CashbackPromoManagement/UserBudgetTokens/types';

import { ADD, UPDATE, GET_INIT, GET_SUCCESS, GET_ERROR } from './types';

export const add = (userBudgetToken: IUserBudgetToken) => ({
  type: ADD,
  payload: { userBudgetToken },
});

export const getError = (error: Error) => ({
  type: GET_ERROR,
  payload: error,
});

export const getInit = () => ({
  type: GET_INIT,
});

export const getSuccess = (userBudgetTokens: Array<IUserBudgetToken>) => ({
  type: GET_SUCCESS,
  payload: { userBudgetTokens },
});

export const update = (userBudgetToken: IUserBudgetToken) => ({
  type: UPDATE,
  payload: { userBudgetToken },
});
