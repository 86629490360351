import React from 'react';
import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import moment from 'moment';
import { isValidNumber } from 'ramda-adjunct';
import { TextField } from '@material-ui/core';

import { addComponentPropsIf } from 'app/helpers/ComponentHelpers/helpers';
import { joinWithSpace } from 'app/helpers/RamdaHelpers/helpers';
import Tooltip from '../Tooltip';

const dateFormat = window.location.pathname.includes('/audience/') ? 'YYYY-MM-DD' : 'MM/DD/YYYY';
const timeFormat = 'HH:mm:ss';

const defaultValue = moment('00:00:00', timeFormat);
const timePicker = <TimePickerPanel defaultValue={defaultValue} showSecond={true} />;

const dateTimeFormat = withTime => (withTime ? joinWithSpace([dateFormat, timeFormat]) : dateFormat);

const unixTime = (value, timeZone) => moment.unix(value).tz(timeZone);

const toMoment = (value, timeZone) => (isValidNumber(value) ? unixTime(value, timeZone) : undefined);
// : moment();

const formatMoment = (value, timeZone, withTime) =>
  isValidNumber(value) ? unixTime(value, timeZone).format(dateTimeFormat(withTime)) : '';

const calendar = withTimePicker => {
  const format = dateTimeFormat(withTimePicker);

  return (
    <Calendar
      dateInputPlaceholder={format}
      formatter={format}
      {...addComponentPropsIf(withTimePicker, {
        timePicker,
      })}
    />
  );
};

interface RuleDateTimePickerProps {
  id: string;
  label: string;
  value?: number;
  timeZone: string;
  onChange(...args: unknown[]): unknown;
  withTimePicker?: boolean;
  classes: Record<string, string>;
}

export const RuleDateTimePicker = ({ id, label, value, timeZone, onChange, withTimePicker = false, classes }: RuleDateTimePickerProps) => {
  const timeString = formatMoment(value, timeZone, withTimePicker);

  return (
    <DatePicker animation="slide-up" calendar={calendar(withTimePicker)} value={toMoment(value, timeZone)} onChange={onChange}>
      {() => (
        <Tooltip title={timeString}>
          <TextField
            required
            margin="dense"
            className={classes.textField}
            id={id}
            label={label}
            InputProps={{
              readOnly: true,
              style: { width: '15rem' },
            }}
            value={timeString}
          />
        </Tooltip>
      )}
    </DatePicker>
  );
};
