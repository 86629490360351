import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { sc } from 'app/styles';
import FieldError from '../FieldError';

type Props = {
  id?: string;
  name: string;
  label: string;
  type?: string;
  value?: string | number;
  onChange?: (ev: React.SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (ev: React.SyntheticEvent<HTMLInputElement>) => void;
  required?: boolean;
  error?: boolean | string | Record<string, string>;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
};

type State = {
  active: boolean;
  focused: boolean;
};

export default class TextField extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      active: !!props.value || props.type === 'time' || props.type === 'date',
      focused: false,
    };
  }

  componentDidUpdate() {
    const { active } = this.state;
    const { value } = this.props;

    if (!active && value) {
      this.setState({ active: true }); // eslint-disable-line
    }
  }

  onChange = (ev: React.SyntheticEvent<HTMLInputElement, Event>) => {
    const { disabled, onChange } = this.props;

    if (!disabled) {
      onChange?.(ev);
    }
  };

  onFocus = () => {
    const { disabled } = this.props;

    if (!disabled) {
      this.setState({ active: true, focused: true });
    }
  };

  onBlur = (ev: React.SyntheticEvent<HTMLInputElement, Event>) => {
    const { value, type, onBlur } = this.props;

    if (!value || !value.length) {
      return this.setState({
        active: type === 'time' || type === 'date',
        focused: false,
      });
    }

    if (onBlur) {
      onBlur(ev);
    }

    return this.setState({ focused: false });
  };

  render() {
    const { active, focused } = this.state;
    const { id, name, label, placeholder, required, value, className, error, disabled, dispatch, autocomplete, ...rest } = this.props;

    return (
      <>
        <Container className={className} $focused={focused} $error={!!error} disabled={disabled}>
          <InnerInput
            id={id}
            type="text"
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={this.onChange}
            required={!!required}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            data-qa={`text-field-${name}`}
            {...rest}
          />

          <Label className="ci-text-field-label" required={!!required} $active={active} $hasPlaceholder={!!placeholder}>
            {label}
          </Label>
        </Container>
        <FieldError error={error} />
      </>
    );
  }
}

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 55px;
  border: 1px solid ${props => (props.$focused ? sc.primary : props.$error ? sc.danger : sc.sectionBorderColor)};
  transition: border 150ms ease-in-out;

  opacity: ${props => (props.disabled ? 0.8 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const InnerInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  padding: ${sc.gutter} ${sc.gutterSmall} ${sc.gutterSmallest};
  font-size: ${sc.fontSizeSmall};
  font-weight: bold;
  &:hover::-webkit-search-cancel-button {
    display: none;
  }
  &:focus::-webkit-search-cancel-button {
    display: none;
  }
  &::placeholder {
    color: ${sc.greyLight};
    font-weight: 200;
  }
`;

const Label = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: ${sc.gutterSmall};

  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmall};
  transform: translate(0, -50%);
  transition: all 100ms ease-out;
  pointer-events: none;

  ${props =>
    props.required
      ? css`
          &:after {
            content: ' *';
          }
        `
      : ''}

  ${props =>
    props.$active || props.$hasPlaceholder
      ? css`
          top: 30%;
          font-size: ${sc.fontSizeSmaller};
        `
      : ''}
`;
