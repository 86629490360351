import bmsVersions from 'app/api/bmsCommon/bmsVersions';
import { findChannelSetup } from 'app/ducks/configStore/utils';
import { IAppConfig } from 'configs/apps/types';
import { channelNameBanner } from 'configs/channels/constants';
import { IBanner } from 'configs/channels/types';

export const LEVEL_CREATIVE = 'creative' as IBannerCreativeConfig['widgetType']['level'];
export const LEVEL_VARIANT = 'variant' as IBannerCreativeConfig['widgetType']['level'];

export type IBannerCreativeConfig = {
  /**
   * BMS API version
   * 1 = Legacy endpoints (For India. BMS proxies to Seller Panel in some cases)
   * 2 = New endpoints (For all non-India tenants. Does not use Seller Panel)
   */
  bmsVersion?: '1' | '2';

  /**
   * Use the ACL table for granting permissions to users by storefront.
   * A table in DynamoDB may be used to grant access to a storefront for multiple users as an alternative to granting permissions
   * to individual users. This makes it easier to grant access to newly-created storefronts to many users.
   */
  useAclService?: boolean;

  /**
   * Allow an optional alternate (second) image to be uploaded for a creative variant.
   * True = Always allow
   * widgetTypes = Array of widget types for which an alternate image is allowed
   */
  useAlternateImage?: boolean | { widgetTypes: Array<string> };

  /** Use app version filters on each creative variant */
  useAppVersion?: boolean;

  /** Use the Autopilot (Cocoa) service */
  useAutopilot?: boolean;

  /** Allow bulk deletion of banners across all slots of a view by uploading a CSV file of banner IDs */
  useBulkDelete?: boolean;

  /** Allow bulk moving of banners from one slot to another */
  useBulkMove?: boolean;

  /**
   * Specify the Google Analytics category on a creative.
   * This provides a category for the purpose of mesurements.
   */
  useGACategory?: boolean;

  /** Use location filters on each creative variant */
  useLocations?: boolean;

  /**
   * Provide a filter to display only views which are managed by Engage.
   * Needed if Seller Panel is used.
   */
  useManagedByEngageFilter?: boolean;

  /** Allow multiple variants for a creative */
  useMultipleVariants?: boolean;

  /** Allow personalization to be toggled on/off */
  usePersonalization?: boolean;

  /** Indicates whether views support Platform Version field */
  usePlatformVersion?: boolean;

  /** Allow a sales date range to be specified on a banner */
  useSaleDates?: boolean;

  /**
   * (Deprecated)
   * Indicates whether the seller panel is used (only used by India)
   *
   * If True:
   *  - View uses personalization (overlaps with usePersonalization setting), status
   *  - Storefront uses URL, status, is_primary
   *  - Calls a different BMS endpoint for fetching a view by ID
   */
  useSellerPanel?: boolean;

  /** Allow a creative to be shared */
  useShareable?: boolean;

  /** Allow creation of storefronts in the UI */
  useStorefrontCreate?: boolean;

  /** Allow deletion of storefronts in the UI */
  useStorefrontDelete?: boolean;

  /** Allow editing of storefronts in the UI */
  useStorefrontUpdate?: boolean;

  /**
   * Indicates whether to support storefront types, i.e. entity_type
   * This is used for India, where seller panel supports two storefront types: merchant and consumer.
   */
  useStorefrontType?: boolean;

  /** Allow tags to be specified on a creative */
  useTags?: boolean;

  /** Allow a title and subtitle to be specified on a creative */
  useTitles?: boolean;

  /**
   * Allow a Variant Type to be specified on a creative variant.
   * This provides a category for the purpose of mesurements.
   */
  useVariantType?: boolean;

  /** Allow metadata to be configured for a view */
  useViewConfig?: boolean;

  /** Allow creation of views in the UI */
  useViewCreate?: boolean;

  /** Allow editing of views in the UI */
  useViewUpdate?: boolean;

  /**
   * Indicates where the widget type will be shown.
   * It can be shown either on the creative, or on each variant.
   * The variant type (if enabled) will be shown at the same level as the widget type.
   */
  widgetType?: {
    level: 'creative' | 'variant';
  };
};

export type IBannerConfig = {
  name: IBanner;
  creative?: IBannerCreativeConfig;
};

const defaultConfig = {
  bmsVersion: bmsVersions.DEFAULT,
  widgetType: {
    level: LEVEL_CREATIVE,
  },
};

export const getBannerCreativeConfig = (appConfig: IAppConfig): IBannerCreativeConfig => {
  const channelSetup = findChannelSetup(appConfig, channelNameBanner);
  if (!channelSetup) {
    return defaultConfig;
  }

  return {
    ...defaultConfig,
    ...(channelSetup.creative || {}),
  };
};
