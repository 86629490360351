import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import withAppConfig from 'app/decorators/withAppConfig';

import { ConnectedUser } from './ducks';

const PATH_UNAUTHORIZED = '/unauthorized';
const PATH_NOT_SUPPORTED = '/not-supported';

class ProtectedRoute extends React.PureComponent {
  isSupported = path => {
    const {
      appConfig: { supportedRoutes },
    } = this.props;
    return supportedRoutes.includes(path);
  };

  render() {
    const { requiredPermissions, appConfig, redirect = PATH_UNAUTHORIZED, path, component: Component, ...rest } = this.props;
    return (
      <ConnectedUser>
        {({ isPermitted }) => (
          <Route
            {...rest}
            render={({ location, ...props }) =>
              (requiredPermissions.length === 0 || isPermitted(requiredPermissions)) && this.isSupported(path) ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: !this.isSupported(path) ? PATH_NOT_SUPPORTED : redirect,
                    state: { from: location },
                  }}
                />
              )
            }
          />
        )}
      </ConnectedUser>
    );
  }
}

ProtectedRoute = withAppConfig(ProtectedRoute);

export { ProtectedRoute };
