import * as React from 'react';
import { branch, renderComponent } from 'recompose';

import Button from 'app/midgarComponents/Button';
import { TableCell } from '../Table/TableCell';

interface EditActionProps {
  onEdit(...args: unknown[]): unknown;
}

const EditAction = ({ onEdit }: EditActionProps) => (
  <TableCell>
    <Button onClick={onEdit}>Edit</Button>
  </TableCell>
);

interface SaveActionsProps {
  onCancel(...args: unknown[]): unknown;
  onSave(...args: unknown[]): unknown;
}

const SaveActions = ({ onSave, onCancel }: SaveActionsProps) => (
  <TableCell>
    <Button onClick={onSave}>Save</Button>
    <Button onClick={onCancel}>Cancel</Button>
  </TableCell>
);

export const EditActions = branch(({ editState }) => editState, renderComponent(SaveActions))(EditAction);
