import { mapObjIndexed, values } from 'ramda';

import { ISegment } from 'app/types';
import { assertions, humanized } from 'app/features/Segments/New/validation.config';

const assertionsOf = audienceType => assertions[audienceType || ''];

const checkersOf = audienceType =>
  values(
    mapObjIndexed(
      (predicate, key) => input => [predicate(input[key]), `${humanized[key]} is missing or invalid.`],
      assertionsOf(audienceType),
    ),
  );

const noError = [true, ''];

const recurTestPass = (target, [result, cause], [validate, ...tail]) =>
  result ? (validate ? recurTestPass(target, validate(target), tail) : noError) : [result, cause];

const validate = (segment: ISegment, audienceType: string) => {
  const [valid, validationError] = recurTestPass(segment, noError, checkersOf(audienceType));
  return { valid, validationError };
};

export { validate };
