import React, { memo, type JSX } from 'react';
import styled from 'styled-components';
import { Pills } from 'app/midgarComponents';
import { FieldArray, useFormikContext } from 'formik';
import { segmentTypesDisplayValue, segmentTypesColorCodes } from 'app/features/Segments2/utils';
import { addIdToName } from 'app/utilities/addIdToName';
import { AudiencesFormikProps } from '../../../../../../utilities/types/formik';
import { Segments } from '../../../../../../types/typescript/Segments';

type Props = {
  id: string;
  name: 'includedSegments' | 'excludedSegments' | 'includedSegmentsFilters' | 'excludedSegmentsFilters' | 'includedSegmentsGeoFilters';
  label: string;
  required?: boolean;
  selected: Segments;
  segments: Segments;
  segmentsFetching: boolean;
  onSelect?: Function;
  onRemove?: Function;
  fetchSegments: Function;
  className?: string;
};
const Audience = (props: Props): JSX.Element => {
  const [value, setValue] = React.useState('');

  const onFocus = (): void => {
    const { segments, fetchSegments } = props;
    if (!segments || !segments.length) {
      fetchSegments(value);
    }
  };

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    const { fetchSegments } = props;
    setValue(ev.target.value);
    fetchSegments(ev.target.value);
  };

  const { id, name, label, required, selected, segments: rawSegments, segmentsFetching, onSelect, onRemove, className } = props;

  const segments = rawSegments.map(addIdToName);

  const parentFormikContext = useFormikContext<AudiencesFormikProps>();

  const baseComponentProps = {
    id,
    name,
    required,
    label,
    limit: 25,
    suggestions: segments,
    loading: segmentsFetching,
    value,
    onFocus,
    onChange,
    suggestionTagTypeMapper: segmentTypesDisplayValue,
    suggestionTagTypeColors: segmentTypesColorCodes,
  };
  const renderAudiences = (): JSX.Element => {
    if (parentFormikContext) {
      const { values, touched, errors } = parentFormikContext;
      const selectAudienceSegment = (segment: object, push: Function): void => {
        push(segment);
      };

      const removeAudienceSegment = (segment: { id: string | number }, remove: Function): void => {
        const foundIndex = values[name].findIndex(({ id }: { id: string | number }) => id === segment.id);
        remove(foundIndex);
      };
      return (
        <FieldArray name={name}>
          {({ push, remove }): JSX.Element => (
            <Pills
              {...baseComponentProps}
              onFocus={onFocus}
              error={touched[name] && errors[name]}
              selected={values[name]}
              onSelect={(segment: object): Function | void => selectAudienceSegment(segment, push)}
              onRemove={(segment: { id: string | number }): Function | void => removeAudienceSegment(segment, remove)}
            />
          )}
        </FieldArray>
      );
    } else
      return <Pills {...baseComponentProps} selected={selected} value={value} onFocus={onFocus} onSelect={onSelect} onRemove={onRemove} />;
  };
  return (
    <Container className={className} data-qa={`audiences-component-${name}`}>
      {renderAudiences()}
    </Container>
  );
};

export default memo(Audience);

const Container = styled.section`
  display: inline-block;
  vertical-align: top;
  margin: 1rem 1rem 1rem 0;

  width: 40.5%;
  min-width: 20rem;

  &:last-child {
    margin-right: 0;
  }
`;
