import * as React from 'react';
import { Button, Divider, Accordion, AccordionActions, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { AccordionProps } from '@material-ui/core/Accordion/Accordion';
import { ReactElement, ReactNode } from 'react';

export interface DrawerErrorProps extends Omit<AccordionProps, 'children'> {
  error: string | ReactElement;
  errorDetails?: string;
  children?: ReactNode;
  handleDismiss?: () => void;
  classes?: {
    root?: string;
    summary?: string;
    details?: string;
    detailsPre?: string;
    divider?: string;
  };
}

export const DrawerError = ({ error, errorDetails, handleDismiss, children, classes = {}, ...props }: DrawerErrorProps) => (
  <Accordion className={classes.root} {...props}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6" className={classes.summary}>
        {error}
      </Typography>
    </AccordionSummary>

    <AccordionDetails>
      <section className={classes.details}>
        {children}
        <pre className={classes.detailsPre}>{errorDetails}</pre>
      </section>
    </AccordionDetails>

    {!!handleDismiss && (
      <section>
        <Divider classes={{ root: classes.divider }} />

        <AccordionActions>
          <Button data-qa="dismiss-button" size="small" onClick={handleDismiss}>
            Dismiss Error
          </Button>
        </AccordionActions>
      </section>
    )}
  </Accordion>
);
