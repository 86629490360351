import React from 'react';

import { FullTable } from 'app/midgarComponents';
import { segmentDownloadPermissions } from 'configs/permissions';
import { ISegment } from 'app/types';

import Download from '../../components/Download';
import Actions from '../Actions';
import { Title } from './styledComponents';

const tableColumns = isPermitted => {
  const genTableColumn = (label: string, key: string, customComponent?: unknown, isNumeric?: boolean) => ({
    key,
    label,
    isNumeric,
    customComponent,
  });

  const renderNameColumn = (_, { name }) => (
    <>
      <Title>{name}</Title>
      {/* <Sub>ID: {id}</Sub>
      <StatusIndicator status={ingestState} /> */}
    </>
  );

  const renderActions = (key, row) => <Actions isPermitted={isPermitted} {...row} />;

  return [
    genTableColumn('Name', 'name', renderNameColumn),
    genTableColumn('Description', 'description'),
    genTableColumn('Audience Type', 'audienceType'),
    genTableColumn('Tags', 'tags', (key, { tags }) => (tags ? tags.map(({ name }) => name).join(', ') : '')),
    genTableColumn('Download', 'membershipCsvFileUri', (key, row) => <Download {...row} />),
    {
      ...genTableColumn('Actions', 'actions', renderActions),
    },
  ];
};

const rows = audiences => {
  return audiences.map(x => (x.audienceId ? { ...x, to: `/audience/${x.audienceId}`, id: x.audienceId } : x));
};

const columns = isPermitted =>
  isPermitted(segmentDownloadPermissions) ? tableColumns(isPermitted) : tableColumns(isPermitted).filter(({ key }) => key !== 'download');

type Props = {
  audiences: Array<ISegment>;
  isPermitted: (val: any) => boolean;
  handleRowClick: (...args: Array<any>) => any;
  isLoading: boolean;
  msgIfEmpty?: string;
};

export const SegmentTable = ({ audiences, isPermitted, handleRowClick, isLoading, msgIfEmpty }: Props) => {
  return (
    <FullTable
      rows={rows(audiences)}
      rowEditable={false}
      rowsLoading={isLoading}
      columns={columns(isPermitted)}
      handleRowClick={handleRowClick}
      msgIfEmpty={msgIfEmpty}
    />
  );
};
