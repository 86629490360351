import { displayError } from 'app/helpers/NotificationHelpers/helpers';

import * as api from 'app/api/debug';

import * as actions from './actions';

const API_VERSION = 'v2';

export const fetchUpcomingBanners =
  (viewId, userId, params = {}) =>
  async dispatch => {
    if (!viewId || !userId) {
      return null;
    }

    const defaultParams = {
      customer_id: userId,
    };

    dispatch(actions.searchStart());

    try {
      const { banners: bannerIds, debug: bannerDebugs } = await api.fetchUpcomingBanners(
        viewId,
        userId,
        Object.assign(defaultParams, params),
        { apiVersion: API_VERSION },
      );

      return dispatch(actions.searchSuccess(bannerIds, bannerDebugs));
    } catch (error) {
      displayError(`Error retrieving debug banners: ${error}`);
      return dispatch(actions.searchError(error));
    }
  };
