import React from 'react';

import { OrRuleGroup, SegmentCount } from 'app/components';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { TextField, DateTimeUtcField } from 'app/midgarComponents';
import styled from 'styled-components';
import { sc } from 'app/styles';

const onCalcuate = (validateRules, calculate) => () => {
  const { isValid, errorMessage, rules } = validateRules();

  if (isValid) calculate(rules);
  else displayError(errorMessage);

  return isValid;
};

interface RulesProps {
  rules: object[];
  errors?: object[];
  pathDict: Record<string, unknown[]>;
  originDict: Record<string, object>;
  pathsOfFeature(...args: unknown[]): unknown;
  optionsByPathKey(...args: unknown[]): unknown;
  newRowValue: object[];
  initialRuleForGroup: {
    key: number;
    value: object[];
  };
  updateState(...args: unknown[]): unknown;
  audience: Record<string, string>;
  validateRules(...args: unknown[]): unknown;
  countOfQuery(...args: unknown[]): unknown;
  classes: Record<string, string>;
}

export const Rules = ({
  rules,
  errors = [],
  pathDict,
  originDict,
  pathsOfFeature,
  optionsByPathKey,
  newRowValue,
  initialRuleForGroup,
  handleExtractionDetails,
  updateState,
  validateRules,
  countOfQuery,
  classes,
  audience,
}: RulesProps) => (
  <section className={classes.customContainer}>
    <SegmentCount calculate={onCalcuate(validateRules, countOfQuery)} classes={classes} calcOnLoad={false} />
    <Row>
      <CoreFields>
        <Row>
          <TextField
            label="Extraction Interval"
            name="extractionIntervalInHours"
            type={'number'}
            value={audience.extractionIntervalInHours || ''}
            onChange={({ target: { value } }) => handleExtractionDetails({ extractionIntervalInHours: value > 0 ? value : 1 })}
            required
          />
        </Row>

        <Row>
          <DateTimeUtcField
            id="firstExtractionAt"
            label="First Extraction"
            name="firstExtractionAt"
            onChange={value => handleExtractionDetails({ firstExtractionAt: value })}
            required
            value={audience.firstExtractionAt || ''}
          />

          <DateTimeUtcField
            id="willBeUpdatedUntil"
            label="Extraction Ends"
            name="willBeUpdatedUntil"
            onChange={value => handleExtractionDetails({ willBeUpdatedUntil: value })}
            required
            value={audience.willBeUpdatedUntil || ''}
          />
        </Row>
      </CoreFields>
    </Row>
    <OrRuleGroup
      data={rules}
      newItem={newRowValue}
      {...{
        errors,
        pathDict,
        originDict,
        pathsOfFeature,
        optionsByPathKey,
        initialRuleForGroup,
        updateState,
      }}
    />
  </section>
);

const Row = styled.section`
  display: flex;
  margin-top: ${sc.gutterSmaller};
  & > div,
  & > section {
    width: 100%;
    margin-right: ${sc.gutterSmall};
  }
  & > div:last-child,
  & > section:last-child {
    margin-right: 0;
  }
`;

const CoreFields = styled.section`
  flex-grow: 1;
  margin-bottom: -${sc.gutterSmaller};
`;
