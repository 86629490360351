import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FieldArray, useFormikContext } from 'formik';
import { Pills, Button } from 'app/midgarComponents';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import * as TagsOperations from 'app/ducks/tags/operations';
import { sc } from 'app/styles';
import { GeneralFormikProps } from '../../utilities/types/formik';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import type { JSX } from 'react';

type Tag = {
  id?: number | string;
  name: string;
};

type V2Tag = {
  name: string;
};

type Props = {
  tags?: Array<Tag>;
  loading?: boolean;
  selected: Array<Tag>;
  size: string | number;
  addTag: (tag: Tag) => void;
  removeTag: (tag: Tag) => void;
};

const Tags = (props: Props): JSX.Element => {
  const path = useLocation();
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch();
  const { tags } = useSelector(
    (state: { tags: { allIds: Array<number>; byId: Array<Tag>; loading: boolean; allNamesV2: Array<V2Tag> } }) => state,
  );
  const mappedTags = React.useMemo(
    () => (Array.isArray(tags.allIds) ? tags.allIds.map((id): Tag => tags.byId[id]) : []),
    [tags.allIds, tags.byId],
  );
  const uniqueNames = React.useMemo(() => {
    const namesSet = new Set();
    const uniqueNamesArray = [];
    for (const item of tags.allNamesV2 ?? []) {
      if (!namesSet.has(item.name)) {
        namesSet.add(item.name);
        uniqueNamesArray.push(item);
      }
    }
    return uniqueNamesArray;
  }, [tags.allNamesV2]);

  const getAllTags = React.useCallback(() => {
    if (path.pathname === '/audience/new') {
      TagsOperations.getV2Tags({ page: 0, size: 1000, sortOrder: 'asc', sortBy: 'name' })(dispatch);
    } else {
      TagsOperations.getAllTags()(dispatch);
    }
  }, [dispatch, path.pathname]);

  React.useEffect(() => {
    getAllTags();
  }, [getAllTags]);

  const parentFormikContext = useFormikContext<GeneralFormikProps>();

  const addNewTag = (tag?: Tag, formikFunctions: { push?: Function; remove?: Function } = {}, eventSource?: string): void | null => {
    if ((!tag || !tag.name) && !value) {
      return null;
    }

    const resolvedValue = eventSource === 'listClick' || !value.length ? tag?.name : value;
    const { addTag, removeTag, selected } = props;

    const existingTag =
      path.pathname === '/audience/new'
        ? uniqueNames.find(({ name }) => name === resolvedValue)
        : mappedTags.find(({ name }) => name === resolvedValue);
    if (existingTag) {
      if (selected.find(tag => tag.name === existingTag?.name) || formikFunctions.push) {
        toast.error('Tag is already added');
        // Prevent adding a duplicate tag or using redux ahead of formik submit.
        // New tags are created as they are added, so all selected tags already have an ID
        return null;
      }

      return addTag(existingTag);
    }

    if (path.pathname !== '/audience/new') {
      const newTag: Tag = { id: -1, name: resolvedValue?.trim() || '' };

      if (Object.keys(formikFunctions).length) {
        const { push = (): null => null, remove = (): null => null } = formikFunctions;

        return TagsOperations.postTag(newTag)(dispatch)
          .then((tag: Tag) => {
            return push(tag);
          })
          .catch((err: Error) => {
            displayError(`Failed while creating tag: ${err}`);
            return remove(newTag);
          });
      } else {
        addTag(newTag);

        return TagsOperations.postTag(newTag)(dispatch)
          .then((tag: Tag) => {
            addTag(tag);
            return removeTag(newTag);
          })
          .catch((err: Error) => {
            displayError(`Failed while creating tag: ${err}`);
            return removeTag(newTag);
          });
      }
    }
  };

  const onEnterTag = (tag?: string): void | null => {
    const { addTag } = props;

    if (!tag || tag.length === 0) {
      return null;
    }

    const tagExists = props.selected.some(obj => obj.name === tag);
    if (tagExists) {
      toast.error(`Tag ${tag} is already added`);
    } else {
      const newTagObject = {
        name: tag,
      };
      addTag(newTagObject);
    }
  };

  const renderTags = (value: string): JSX.Element => {
    const { selected, removeTag } = props;
    const baseComponentProps = {
      id: 'tags',
      name: 'tags',
      label: 'Tags',
      suggestions: path.pathname === '/audience/new' ? uniqueNames : mappedTags,
      loading: tags.loading,
      limit: 10,
      value,
      required: true,
      showShowMore: true,
      onChange: ({ target }: Event): void => setValue((target as HTMLSelectElement)?.value),
    };

    if (!parentFormikContext)
      return (
        <>
          {path.pathname === '/audience/new' ? (
            <StyledPills
              {...baseComponentProps}
              selected={selected}
              onSelect={(tag: Tag): void | null => addNewTag(tag, undefined, 'listClick')}
              onRemove={(tag: Tag): void | null => removeTag(tag)}
              onEnter={(tag: string): void | null => {
                onEnterTag(tag);
                setValue('');
              }}
            />
          ) : (
            <>
              <StyledPills
                {...baseComponentProps}
                selected={selected}
                onSelect={(tag: Tag): void | null => addNewTag(tag, undefined, 'listClick')}
                onRemove={removeTag}
              />
              <StyledButton onClick={addNewTag} disabled={!value || !value.length}>
                Add Tag
              </StyledButton>
            </>
          )}
        </>
      );
    else {
      const { values, setFieldTouched, touched, errors } = parentFormikContext;

      const addSelectedTag = (tag: Tag, push: Function): void => {
        push(tag);
        setFieldTouched('tags');
      };

      const removeSelectedTag = (tag: Tag, remove: Function): void => {
        const foundIndex = values.tags.findIndex(({ id }) => id === tag?.id);
        remove(foundIndex);
        setFieldTouched('tags');
      };

      return (
        <FieldArray name="tags">
          {({ push, remove }): JSX.Element => (
            <>
              <StyledPills
                {...baseComponentProps}
                error={touched.tags && errors.tags}
                selected={values.tags}
                onSelect={(tag: Tag): void => addSelectedTag(tag, push)}
                onRemove={(tag: Tag): void => removeSelectedTag(tag, remove)}
              />
              <StyledButton
                onClick={(e: Event): void | null => {
                  e.preventDefault();
                  addNewTag(undefined, { push, remove });
                }}
                disabled={!value || !value.length}
              >
                Add Tag
              </StyledButton>
            </>
          )}
        </FieldArray>
      );
    }
  };

  return (
    <Container size={props.size as number} data-qa="tags-component">
      {renderTags(value)}
    </Container>
  );
};

export default Tags;

interface ContainerProps {
  size: number;
}

const Container = styled.section<ContainerProps>`
  width: ${(props): number => props.size}%;
  position: relative;
  margin-top: ${sc.gutter};
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0.5rem;
`;

const StyledPills = styled(Pills)`
  width: 80%;
`;
