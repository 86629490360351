import * as Yup from 'yup';

const campaignTargetSchema = (context?: {
  maxAudienceRequired?: boolean;
  exclusionCampaign?: boolean;
  versionChecked?: boolean;
  useEveryoneAudience?: boolean;
}): {
  validate: (value: any) => Promise<any>;
} =>
  Yup.lazy((value: any) => {
    return Yup.object().shape({
      includedSegments:
        context?.versionChecked || value.useEveryoneAudience === true || context?.exclusionCampaign
          ? Yup.mixed().notRequired()
          : Yup.array()
              .of(
                Yup.object({
                  id: Yup.string().required('An Audience requires an ID property'),
                  name: Yup.string().required('An Audience requires a name property'),
                }),
              )
              .required()
              .min(1, 'At least 1 Audience must be selected'),
      excludedSegments:
        (context?.exclusionCampaign || value.exclusionCampaign) && !context?.versionChecked && !value.useEveryoneAudience
          ? Yup.array()
              .of(
                Yup.object({
                  id: Yup.string().required('An Audience requires an ID property'),
                  name: Yup.string().required('An Audience requires a name property'),
                }),
              )
              .required()
              .min(1, 'At least 1 Audience must be selected')
          : Yup.array().of(
              Yup.object({
                id: Yup.string().required('An Audience requires an ID property'),
                name: Yup.string().required('An Audience requires a name property'),
              }),
            ),
      includedSegmentsFilters: Yup.array().of(
        Yup.object({
          id: Yup.number().required('An Audience requires an ID property'),
          name: Yup.string().required('An Audience requires a name property'),
        }),
      ),
      audienceId:
        !context?.versionChecked || value.useEveryoneAudience === true
          ? Yup.mixed().notRequired()
          : Yup.array()
              .of(
                Yup.object({
                  id: Yup.string().required('An Audience requires an ID property'),
                  name: Yup.string().required('An Audience requires a name property'),
                }),
              )
              .required()
              .min(1, 'Audience must be selected'),
      excludedSegmentsFilters: Yup.array().of(
        Yup.object({
          id: Yup.number().required('An Audience requires an ID property'),
          name: Yup.string().required('An Audience requires a name property'),
        }),
      ),
      customerCountPerExecution:
        !context?.versionChecked && context?.maxAudienceRequired && !value.useEveryoneAudience && !context?.exclusionCampaign
          ? Yup.number().required('Max audience is required')
          : Yup.number(),
      audienceFiltersEnabled: Yup.boolean(),
      exclusionCampaign: Yup.boolean(),
    });
  });

export { campaignTargetSchema };
