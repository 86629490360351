import React, { PureComponent } from 'react';
import { prop } from 'ramda';
import moment from 'moment';

import { HTMLPreview } from 'app/components/HTMLPreview/HTMLPreview';
// eslint-disable-next-line import/no-cycle
import TestDelivery from 'app/components/TestDelivery';

import { highlightCriteria } from 'app/features/Campaigns/utils';
import { withAppConfig } from 'app/decorators';
import { ConnectedEmail } from 'app/ducks';
import { transformCampaignVariables } from 'app/ducks/campaigns/campaign/helpers';
import { Grid } from 'app/midgarComponents';
import { prettifyPredicates } from 'app/midgarComponents/PredicateBuilder/helper';
import { triggerDisplayValues } from 'app/utilities/constants';
import { IAppConfig } from 'configs/apps/types';
// eslint-disable-next-line import/no-cycle
import { getEmailCreativeConfig } from 'configs/apps/creatives/email';
// eslint-disable-next-line import/no-cycle
import { channelNameEmail } from 'configs/channels/constants';

import {
  MainPage,
  Header,
  Name,
  Details,
  LeftColumn,
  RightColumn,
  Label,
  Text,
  Section,
} from '../../Campaigns/Overview/common/ChannelDetailsComponents';

import RecurringOverview from '../../Channels/RecurringOverview';
import { ChannelStatus } from '../../../components/ChannelStatus/ChannelStatus';

const formatDate = (date: moment.MomentInput) => (date === 'Not set' ? 'Not set' : moment(date).format("Do MMM'YY"));
const formatTime = (date: moment.MomentInput) => (date === 'Not set' ? 'Not set' : moment(date).format('hh : mm a'));

const printLabelText = (label: string, text: string, highlight?: boolean) => (
  <Section $highlight={highlight}>
    <Label>{label}</Label>
    <Text>{text}</Text>
  </Section>
);

class Overview extends PureComponent<{
  appConfig: IAppConfig;
  state: string;
  triggerType: string;
  quotaPeriodInDays: number;
  cron: string;
  days: Array<string>;
  scheduling: Partial<{
    expiryDate: string;
    startDate: string;
    startTime: string;
    validDays: string;
    miniumInterval: string;
    maximumLimit: string;
  }>;

  variables: Array<{
    id: number;
    name: string;
    defaultValue: string;
  }>;

  testDelivery: (...args: Array<any>) => any;
  dedupeStrategy: string;
  criteria: Partial<{
    predicateFilter: string;
    eventName: string;
    predicateFilterObject: Array<any>;
    delayTime: null | { value: number; unit: string };
  }>;

  originalCampaign?: Record<string, any>;
  startDate: string;
  startTime: string;
  expiryDate: string;
}> {
  static defaultProps = {
    scheduling: {
      expiryDate: '',
      startDate: '',
      startTime: '',
      validDays: '',
      miniumInterval: '',
      maximumLimit: '',
    },

    variables: [],
  };

  render() {
    const {
      appConfig,
      dedupeStrategy,
      scheduling,
      startDate,
      startTime,
      expiryDate,
      state,
      testDelivery,
      triggerType,
      variables,
      criteria,
      originalCampaign,
    } = this.props;

    const emailCreativeConfig = getEmailCreativeConfig(appConfig);
    const { useReplyTo } = emailCreativeConfig;

    let predicateFilter = null;
    let eventName = null;
    let predicateFilterObject = null;

    if (criteria) {
      ({ predicateFilter, eventName, predicateFilterObject } = criteria);
    }

    return (
      <MainPage>
        <ConnectedEmail>
          {({ senderName, sender, replyToName, replyTo, subject, html }) => (
            <>
              <Header>
                <Name>EMAIL</Name>
                <ChannelStatus state={state} />
              </Header>
              <Details>
                <LeftColumn>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      {printLabelText(
                        'Trigger Type :',
                        triggerDisplayValues[triggerType],
                        originalCampaign && originalCampaign.triggerType !== triggerType,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {printLabelText(
                        'Dedupe Strategy :',
                        dedupeStrategy,
                        originalCampaign && originalCampaign.dedupeStrategy !== dedupeStrategy,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {printLabelText(
                        'Start Date : ',
                        formatDate(prop('Start Date', scheduling)),
                        originalCampaign && originalCampaign.startDate !== startDate,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {printLabelText(
                        'End Date : ',
                        formatDate(prop('Expiry Date', scheduling)),
                        originalCampaign && originalCampaign.expiryDate !== expiryDate,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {printLabelText(
                        'Trigger time : ',
                        formatTime(prop('Start Date', scheduling)),
                        originalCampaign && originalCampaign.startTime !== startTime,
                      )}
                    </Grid>
                    <RecurringOverview {...this.props} />
                  </Grid>
                  {triggerType === 'REAL_TIME' && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        {printLabelText(
                          'Trigger Event: ',
                          eventName || 'N/A',
                          originalCampaign &&
                            (originalCampaign.criteria?.eventName || eventName) &&
                            originalCampaign.criteria?.eventName !== eventName,
                        )}
                      </Grid>
                      {criteria?.delayTime && (
                        <Grid item xs={12} sm={12}>
                          {printLabelText('Trigger in', `${criteria.delayTime?.value} ${criteria.delayTime?.unit}`)}
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12}>
                        {printLabelText(
                          'Predicate Filter: ',
                          predicateFilter ||
                            (predicateFilterObject && predicateFilterObject.length ? prettifyPredicates(predicateFilterObject) : 'N/A',
                            originalCampaign &&
                              highlightCriteria({
                                jsonArr: predicateFilterObject,
                                str: predicateFilter,
                                originalJsonArr: originalCampaign.criteria?.predicateFilterObject,
                                originalStr: originalCampaign.criteria?.predicateFilter,
                              })),
                        )}
                      </Grid>
                    </Grid>
                  )}
                </LeftColumn>
                <RightColumn>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      {printLabelText(
                        'Sender name: ',
                        senderName,
                        originalCampaign && originalCampaign.creative?.senderName !== senderName,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {printLabelText('Sender: ', sender, originalCampaign && originalCampaign.creative?.sender !== sender)}
                    </Grid>
                    {useReplyTo && (
                      <>
                        <Grid item xs={12} sm={12}>
                          {printLabelText(
                            'Reply name: ',
                            replyToName,
                            originalCampaign &&
                              (replyToName || originalCampaign.creative?.replyToName) &&
                              originalCampaign.creative?.replyToName !== replyToName,
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {printLabelText(
                            'Replay to: ',
                            replyTo,
                            originalCampaign &&
                              (replyTo || originalCampaign.creative?.replyTo) &&
                              originalCampaign.creative?.replyTo !== replyTo,
                          )}
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={12}>
                      {printLabelText('Subject: ', subject, originalCampaign && originalCampaign.creative?.subject !== subject)}
                    </Grid>
                  </Grid>

                  <section>
                    <TestDelivery buttonText="Send Samples" deliveryChannel={channelNameEmail} handleSend={testDelivery} rowsMax={3} />
                  </section>
                  <section>
                    <HTMLPreview
                      mobile={false}
                      value={html}
                      variables={transformCampaignVariables({ variables }).reduce((acc, v) => ({ ...acc, [v.name]: v.defaultValue }), {})}
                    />
                  </section>
                </RightColumn>
              </Details>
            </>
          )}
        </ConnectedEmail>
      </MainPage>
    );
  }
}

export default withAppConfig(Overview);
