import { compose, withHandlers } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

import { DrawerError, DrawerErrorProps } from './DrawerError';
import { styles } from './styles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { Styles } from '@material-ui/styles/withStyles/withStyles';

const enhanced = compose<DrawerErrorProps, DrawerErrorProps>(
  withHandlers({
    handleDismiss: (props: { handleDismiss: () => void }) => () => props.handleDismiss?.(),
  }),

  withStyles(styles as Styles<Theme, object>, { withTheme: true }),
);

export default enhanced(DrawerError);
