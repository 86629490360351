import * as React from 'react';

import { map } from 'ramda';
import { TableHeader } from '../Table/TableHeader';
import { TableHeaderCell } from '../Table/TableHeaderCell';

const renderCell = column => (
  <TableHeaderCell key={column.key}>{column.renderHeader ? column.renderHeader(column) : column.label}</TableHeaderCell>
);

interface HeaderProps {
  columns?: {
    label?: string;
    renderHeader?(...args: unknown[]): unknown;
  }[];
  rowEditable?: boolean;
}

export const Header = ({ columns = [], rowEditable = true }: HeaderProps) => {
  const tableHeaderCells = map(renderCell)(columns);
  if (rowEditable) {
    tableHeaderCells.push(<TableHeaderCell />);
  }
  return <TableHeader>{tableHeaderCells}</TableHeader>;
};
