import * as React from 'react';

import { Typeahead } from 'app/midgarComponents';

interface VariableSelectionProps {
  selectVariable(...args: unknown[]): unknown;
  getSuggestions(...args: unknown[]): unknown;
  type?: string;
}

const VariableSelection = ({ selectVariable, getSuggestions, type }: VariableSelectionProps) => (
  <Typeahead
    debounceTime={500}
    getSuggestions={getSuggestions}
    onItemSelect={selectVariable}
    placeholder="Type to search in variables..."
    name={`${type}Variables` || 'variables'}
  />
);

export default VariableSelection;
