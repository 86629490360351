import { History } from 'history';

import { fetchTemplate, postTemplate, putTemplate } from 'app/api/templates';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { asReplacements, replaceImagesHTML } from 'app/helpers/ImageUploadManager';
import { mapIds } from 'app/ducks/helpers';

import { DispatchFn, GetStateFn, ITag, ITemplate } from 'app/types';
import { IUploadResult } from 'app/features/Templates/Email/TemplatePage/types';
import { CHANNELS_EMAIL_TEMPLATE_REPORTING } from 'configs/routes';

import * as actions from './actions';
import { template as templateSelector } from './selectors';

type FileUploads = Array<IUploadResult>;

const getTemplate =
  (id: number) =>
  async (dispatch: DispatchFn): Promise<ITemplate> => {
    dispatch(actions.get());
    try {
      const template = await fetchTemplate(id);
      return dispatch(actions.getSuccess(template));
    } catch (e) {
      displayError('Error getting template');
      return dispatch(actions.getFail('Error getting template', e.message));
    }
  };

const save =
  (dispatch: DispatchFn, getState: GetStateFn, mediumId: number | null | undefined, fileUploads?: FileUploads) =>
  async (apiSaveFn: (...args: Array<any>) => any) => {
    dispatch(actions.post());

    if (mediumId !== undefined && mediumId !== null) dispatch(actions.setMediumId(mediumId));
    const initTemplate = templateSelector(getState());

    const newContent = replaceImagesHTML(initTemplate.content, asReplacements(fileUploads));
    const template = { ...initTemplate, content: newContent, tagIds: mapIds(initTemplate.tags) };
    try {
      await apiSaveFn(template);
      dispatch(actions.postSuccess(template.id));
    } catch (e) {
      displayError('Error Saving Template');
      dispatch(actions.postFail('Error Saving Template', e.message));
    }
  };

const createTemplate =
  (history: History, mediumId: number, fileUploads?: FileUploads) => async (dispatch: DispatchFn, getState: GetStateFn) => {
    save(
      dispatch,
      getState,
      mediumId,
      fileUploads,
    )(async template => {
      await postTemplate(template);
      displaySuccess(`Template ${template.name} was Created`);
      history.push(CHANNELS_EMAIL_TEMPLATE_REPORTING);
    });
  };

const updateTemplate = (fileUploads?: FileUploads) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  save(
    dispatch,
    getState,
    null,
    fileUploads,
  )(async template => {
    await putTemplate(template);
    displaySuccess('Saved');
    getTemplate(template.id)(dispatch);
  });
};

const addTag = (tag: ITag) => (dispatch: DispatchFn) => dispatch(actions.addTag(tag));
const removeTag = (tag: ITag) => (dispatch: DispatchFn) => dispatch(actions.removeTag(tag));

export { getTemplate, createTemplate, updateTemplate, addTag, removeTag };
