import React from 'react';
import { TextField } from '@material-ui/core';

import { isNilOrEmpty, R } from 'app/helpers/RamdaHelpers/helpers';
import Tooltip from '../Tooltip';

const propsBasedOnValidation = (validate, value, dataType) =>
  R.isNil(validate) || validate(value)
    ? {
        value,
      }
    : {
        value,
        error: true,
        helperText: `Error: Not a valid ${dataType}`,
      };

interface RuleTextEditProps {
  id: string;
  label: string;
  dataType: string;
  value?: string;
  validator(...args: unknown[]): unknown;
  handleChange(...args: unknown[]): unknown;
  multiLine?: boolean;
  classes: Record<string, string>;
  style?: Record<string, any>;
}

export const RuleTextEdit = ({
  id,
  label,
  dataType,
  value = '',
  validator,
  handleChange,
  multiLine = false,
  classes,
  style = {},
}: RuleTextEditProps) => {
  const onKeyDown = e => {
    if (dataType === 'number') {
      const invalidChars = ['-', '+', 'e', 'E', '.'];
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    }
  };

  return (
    <Tooltip title={isNilOrEmpty(value) ? value : ''}>
      <TextField
        required
        margin="dense"
        className={classes.textField}
        style={style}
        key={id}
        id={id}
        label={label}
        type={dataType}
        multiline={multiLine}
        data-qa="rule-text-field"
        onChange={handleChange}
        onKeyDown={e => onKeyDown(e)}
        {...(isNilOrEmpty(value) ? { value: '' } : propsBasedOnValidation(validator, value, dataType))}
      />
    </Tooltip>
  );
};
