import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Avatar, Chip } from '@material-ui/core';
import { sc } from 'app/styles';

import { FullTable, Spinner } from 'app/midgarComponents';
import { isNeitherNilNorEmpty, R } from 'app/helpers/RamdaHelpers/helpers';
import { ConnectedCampaignForTable } from 'app/ducks/campaigns';
import theme from 'app/theme';
import { Tooltip } from 'app/components';
import { formatDateTime } from 'app/utilities/date';

import Actions from './Actions';

const DATE_FORMAT = "DD MMM'YY";

const genTableColumn = (label, key, Component, isNumeric = false) => ({
  label,
  key,
  customComponent: Component,
  isNumeric,
});

const Title = styled.p`
  color: #29394f;
  font-weight: bold;
  margin-bottom: 0.3rem;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  // overflow: hidden;
`;

const Sub = styled.p`
  color: #999999;
  font-size: ${sc.fontSizeSmaller};
  line-height: 1.2;
  margin-bottom: 0.3rem;
`;

const LoadMore = styled.div`
  position: relative;
  color: ${theme.palette.primary.main};
  font-weight: bold;
  padding: 25px;
  text-align: center;
  cursor: pointer;
`;

const renderState =
  classes =>
  (_, { state }) =>
    (
      <Chip
        className={classes[state]}
        label={state}
        style={{
          width: 80,
          textAlign: 'center',
          fontSize: '.7rem',
          height: 26,
        }}
      />
    );

const renderNameColumn =
  () =>
  (_, { name, id, createdBy }) =>
    (
      <>
        <Title title={name}>{name}</Title>
        <Sub>ID: {id}</Sub>
        <Sub>{createdBy}</Sub>
      </>
    );

// const returnFlag = (_, { scheduling }) => (scheduling && Boolean(scheduling.cron)) ? 'Yes' : 'No';
const renderActions = (isPermitted, openModal, classes) => (key, row) =>
  (
    <ConnectedCampaignForTable>
      {ops => <Actions {...ops} {...row} openModal={openModal} isPermitted={isPermitted} classes={classes} />}
    </ConnectedCampaignForTable>
  );

const renderChips = (label, items) => (
  <section>
    {label}
    <div style={{ padding: '1rem 0 0 0' }}>
      {items.map(item => (
        <Chip label={item.name} key={item.id} style={{ margin: '0 8px 8px 0' }} />
      ))}
    </div>
  </section>
);

const renderTooltip =
  classes =>
  (_, { id, medium, categories, tags, scheduling }) => {
    const mediumType = R.prop('type', medium);
    const [hasCategories, hasTags] = R.map(isNeitherNilNorEmpty, [categories, tags]);
    const hasInfo = mediumType || hasCategories || hasTags;
    const TooltipContent = hasInfo ? (
      <>
        {mediumType && renderChips('Channel', [{ id: mediumType, name: mediumType }])}
        {scheduling && renderChips('Campaign Trigger', [{ id: 'type', name: scheduling.cron ? 'Recurring' : 'One Time' }])}
        {hasCategories && renderChips('Categories', categories)}
        {hasTags && renderChips('Tags', tags)}
      </>
    ) : (
      ''
    );

    return (
      <Tooltip title={TooltipContent}>
        <Avatar className={hasInfo ? classes.tooltipIcon : classes.disabledTooltipIcon}>{R.head(mediumType || 'i')}</Avatar>
      </Tooltip>
    );
  };

const renderFirstOccurrence =
  (_, timezone) =>
  (_, { scheduling }) =>
    !!scheduling && (
      <>
        {timezone
          ? formatDateTime(moment.tz(scheduling.firstOccurrence, 'Etc/UTC'), timezone, DATE_FORMAT)
          : moment(scheduling.firstOccurrence).format(DATE_FORMAT)}
      </>
    );

const setColumns = (appConfig, percent, classes, isPermitted, openModal, timezone) => {
  let columns = [
    genTableColumn('Name', 'name', renderNameColumn()),
    genTableColumn('State', 'state', renderState(classes)),
    // genTableColumn('Recurring', 'recurring', returnFlag),
  ];

  columns = [
    ...columns,
    genTableColumn('First Occurrence', 'firstOccurence', renderFirstOccurrence(classes, timezone)),
    {
      ...genTableColumn('Actions', 'actions', renderActions(isPermitted, openModal, classes)),
      style: {
        padding: 0,
        textAlign: 'center',
      },
    },

    genTableColumn('', '', renderTooltip(classes)),
  ];

  return columns;
};

const renderLoadMore = (isLastPage, isMoreLoading, getMoreCampaigns) => {
  if (isLastPage) {
    return null;
  }

  if (isMoreLoading) {
    return (
      <LoadMore>
        <Spinner size={20} />
      </LoadMore>
    );
  }
  return <LoadMore onClick={getMoreCampaigns}>Load More</LoadMore>;
};

const getLinkedRows = campaigns => campaigns.map(x => (x.id ? { ...x, to: `/campaigns/${x.id}` } : x));

interface CampaignTableProps {
  classes: {
    header?: string;
  };
  percent: boolean;
  campaigns?: {
    name?: string;
    id?: number;
  }[];
  openModal(...args: unknown[]): unknown;
  isPermitted(...args: unknown[]): unknown;
  isLoading: boolean;
  onRowMount?(...args: unknown[]): unknown;
  isLastPage?: boolean;
  isMoreLoading: boolean;
  getMoreCampaigns(...args: unknown[]): unknown;
  handleRowClick(...args: unknown[]): unknown;
  appConfig?: {};
  user?: {
    timezone: string;
  };
}

export const CampaignTable = ({
  classes,
  percent,
  campaigns = [],
  openModal,
  isPermitted,
  isLoading,
  onRowMount,
  isLastPage = false,
  isMoreLoading,
  getMoreCampaigns,
  handleRowClick,
  appConfig,
  user,
}: CampaignTableProps) => (
  <>
    <FullTable
      rows={getLinkedRows(campaigns)}
      columns={setColumns(appConfig, percent, classes, isPermitted, openModal, user?.timezone)}
      rowEditable={false}
      rowsLoading={isLoading}
      onRowMount={onRowMount}
      handleRowClick={handleRowClick}
    />

    {renderLoadMore(isLastPage, isMoreLoading, getMoreCampaigns)}
  </>
);
