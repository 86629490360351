import * as React from 'react';
import { Segments } from '../../../../../../types/typescript/Segments';
import Audience from './Audience';

import type { JSX } from 'react';

type Props = {
  segmentsFetching: boolean;
  fetchSegments: Function;
  exclusionCampaign: boolean;
  includedSegments: Segments;
  excludedSegments: Segments;
  includedsegmentOptions: Segments;
  excludedsegmentOptions: Segments;
};

const Audiences = ({
  includedsegmentOptions,
  excludedsegmentOptions,
  segmentsFetching,
  fetchSegments,
  exclusionCampaign,
  includedSegments,
  excludedSegments,
}: Props): JSX.Element => {
  return (
    <>
      <Audience
        required={!exclusionCampaign}
        id="includedSegments"
        name="includedSegments"
        label="Included Audiences"
        segments={includedsegmentOptions}
        segmentsFetching={segmentsFetching}
        selected={includedSegments}
        fetchSegments={fetchSegments}
      />

      <Audience
        required={exclusionCampaign}
        id="excludedSegments"
        name="excludedSegments"
        label="Excluded Audiences"
        segments={excludedsegmentOptions}
        segmentsFetching={segmentsFetching}
        selected={excludedSegments}
        fetchSegments={fetchSegments}
      />
    </>
  );
};

export default Audiences;
