import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import { inject } from 'app/decorators';
import { Loading, Table } from 'app/components';
import * as DebugOperations from 'app/ducks/debug/operations';

import styles from './styles';
import { compose } from 'recompose';

interface DeliveredBannersProps {
  banners?: {
    userId?: number;
    resourceId?: number;
    timestamp?: string;
  }[];
  loading: boolean;
  DebugOperations: Record<string, (...args: unknown[]) => unknown>;
  classes: Record<string, string>;
}

class DeliveredBanners extends Component<DeliveredBannersProps> {
  static defaultProps = {
    banners: null,
  };

  state = {
    userId: '',
  };

  search = () => {
    const { userId } = this.state;
    const {
      DebugOperations: { fetchDeliveredBanners },
    } = this.props;

    fetchDeliveredBanners(userId);
  };

  render() {
    const { banners, loading, classes } = this.props;
    const { userId } = this.state;

    const columns = [
      { label: 'User ID', key: 'userId' },
      { label: 'Banner ID', key: 'resourceId' },
      { label: 'Time', key: 'timestamp' },
    ];

    return (
      <section className={classes.container}>
        <section className={classes.header}>
          <TextField
            required
            id="userId"
            type="number"
            label="User ID"
            value={userId}
            className={classes.textField}
            onChange={ev => this.setState({ userId: ev.target.value })}
          />

          <Button color="primary" disabled={!userId || !userId.trim().length} onClick={this.search}>
            Search
          </Button>
        </section>

        {loading ? <Loading /> : <Table rows={banners || []} columns={columns} />}
      </section>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(state => ({
    loading: state.debug.loading,
    banners: state.debug.banners,
  })),

  inject({ DebugOperations }),
)(DeliveredBanners);
