import { connect } from 'react-redux';

import { campaignOperations } from './campaign';
import { campaignsOperations } from './campaigns';

const { setStatus, cloneCampaign, activateCampaign } = campaignOperations;

const mapStateToProps = ({ user: { timezone } }) => ({ timezone });

const connectedForTable = connect(mapStateToProps, {
  activateCampaign,
  setStatus: campaignsOperations.setStatus,
  cloneCampaign,
});

const connectedForOverview = connect(mapStateToProps, {
  activateCampaign,
  setStatus,
  cloneCampaign,
});

interface mixinProps {
  children?(...args: unknown[]): unknown;
}

const mixin = ({ children, ...props }: mixinProps) => <>{children && children(props)}</>;

export const ConnectedCampaignForTable = connectedForTable(mixin);
export const ConnectedCampaignForOverview = connectedForOverview(mixin);
