import * as types from './types';
import { buildTagsLookups, buildV2TagsLookups } from './helpers';

const getAll = () => ({
  type: types.GET_ALL_TAGS,
});

const getAllSuccess = tags => ({
  type: types.GET_ALL_TAGS_SUCCESS,
  payload: { tagsLookups: buildTagsLookups(tags) },
});

const getAllFail = error => ({
  type: types.GET_ALL_TAGS_FAIL,
  payload: { error },
});

const post = () => ({
  type: types.POST_TAGS,
});

const postSuccess = () => ({
  type: types.POST_TAGS_SUCCESS,
});

const postFail = (error?: string) => ({
  type: types.POST_TAGS_FAIL,
  payload: { error },
});

const getAllV2Tags = () => ({
  type: types.GET_ALL_V2_TAGS,
});

const getV2TagsSuccess = tags => ({
  type: types.GET_V2_TAGS_SUCCESS,
  payload: { tagsV2Lookups: buildV2TagsLookups(tags) },
});

export { getAll, getAllSuccess, getAllFail, post, postSuccess, postFail, getAllV2Tags, getV2TagsSuccess };
