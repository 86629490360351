import * as types from './types';
import { ICampaign } from '../../../types';

const includedSegments = 'includedSegments';
const excludedSegments = 'excludedSegments';
const includedSegmentsFilters = 'includedSegmentsFilters';
const excludedSegmentsFilters = 'excludedSegmentsFilters';
const categories = 'categories2';
const tags = 'tags';

const get = () => ({
  type: types.GET_CAMPAIGN,
});

const getSuccess = campaign => ({
  type: types.GET_CAMPAIGN_SUCCESS,
  payload: { campaign },
});

const getFail = error => ({
  type: types.GET_CAMPAIGN_FAIL,
  payload: { error },
});

const post = () => ({
  type: types.POST_CAMPAIGN,
});

const postSuccess = campaignId => ({
  type: types.POST_CAMPAIGN_SUCCESS,
  payload: { campaignId },
});

const postFail = error => ({
  type: types.POST_CAMPAIGN_FAIL,
  payload: { error },
});

const newCampaign = () => ({ type: types.NEW_CAMPAIGN });

const setCampaignField = field => ({
  type: types.SET_CAMPAIGN_FIELD,
  payload: field,
});

const setCriteria = obj => ({
  type: types.SET_CRITERIA,
  payload: obj,
});

const clearCriteria = () => ({
  type: types.CLEAR_CRITERIA,
});

const toggleSubmitAllForms = () => ({
  type: types.TOGGLE_SUBMIT_ALL_FORMS,
});

const resetHiddenFormsSubmitted = () => ({
  type: types.RESET_HIDDEN_FORMS_SUBMITTED,
});

const setHiddenFormsSubmitted = submittedForm => ({
  type: types.SET_HIDDEN_FORMS_SUBMITTED,
  payload: submittedForm,
});

const setDeactivationCriteria = criteria => ({
  type: types.SET_DEACTIVATION_CRITERIA,
  payload: criteria,
});

const clearDeactivationCriteria = () => ({
  type: types.CLEAR_DEACTIVATION_CRITERIA,
});

const addIncludeSegment = segment => ({
  type: types.ADD_SEGMENT,
  payload: {
    segment,
    field: includedSegments,
  },
});

const removeIncludeSegment = segment => ({
  type: types.REMOVE_SEGMENT,
  payload: { segment, field: includedSegments },
});

const addExcludeSegment = segment => ({
  type: types.ADD_SEGMENT,
  payload: {
    segment,
    field: excludedSegments,
  },
});

const removeExcludeSegment = segment => ({
  type: types.REMOVE_SEGMENT,
  payload: { segment, field: excludedSegments },
});

const addIncludeSegmentFilters = segment => ({
  type: types.ADD_SEGMENT,
  payload: {
    segment,
    field: includedSegmentsFilters,
  },
});

const removeIncludeSegmentFilters = segment => ({
  type: types.REMOVE_SEGMENT,
  payload: { segment, field: includedSegmentsFilters },
});

const addExcludeSegmentFilters = segment => ({
  type: types.ADD_SEGMENT,
  payload: {
    segment,
    field: excludedSegmentsFilters,
  },
});

const removeExcludeSegmentFilters = segment => ({
  type: types.REMOVE_SEGMENT,
  payload: { segment, field: excludedSegmentsFilters },
});

const clearSegments = () => ({
  type: types.CLEAR_SEGMENTS,
});

const clearFilterSegments = () => ({
  type: types.CLEAR_FILTER_SEGMENTS,
});

const addCategory = segment => ({
  type: types.ADD_SEGMENT,
  payload: {
    segment,
    field: categories,
  },
});

const setCategories = segments => ({
  type: types.SET_SEGMENTS,
  payload: {
    segments,
    field: categories,
  },
});

const removeCategory = segment => ({
  type: types.REMOVE_SEGMENT,
  payload: {
    segment,
    field: categories,
  },
});

const addTag = segment => ({
  type: types.ADD_SEGMENT,
  payload: {
    segment,
    field: tags,
  },
});

const removeTag = segment => ({
  type: types.REMOVE_SEGMENT,
  payload: {
    segment,
    field: tags,
  },
});

const setGoal = goal => ({
  type: types.SET_GOAL,
  payload: {
    goal,
  },
});

const removeGoal = () => ({
  type: types.REMOVE_GOAL,
});

const setPromoCode = campaignPromotionCode => ({
  type: types.SET_PROMO_CODE,
  payload: {
    campaignPromotionCode,
  },
});

const getCampaignSize = () => ({
  type: types.GET_CAMPAIGN_SIZE,
});

const getCampaignSizeSuccess = size => ({
  type: types.GET_CAMPAIGN_SIZE_SUCCESS,
  payload: { size },
});

const getCampaignSizeFail = () => ({
  type: types.GET_CAMPAIGN_SIZE_FAIL,
});

const updateEventsVariables = variable => ({
  type: types.UPDATE_EVENTS_VARIABLES,
  payload: { variable },
});

const addEventsVariable = variable => ({
  type: types.ADD_EVENTS_VARIABLE,
  payload: { variable },
});

const removeEventsVariable = variable => ({
  type: types.REMOVE_EVENTS_VARIABLE,
  payload: { variable },
});

const removeAllEventsVariables = () => ({
  type: types.REMOVE_ALL_EVENTS_VARIABLES,
});

const addFeaturesVariable = variable => ({
  type: types.ADD_FEATURES_VARIABLE,
  payload: { variable },
});

const updateFeaturesVariables = variable => ({
  type: types.UPDATE_FEATURES_VARIABLES,
  payload: { variable },
});

const removeFeaturesVariable = variable => ({
  type: types.REMOVE_FEATURES_VARIABLE,
  payload: { variable },
});

const removeAllVariables = () => ({
  type: types.REMOVE_ALL_VARIABLES,
});

const setEmailCampaign = () => ({
  type: types.SET_EMAIL_CAMPAIGN,
});

const setBannerCampaign = () => ({
  type: types.SET_BANNER_CAMPAIGN,
});

const setLocationTrigger = params => ({
  type: types.SET_LOCATION_TRIGGER,
  payload: { ...params },
});

const setStatus = params => ({
  type: types.SET_STATUS,
  payload: { ...params },
});

const updateFacebookValues = values => ({
  type: types.UPDATE_FACEBOOK_VALUES,
  payload: { values },
});

export const setBannerCreative = (creative: ICampaign['creative'], preserveRawCreative?: boolean) => ({
  type: types.SET_BANNER_CREATIVE,
  payload: { creative, preserveRawCreative },
});

export const setRegularCashbackCreative = creative => ({
  type: types.SET_REGULAR_CASHBACK_CREATIVE,
  payload: { creative },
});

export const setJourneyCreative = creative => ({
  type: types.SET_JOURNEY_CREATIVE,
  payload: { creative },
});

export const setEmailCreative = creative => ({
  type: types.SET_EMAIL_CREATIVE,
  payload: { creative },
});

export const setPush2Creative = creative => ({
  type: types.SET_PUSH2_CREATIVE,
  payload: { creative },
});

export const setTimelineNotificationCreative = creative => ({
  type: types.SET_TIMELINE_NOTIFICATION_CREATIVE,
  payload: { creative },
});

const getMetricsSuccess = metrics => ({
  type: types.GET_METRICS_SUCCESS,
  payload: { metrics },
});

const getMetricsFail = error => ({
  type: types.GET_METRICS_ERROR,
  payload: { error },
});

const setBusinessPhase = payload => ({
  type: types.SET_BUSINESS_PHASE,
  payload,
});

// TODO: Remove once confirmed we don't need to pass errors through to validations.js
const setFormErrors = payload => ({
  type: types.SET_FORM_ERRORS,
  payload,
});

const resetFormErrors = () => ({
  type: types.RESET_FORM_ERRORS,
});

export {
  get,
  getSuccess,
  getFail,
  post,
  postSuccess,
  postFail,
  newCampaign,
  setCampaignField,
  setCriteria,
  clearCriteria,
  setDeactivationCriteria,
  clearDeactivationCriteria,
  addIncludeSegment,
  removeIncludeSegment,
  addExcludeSegment,
  removeExcludeSegment,
  addIncludeSegmentFilters,
  removeIncludeSegmentFilters,
  addExcludeSegmentFilters,
  removeExcludeSegmentFilters,
  clearSegments,
  clearFilterSegments,
  getCampaignSize,
  getCampaignSizeSuccess,
  getCampaignSizeFail,
  updateFeaturesVariables,
  updateEventsVariables,
  addFeaturesVariable,
  addEventsVariable,
  removeFeaturesVariable,
  removeEventsVariable,
  removeAllEventsVariables,
  removeAllVariables,
  setEmailCampaign,
  setBannerCampaign,
  setStatus,
  addCategory,
  setCategories,
  removeCategory,
  setLocationTrigger,
  addTag,
  removeTag,
  setGoal,
  removeGoal,
  setPromoCode,
  updateFacebookValues,
  getMetricsSuccess,
  getMetricsFail,
  setBusinessPhase,
  setFormErrors,
  toggleSubmitAllForms,
  resetFormErrors,
  resetHiddenFormsSubmitted,
  setHiddenFormsSubmitted,
};
