import { RouteComponentProps } from 'react-router-dom';

import { CASHBACK_MANAGEMENT_STACKABLE_GROUP } from 'configs/routes';

interface Params {
  groupId: string;
}

export const getStackableGroupUrl = (groupId: number) => CASHBACK_MANAGEMENT_STACKABLE_GROUP.replace(':groupId', String(groupId));

export const parseStackableGroupUrl = ({ match }: { match: RouteComponentProps<Params>['match'] }): { groupId?: number } => {
  const {
    params: { groupId: groupIdStr },
  } = match;
  const groupId = Number(groupIdStr);
  return Number.isNaN(groupId) ? {} : { groupId };
};
