import React from 'react';

import MultiSelectDialog from '../MultiSelectDialog';
import MultiValueTextField from '../MultiValueTextField';

interface ReadOnlyInputWithMultiSelectDialogProps {
  id: string;
  label: string;
  selection?: string | number[];
  options: any[];
  stringifier(...args: unknown[]): unknown;
  handleOpen(...args: unknown[]): unknown;
  handleClose(...args: unknown[]): unknown;
  isOpen: boolean;
  dialogTitle?: string;
  classes: Record<string, string>;
}

export const ReadOnlyInputWithMultiSelectDialog = ({
  id,
  label,
  selection,
  stringifier,
  options,
  handleOpen,
  handleClose,
  isOpen,
  dialogTitle,
  classes,
}: ReadOnlyInputWithMultiSelectDialogProps) => (
  <>
    <MultiValueTextField
      {...{
        id,
        label,
        stringifier,
        handleOpen,
        classes,
        values: selection,
        tooltipId: `${id}-tooltip`,
      }}
    />

    <MultiSelectDialog
      {...{
        selection,
        isOpen,
        options,
        dialogTitle,
        classes,
        onClose: handleClose,
      }}
    />
  </>
);
