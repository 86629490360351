import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';

import { Tooltip } from 'app/components';
import { Info as InfoIcon } from '@material-ui/icons';

import HTMLWithPreview from 'app/components/HTMLWithPreview';

import EmailHeader from 'app/components/EmailHeader';

import { ConnectedEmail } from 'app/ducks';
// TODO: Change placement of component to common directory
import PersonalizationVariables from '../../Personalization/Variables';

import { TemplateSelection } from './TemplateSelection';
import { useAppConfig } from 'app/hooks/useAppConfig';

interface EmailCreationProps {
  selectTemplate(...args: unknown[]): unknown;
  classes: {
    root?: string;
    container?: string;
    variablesContainer?: string;
    templateTooltip?: string;
    templateSelect?: string;
  };
  allEmails?: {
    id?: string | number;
    name?: string;
  }[];
  emailsLoading?: boolean;
  setEmailCreativeField?(...args: unknown[]): unknown;
  getTemplates(...args: unknown[]): unknown;
  testDelivery?(...args: unknown[]): unknown;
}

export const EmailCreation = ({
  selectTemplate,
  classes,
  allEmails = [],
  emailsLoading = false,
  setEmailCreativeField = undefined,
  getTemplates,
  testDelivery,
}: EmailCreationProps) => {
  const appConfig = useAppConfig();
  return (
    <section className={classes.root}>
      <ConnectedEmail>
        {({ subject, sender, senderName, replyTo, replyToName, html, updateEmailField }) => (
          <>
            <section className={classes.container}>
              <Tooltip placement="bottom" title="(Optional) Warning: all values in this section will be overwritten">
                <InfoIcon className={classes.templateTooltip} />
              </Tooltip>
              <TemplateSelection getTemplates={getTemplates} selectTemplate={selectTemplate} />
            </section>

            <section className={classes.container}>
              <EmailHeader
                subject={subject}
                sender={sender}
                senderName={senderName}
                replyTo={replyTo}
                replyToName={replyToName}
                allEmailSenders={allEmails}
                emailSendersLoading={emailsLoading}
                setSubject={setEmailCreativeField(updateEmailField)('subject')}
                setSender={setEmailCreativeField(updateEmailField)('sender')}
                setSenderName={setEmailCreativeField(updateEmailField)('senderName')}
                setReplyTo={setEmailCreativeField(updateEmailField)('replyTo')}
                setReplyToName={setEmailCreativeField(updateEmailField)('replyToName')}
                handleTestDelivery={testDelivery}
              />
            </section>

            {!appConfig.audiences.versionChecked && (
              <section>
                <Label>Personalization Variables</Label>
                <PersonalizationVariables />
              </section>
            )}

            <HTMLWithPreview
              content={html}
              label="Paste HTML or select a template"
              name="emailHtmlContent"
              setContent={setEmailCreativeField(updateEmailField)('html')}
              isUploadImage={false}
            />
          </>
        )}
      </ConnectedEmail>
    </section>
  );
};

const Label = styled.div`
  text-transform: uppercase;
  color: ${sc.grey};
  margin: 0 1rem 1rem 0;
  text-align: left;
  font-size: ${sc.fontSize};
`;
