import * as React from 'react';
import styled, { css } from 'styled-components';
import { dissoc } from 'ramda';
import Moment from 'moment';

import { tzJapan } from 'configs/dateTime';

import { DrawerError } from 'app/components';
import { ConnectedUser } from 'app/ducks';
import { formatCampaign } from 'app/ducks/campaigns/campaign/operations';
import { findChannelSetup } from 'app/ducks/configStore/utils';
import { isNeitherNilNorEmpty } from 'app/helpers/RamdaHelpers/helpers';
import { defaultSearchAdaptor } from 'app/hocs/withReporting/withCreatedBySwitch';
import {
  DateField,
  DateRangeField,
  Dropdown,
  Icon as _Icon,
  OptionsSwitch,
  Page,
  PageHeader,
  PageMain,
  Paging,
  SearchBar,
} from 'app/midgarComponents';

import { IDropdownOption } from 'app/midgarComponents/Dropdown';
import { CreatedBySwitch as _CreatedBySwitch } from 'app/midgarComponents/OptionsSwitch/CreatedBySwitch';
import {
  CreateNewButton,
  Footer,
  SearchControls as _SearchControls,
  SearchContainer as _SearchContainer,
  Switches,
} from 'app/midgarComponents/ReportingPage';

import { sc } from 'app/styles';
import { ICampaign, IQueryParams } from 'app/types';
import { IBannerCampaignSpec } from 'app/types/Creatives/BannerCreative';
import { IScheduling } from 'app/types/Scheduling';
import { IPaging } from 'app/hocs/withReporting';
import { asQueryParamDateWindow, momentToDateStr, parseQueryParamDateWindow } from 'app/utilities/date';

import { IAppConfig } from 'configs/apps/types';
import { campaignCreationPermissions, campaignDraftPermissions } from 'configs/permissions';
import * as RoutesConfig from 'configs/routes';

import CampaignTable from './CampaignTable';
import { CampaignEdit } from '../CampaignEdit/CampaignEdit';
import { searchBarDefiner, searchByChannelDefiner, searchByState, searchParams } from './search.config';
import CategoriesSearch from './CategoriesSearch';
import SegmentsSearch from './SegmentsSearch';

const CreatedBySwitch = defaultSearchAdaptor(searchParams.createdBy)(_CreatedBySwitch);

const valueAll = 'all';
const valueRecurring = 'RECURRING';

type Props = {
  history: Record<string, any>;
  campaigns: Array<ICampaign>;
  isLoading: boolean;
  error: Error;
  search: IQueryParams;
  pushSearch: (arg0: IQueryParams) => void;
  searchPhrase: string;
  selectedSearchType: string;
  handleSearchPhraseChange: (arg0: string) => void;
  handleSearchTypeChange: (arg0: string) => void;
  pushSearchBar: (arg0: { searchPhrase: string; selectedSearchType: string }) => void;
  msgIfEmpty?: string;
  paging: IPaging;
  goToPage: (arg0: number) => unknown;
  userEmail: string;
  user: { timezone: string };
  bannerUpdate: (campaignId: number, arg1: Array<IBannerCampaignSpec>) => void;
  schedulingUpdate: (arg0: number, arg1: IScheduling) => void;
  appConfig?: IAppConfig;
};

type State = {
  percent: boolean;
  modal?: 'bannerId' | 'campaignExpiry';
  selectedId?: number;
  expanded: boolean;
  availableTriggers: Array<{ label: string; value: string }>;
};

export default class Reporting extends React.PureComponent<Props, State> {
  state = {
    percent: false,
    expanded: false,
    availableTriggers: [],
  };

  componentDidMount() {
    const { search, searchPhrase, appConfig } = this.props;
    const channelSetup = appConfig && findChannelSetup(appConfig, 'Push2');
    const newState = {};

    if (channelSetup && channelSetup.triggers) {
      const triggers = channelSetup.triggers.map(trigger => {
        trigger.label = trigger.name;
        return trigger;
      });
      newState.availableTriggers = triggers;
    }

    if (
      isNeitherNilNorEmpty(search[searchParams.state]) ||
      isNeitherNilNorEmpty(search[searchParams.channel]) ||
      isNeitherNilNorEmpty(search[searchParams.expiry]) ||
      isNeitherNilNorEmpty(search[searchParams.firstOccurrence]) ||
      isNeitherNilNorEmpty(search[searchParams.segment]) ||
      isNeitherNilNorEmpty(search.categories) ||
      isNeitherNilNorEmpty(searchPhrase)
    ) {
      newState.expanded = true;
    }

    this.setState(newState);
  }

  getCampaign = (campaignId: number): ICampaign => {
    const { campaigns, user } = this.props;
    const campaign = campaigns.find(({ id }) => id === campaignId);
    if (!campaign) {
      throw new Error(`No match found for campaign ${campaignId} among ${campaigns.map(({ id }) => id).join(', ')}`);
    }

    return formatCampaign(user.timezone)(campaign);
  };

  openModal = ({ modal, selectedId }) => this.setState({ modal, selectedId });

  closeModal = () => this.setState({ modal: undefined, selectedId: undefined });

  handleChangeChannel = ({ id }: IDropdownOption) => {
    const { pushSearch, search } = this.props;
    if (id) {
      pushSearch({ ...search, [searchParams.channel]: id });
    } else {
      pushSearch(dissoc(searchParams.channel, search));
    }
  };

  handleChangeExpiry = (expiryDateStr: string) => {
    const { pushSearch, search } = this.props;
    pushSearch({ ...search, [searchParams.expiry]: expiryDateStr });
  };

  handleChangeFirstOccurrenceRange = ([startDate, endDate]: [Moment, Moment]) => {
    const { pushSearch, search } = this.props;
    pushSearch({
      ...search,
      [searchParams.firstOccurrence]: asQueryParamDateWindow({
        startDate: momentToDateStr(startDate),
        endDate: momentToDateStr(endDate),
        timezone: tzJapan,
      }),
    });
  };

  handleChangePercent = value => {
    this.setState(prevState => ({ ...prevState, percent: value === 'percent' }));
  };

  handleChangeSearchTriggerType = value => {
    const { pushSearch, search } = this.props;
    if (value === 'all') {
      pushSearch(dissoc('trigger_type', search));
    } else {
      pushSearch({ ...search, trigger_type: value });
    }
  };

  handleChangeSearchOptionsSwitch = (searchType, onValue) => value => {
    const { pushSearch, search } = this.props;
    if (value === onValue) {
      pushSearch({ ...search, [searchType]: true });
    } else {
      pushSearch(dissoc(searchType, search));
    }
  };

  handleChangeState = ({ id }: IDropdownOption) => {
    const { pushSearch, search } = this.props;
    if (id) {
      pushSearch({ ...search, [searchParams.state]: id });
    } else {
      pushSearch(dissoc(searchParams.state, search));
    }
  };

  handleRowClick = ({ id }) => {
    const { history } = this.props;

    return history.push(`/campaigns/${id}`, { state: { from: history.location.pathname } });
  };

  handleExpand = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));
  };

  render() {
    const { modal, percent, selectedId, expanded, availableTriggers } = this.state;

    const {
      campaigns,
      isLoading,
      error,
      search,
      searchPhrase,
      selectedSearchType,
      handleSearchPhraseChange,
      handleSearchTypeChange,
      msgIfEmpty,
      pushSearch,
      pushSearchBar,
      paging,
      goToPage,
      userEmail,
      user,
      bannerUpdate,
      schedulingUpdate,
    } = this.props;

    const { startDate: firstOccurrenceStart = '', endDate: firstOccurrenceEnd = '' } = parseQueryParamDateWindow(
      search[searchParams.firstOccurrence],
    );

    const searchByChannel = searchByChannelDefiner(this.props);

    return (
      <ConnectedUser>
        {({ isPermitted }) => (
          <Page>
            <PageHeader title="Campaigns">
              <Switches>
                <CreatedBySwitch pushSearch={pushSearch} search={search} userEmail={userEmail} />

                <OptionsSwitch
                  value={search.trigger_type || valueAll}
                  options={[
                    { value: valueAll, label: 'All' },
                    ...(availableTriggers.length ? availableTriggers : [{ value: valueRecurring, label: 'Recurring' }]),
                  ]}
                  handleChange={this.handleChangeSearchTriggerType}
                />
              </Switches>

              {isPermitted([...campaignCreationPermissions, ...campaignDraftPermissions]) && (
                <CreateNewButton to={RoutesConfig.CAMPAIGNS_NEW} />
              )}
            </PageHeader>

            <PageMain>
              <SearchContainer $expanded={expanded} data-qa="campaigns-search-container">
                <SearchControls>
                  <Dropdown
                    label="State"
                    value={(searchByState.options || []).find(opt => opt.id === search[searchParams.state]) || {}}
                    options={searchByState.options || []}
                    onChange={this.handleChangeState}
                  />

                  {!!searchByChannel && (
                    <Dropdown
                      label="Channel"
                      value={(searchByChannel.options || []).find(opt => opt.id === search[searchParams.channel]) || {}}
                      options={searchByChannel.options || []}
                      onChange={this.handleChangeChannel}
                    />
                  )}

                  <DateField
                    id="expires-on"
                    name="expires-on"
                    label="Expires On"
                    value={search[searchParams.expiry]}
                    handleSelect={this.handleChangeExpiry}
                  />

                  <DateRangeField
                    id="first-occurrence"
                    name="first-occurence"
                    label="First Occurrence"
                    startValue={firstOccurrenceStart}
                    endValue={firstOccurrenceEnd}
                    handleSelect={this.handleChangeFirstOccurrenceRange}
                  />
                </SearchControls>

                <CategoriesSearch pushSearch={pushSearch} search={search} />

                <SegmentsSearch pushSearch={pushSearch} search={search} />

                <SearchBar
                  searchPhrase={searchPhrase}
                  selectedSearchType={selectedSearchType}
                  searchTypes={searchBarDefiner(this.props)} // TODO: inject searchBarDefs
                  handleSearchPhraseChange={handleSearchPhraseChange}
                  handleSearchTypeChange={handleSearchTypeChange}
                  pushSearchBar={pushSearchBar}
                />
              </SearchContainer>

              <ExpandContainer onClick={this.handleExpand} $expanded={expanded} data-qa="filter-toggle">
                <Icon name={expanded ? 'chevron-up' : 'chevron-down'} size={30} />
                {expanded ? 'Hide Filters' : 'Show Filters'}
              </ExpandContainer>

              {error && error.message && (
                <DrawerError error="Error while retrieving campaigns" errorDetails={error.message} defaultExpanded />
              )}

              <CampaignTable
                campaigns={campaigns}
                isLoading={isLoading}
                isPermitted={isPermitted}
                handleRowClick={this.handleRowClick}
                percent={percent}
                msgIfEmpty={msgIfEmpty}
                openModal={this.openModal}
              />

              {isLoading ? undefined : (
                <Footer>
                  <Paging {...paging} handleGoToPage={goToPage} />
                </Footer>
              )}
            </PageMain>

            {modal && (
              <CampaignEdit
                bannerUpdate={bannerUpdate}
                campaign={this.getCampaign(selectedId)}
                closeModal={this.closeModal}
                modal={modal}
                schedulingUpdate={schedulingUpdate}
                user={user}
              />
            )}
          </Page>
        )}
      </ConnectedUser>
    );
  }
}

const ExpandContainer = styled.div`
  width: 100%;
  height: 55px;
  color: ${sc.headingColor};
  text-align: center;
  line-height: 3.5;
  margin-bottom: ${sc.gutterSmaller};
  transition: all 0.3s ease;

  ${props =>
    props.$expanded
      ? css`
          box-shadow: '';
          border-bottom: 1px solid ${sc.sectionBorderColor};
        `
      : css`
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          border-bottom: '';
        `};

  & * {
    fill: ${sc.headingColor};
  }

  &:hover {
    color: ${sc.subHeadingColor};
    cursor: pointer;
    & * {
      fill: ${sc.subHeadingColor};
    }
  }
`;

const Icon = styled(_Icon)`
  margin-right: ${sc.gutterSmaller};
`;

const SearchContainer = styled(_SearchContainer)`
  margin-bottom: 0;
  display: ${props => (props.$expanded ? '' : 'none')};
  padding: ${sc.gutterSmaller};
  & > div,
  & > section {
    margin-bottom: ${sc.gutterSmaller};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SearchControls = styled(_SearchControls)`
  & > div,
  & > section {
    width: 20%;
  }
`;
