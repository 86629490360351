import React from 'react';
import List from '@material-ui/core/List';
import { ListItem, ListItemText } from '@material-ui/core';
import { Tooltip } from 'app/components';
import Paper from 'app/midgarComponents/Paper';
import { useEffect, useState } from 'react';

interface ListSelectableProps {
  selectedId?: number | string;
  items?: {
    id?: number | string;
    name?: string;
  }[];
  handleClick(...args: unknown[]): unknown;
  classes?: {
    listContainer?: string;
  };
  scrollIntoViewIfNeeded?: boolean;
}

export const ListSelectable = ({ selectedId, items, handleClick, classes, scrollIntoViewIfNeeded }: ListSelectableProps) => {
  const highlightedRef = React.useRef(null);
  const [scrolledIntoView, setScrolledIntoView] = useState(false);

  useEffect(() => {
    if (scrollIntoViewIfNeeded && !scrolledIntoView) {
      if (highlightedRef.current && selectedId) {
        highlightedRef.current.scrollIntoView(false);
        setScrolledIntoView(true);
      }
    }
  }, [scrollIntoViewIfNeeded, scrolledIntoView, selectedId]);

  return items && items.length > 0 ? (
    <Paper className={classes.listContainer}>
      <List data-qa="list" disablePadding component="nav">
        {items.map(item => (
          <Tooltip title={item.desc || ''} key={item.id}>
            <ListItem
              data-qa={item.id}
              button
              divider
              onClick={handleClick(item.id)}
              className={selectedId === item.id ? classes.selected : null}
              ref={selectedId === item.id ? highlightedRef : null}
            >
              {selectedId === item.id ? <ListItemText primary={item.name} /> : <ListItemText secondary={item.name} />}
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </Paper>
  ) : null;
};
