import { IAppConfig, IChannelSetup } from 'configs/apps/types';
import { channelNameTimelineNotification } from 'configs/channels/constants';

export const getChannels = (appConfig: IAppConfig): Array<string | IChannelSetup> => (appConfig ? appConfig.channels || [] : []);

export const getChannelNames = (appConfig: IAppConfig): Array<string> => {
  const channels = getChannels(appConfig).map(c => (typeof c === 'string' ? c : c.name));

  const filteredChannels = appConfig?.isSecureRoom ? channels.filter(channel => channel === channelNameTimelineNotification) : channels;
  return filteredChannels;
};

/**
 * Get the `IChannelSetup` for the given channel.
 *
 * If the channel is active, and there is no custom setup for this channel, returns an object with only the `name` property.
 *
 * If the channel is not active, return `undefined`.
 *
 * @param {IAppConfig} appConfig
 * @param {string} channelName
 * @returns ?IChannelSetup
 */
export const findChannelSetup = (appConfig: IAppConfig, channelName: string): IChannelSetup | string | undefined => {
  const channel = getChannels(appConfig).find(config => {
    if (typeof channelName !== 'string') {
      return null;
    }

    return typeof config === 'string'
      ? channelName.toUpperCase() === config.toUpperCase()
      : typeof config.name === 'string' && channelName.toUpperCase() === config.name.toUpperCase();
  });

  return channel && typeof channel === 'string' ? { name: channel } : channel;
};
