import React from 'react';
import cx from 'classnames';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import { sc } from 'app/styles';
import { DragSource, DropTarget } from 'react-dnd/lib/esm';
import { moveWhenStopped } from 'app/utilities/dnd';
import { ISlot } from 'app/types/BannerManagement';
import { Icon as _Icon } from 'app/midgarComponents';

type Props = {
  slot: ISlot;
  index: number;
  onMoveSave: (...args: Array<any>) => any;
  onSelect: (arg0: number) => void;
  readOnly?: boolean;
  isSelected: boolean;
  isDragging?: boolean;
  renderError: (...args: Array<any>) => any;
  connectDragSource?: (...args: Array<any>) => any;
  connectDropTarget?: (...args: Array<any>) => any;
  connectDragPreview?: (...args: Array<any>) => any;
  openDeleteModel?: (...args: Array<any>) => any;
};

class Slot extends React.Component<Props> {
  slotRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.slotRef = React.createRef<HTMLDivElement>();
  }

  handleClick = (id: number) => {
    const { onSelect } = this.props;
    onSelect(id);
  };

  showError = (error = '') => {
    const { renderError } = this.props;
    renderError(error);
  };

  render() {
    const { slot, isSelected, isDragging, connectDragSource, connectDragPreview, connectDropTarget, openDeleteModel } = this.props;
    const isEmpty = slot.status && !slot.itemCount;
    const error = isEmpty ? 'Active slot with no banners' : slot.error;

    return connectDragPreview && connectDropTarget
      ? connectDropTarget(
          connectDragPreview(
            <div ref={this.slotRef} className={cx('binder-dnd-slot', { 'is-dragging': isDragging })}>
              {connectDragSource &&
                connectDragSource(
                  <div>
                    <SlotIconContainer>
                      <SlotIcon
                        size={14}
                        name="remove"
                        onClick={() => openDeleteModel?.(slot.id)}
                        isActive={!!slot.status}
                        isInvalid={!!(slot.error || isEmpty)}
                        isSelected={isSelected}
                      />
                    </SlotIconContainer>
                    <SlotButton
                      type="secondary"
                      isActive={!!slot.status}
                      isInvalid={!!(slot.error || isEmpty)}
                      isSelected={isSelected}
                      onMouseUp={() => this.handleClick(slot.id)}
                      onMouseOut={() => this.showError()}
                      onMouseOver={() => this.showError(error)}
                    >
                      {slot.id}
                    </SlotButton>
                  </div>,
                )}
            </div>,
          ),
        )
      : null;
  }
}

export default DropTarget(
  'binder-slot',
  {
    hover: (props: Record<string, any>, monitor: any, component: Slot) =>
      moveWhenStopped(props, monitor, component?.slotRef?.current, 'linear'),
    drop: (props: Props) => props.onMoveSave(),
  },

  (connect: Record<string, any>) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    'binder-slot',
    {
      beginDrag: (props: Props) => ({
        id: props.slot.id,
        index: props.index,
      }),
    },

    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    }),
  )(Slot),
);

// Styled Components with Prop Types

interface SlotButtonProps {
  isSelected: boolean;
  isActive: boolean;
  isInvalid: boolean;
  type: 'primary' | 'secondary';
}

const SlotButton = styled.div<SlotButtonProps>`
  position: relative;
  height: 40px;
  line-height: 40px;
  width: 70px;
  border: 1px solid ${sc.sectionBorderColor};
  border-radius: 5px 0 5px 5px;
  background-color: white;
  color: ${sc.primary};
  font-size: ${sc.fontSizeSmall};
  font-weight: 600;
  cursor: pointer;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    border-color: ${sc.primary};
  }

  ${({ isSelected, isActive, isInvalid }) => {
    if (!isActive) {
      return css`
        color: ${sc.subHeadingColor};
        background-color: ${isSelected ? sc.sectionBackgroundColor : 'transparent'};
        opacity: ${isSelected ? 1 : 0.5};

        &:hover,
        &:active,
        &:focus {
          opacity: 1;
          border-color: ${sc.sectionBorderColor};
        }
      `;
    }

    if (isInvalid) {
      return css`
        color: ${isSelected ? '#fff' : sc.primary};
        border-color: ${isSelected ? sc.danger : lighten(0.2, sc.danger)};
        background-color: ${isSelected ? sc.danger : 'transparent'};

        &:hover,
        &:active,
        &:focus {
          color: ${isSelected ? '#fff' : sc.danger};
          border-color: ${sc.danger};
        }
      `;
    }

    if (isSelected) {
      return css`
        color: #fff;
        border-color: ${sc.primary};
        background-color: ${sc.primary};
      `;
    }
  }}

  ${({ isSelected, isActive, isInvalid }) => {
    if (isSelected) {
      return css`
        &:after {
          content: '';
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translate(-50%, 0);
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid ${isActive ? (isInvalid ? sc.danger : sc.primary) : sc.greyLighter};
        }
      `;
    }
  }}
`;

const SlotIconContainer = styled.div`
  display: inline-block;
  width: 70px;
  background-color: transparent;
`;

interface SlotIconProps {
  isSelected: boolean;
  isActive: boolean;
  isInvalid: boolean;
}

const SlotIcon = styled(_Icon)<SlotIconProps>`
  position: relative;
  right: 0;
  bottom: -4px;
  border-top: 1px solid ${sc.sectionBorderColor};
  border-left: 1px solid ${sc.sectionBorderColor};
  border-right: 1px solid ${sc.sectionBorderColor};
  border-bottom: 0 solid ${sc.sectionBorderColor};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: white;
  color: #d5deec;
  float: right;

  &:hover,
  &:active,
  &:focus {
    color: ${sc.danger};
  }

  ${({ isSelected, isActive, isInvalid }) => {
    if (!isActive) {
      return css`
        background-color: transparent;
      `;
    }

    if (isInvalid) {
      return css`
        background-color: transparent;
      `;
    }

    if (isSelected) {
      return css`
        color: #fff;
        border-color: ${sc.primary};
        background-color: ${sc.primary};
      `;
    }
  }}
`;
