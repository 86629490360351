import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { useAppConfig } from 'app/hooks/useAppConfig';
import { getChannelsConfig } from 'configs/channels';
import { mapIds } from 'app/ducks/helpers';
import { segmentTypeFlags } from 'app/features/Segments/utils';
import { isMaxAudienceRequired, maxAudienceProp } from 'app/features/Campaigns/campaignReach';
import { setHiddenFormsSubmitted } from 'app/ducks/campaigns/campaign/actions';
import {
  setCampaignFieldPromise,
  removeExcludeSegment,
  removeIncludeSegment,
  getMaxAudienceLimit,
} from 'app/ducks/campaigns/campaign/operations';
import * as api from 'app/api/segments';
import * as apiV2 from 'app/api/segments2';
import { Segment, Segments } from '../../../types/typescript/Segments';
import { QueryParams } from '../../../types/typescript/QueryParams';
import { SectionContainer } from '../SectionContainer';
import AudienceVersions from './AudienceVersions';

import type { JSX } from 'react';

export const PAGE_SIZE = 25;

const getSegmentsPaged = async (params: QueryParams, versionChecked: boolean): Promise<Segments> => {
  try {
    const apiType = versionChecked ? apiV2 : api;
    const res = await apiType.getSegmentsPaged(params);
    return res.content;
  } catch (err) {
    // TODO: Display error in UI
    return [];
  }
};

export const fetchSegments = (
  value: string,
  type: string,
  getSegmentsPaged: (params: QueryParams, versionChecked: boolean) => Promise<Segments>,
  pageSize: number,
  versionChecked: boolean,
): Promise<Segments> => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  const nameParams = { name: trimmedValue, type, page: 0, size: pageSize };
  const nameParamsV2 = { searchBy: 'name', searchTerm: trimmedValue, page: 0, size: pageSize };
  const idParams: QueryParams | undefined = trimmedValue.length && !Number.isNaN(Number(trimmedValue)) ? { id: trimmedValue } : undefined;
  const queries: Array<QueryParams | undefined> = [versionChecked ? nameParamsV2 : nameParams, idParams];

  const mergeResultSets = (resultSets: Array<Segments>): Segments => {
    return ([] as Segment[]).concat(...resultSets).filter(r => !!r);
  };

  function notUndefined<QueryParams>(value: QueryParams | undefined): value is QueryParams {
    return !!value;
  }

  const segmentQueriesFiltered: Array<QueryParams> = queries.filter(notUndefined);
  const segmentCalls = segmentQueriesFiltered.map(q => {
    return getSegmentsPaged(q, versionChecked);
  });

  return Promise.all(segmentCalls).then(resultSets => {
    return mergeResultSets(resultSets);
  });
};

type CampaignState = {
  campaigns: {
    campaign: {
      general: {
        customerCountPerExecution: number;
        mediumId: number;
        triggerType: string;
        submitAllForms: boolean;
        exclusionCampaign: boolean;
        useEveryoneAudience: boolean;
        includedSegments: Segments;
        excludedSegments: Segments;
        includedSegmentsFilters: Segments;
        excludedSegmentsFilters: Segments;
        audienceFiltersEnabled: boolean;
        includedSegmentsGeoFilters: Segments;
        maxAudienceLimit: string | null;
        audienceId: Segments;
      };
    };
  };
};

const Target = (): JSX.Element => {
  const {
    general: {
      mediumId,
      triggerType,
      includedSegments,
      excludedSegments,
      includedSegmentsFilters,
      excludedSegmentsFilters,
      audienceFiltersEnabled,
      includedSegmentsGeoFilters,
      exclusionCampaign,
      useEveryoneAudience,
      customerCountPerExecution,
      submitAllForms,
      maxAudienceLimit,
      audienceId,
    },
  } = useSelector(
    ({
      campaigns: {
        campaign: { general },
      },
    }: CampaignState) => ({ general }),
  );

  const dispatch = useDispatch();
  const appConfig = useAppConfig();
  const [isMounted, setIsMounted] = React.useState(false);
  const [segments, setSegments] = React.useState<Segments>([]);
  const [segmentsFetching, setSegmentsFetching] = React.useState(false);
  const [supportedTypes, setSupportedTypes] = React.useState('RULE,CSV');
  const [versionChecked, setVersionChecked] = React.useState(audienceId && audienceId.length > 0 ? true : false);

  const getSegments = debounce(async (value: string, type: string) => {
    if (isMounted) {
      setSegmentsFetching(true);
    }

    let segments: Array<Segment> = [];
    try {
      segments = await fetchSegments(value, type, getSegmentsPaged, PAGE_SIZE, versionChecked);
      if (isMounted) {
        setSegments(segments);
        setSegmentsFetching(false);
      }
    } catch (_) {
      if (isMounted) {
        setSegments(segments);
      }
    }
  }, 300);

  React.useEffect(() => {
    setIsMounted(true);
    getSegments('', supportedTypes);
    return (): void => {
      setIsMounted(false);
    };
  }, [isMounted, versionChecked]); //eslint-disable-line react-hooks/exhaustive-deps

  const maxAudienceRequired = React.useMemo(() => {
    const channelsConfig = getChannelsConfig(appConfig);
    const channel = mediumId !== undefined ? channelsConfig[mediumId] || {} : {};

    const maxAudienceRequiredInChannel = !!(channel && 'requiredFields' in channel ? channel['requiredFields'] : []).find(
      (f: unknown) => f === maxAudienceProp,
    );
    return maxAudienceRequiredInChannel && isMaxAudienceRequired(triggerType);
  }, [appConfig, mediumId, triggerType]);

  React.useEffect(() => {
    if (appConfig.enableMaxAudienceLimit && !maxAudienceLimit) {
      dispatch(getMaxAudienceLimit());
    }
  }, [appConfig.enableMaxAudienceLimit, dispatch, maxAudienceLimit]);

  const getEligibleSegments = (
    segments: Segments,
    ineligibleItems: Segments = [],
    allowBoostSegments: boolean,
    audienceFiltersEnabled: boolean,
  ): Segments => {
    if (audienceFiltersEnabled) {
      if (allowBoostSegments) {
        return segments.filter(x => x.type !== segmentTypeFlags.CSV_BASED && mapIds(ineligibleItems).indexOf(x.id) === -1);
      }
      return segments.filter(x => x.type === segmentTypeFlags.RULE_BASED && mapIds(ineligibleItems).indexOf(x.id) === -1);
    }

    return segments;
  };

  const filterEnableHandler = (enable: boolean): void => {
    setSupportedTypes('RULE,CSV');
    if (enable) {
      const isCsv = (segment: Segment): boolean => segment.type === segmentTypeFlags.CSV_BASED;
      includedSegments.filter(isCsv).map(removeIncludeSegment);
      excludedSegments.filter(isCsv).map(removeExcludeSegment);
    }
  };

  const saveTargetValues = React.useCallback(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    (values: any) => {
      const {
        customerCountPerExecution,
        includedSegments,
        audienceId,
        excludedSegments,
        includedSegmentsFilters,
        excludedSegmentsFilters,
        includedSegmentsGeoFilters,
        audienceFiltersEnabled,
      } = values;

      // eslint-disable-next-line promise/catch-or-return
      setCampaignFieldPromise({
        includedSegments,
        audienceId,
        excludedSegments,
        includedSegmentsFilters,
        excludedSegmentsFilters,
        includedSegmentsGeoFilters,
        customerCountPerExecution,
        audienceFiltersEnabled,
      })(dispatch).then(() => {
        return dispatch(setHiddenFormsSubmitted({ audiences: true }));
      });
    },
    [dispatch],
  );

  return (
    <SectionContainer data-qa="audiences-section">
      <AudienceVersions
        includedSegments={includedSegments}
        audienceId={audienceId}
        excludedSegments={excludedSegments}
        includedSegmentsFilters={includedSegmentsFilters}
        excludedSegmentsFilters={excludedSegmentsFilters}
        includedSegmentsGeoFilters={includedSegmentsGeoFilters}
        customerCountPerExecution={customerCountPerExecution}
        audienceFiltersEnabled={audienceFiltersEnabled}
        useEveryoneAudience={useEveryoneAudience}
        exclusionCampaign={exclusionCampaign}
        saveTargetValues={saveTargetValues}
        maxAudienceRequired={maxAudienceRequired}
        appConfig={appConfig}
        segments={segments}
        // eslint-disable-next-line
        // @ts-ignore
        getSegments={getSegments}
        segmentsFetching={segmentsFetching}
        supportedTypes={supportedTypes}
        maxAudienceLimit={maxAudienceLimit}
        submitAllForms={submitAllForms}
        mediumId={mediumId}
        triggerType={triggerType}
        filterEnableHandler={filterEnableHandler}
        getEligibleSegments={getEligibleSegments}
        versionChecked={versionChecked}
        setVersionChecked={setVersionChecked}
      />
    </SectionContainer>
  );
};

export default Target;
